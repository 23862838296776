import {Component, ElementRef, OnInit, Output, ViewChild, EventEmitter, Input} from '@angular/core';
import { GlobalService } from "../../global/global.service";

@Component({
  selector: 'app-custom-dropzone',
  templateUrl: './custom-dropzone.component.html',
  styleUrls: ['./custom-dropzone.component.scss']
})
export class CustomDropzoneComponent {
  @Output()  public  filePass = new EventEmitter<any>();
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  @Input() contentStr = 'Drop files here or click to upload.';
  @Input() multiple = true;
  @Input() accept = '*';
  files: any[] = [];
  allFiles: any[] = [];
  unsupportedFileTypes = ["svg"];
  maxFileSize = 26214400;
  sizeOfAllFiles = 0;

  constructor(
    private globeService: GlobalService
  ) {
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    let validFiles: boolean = this.addFilesHandler($event);
    if(validFiles)
      this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    let validFiles: boolean = this.addFilesHandler(files);
    if(validFiles)
      this.prepareFilesList(files);
  }
  addFilesHandler(files: Array<any>)
  {
    let raw_size = 0;

    for (const item of files) {
      const fileSize = item.size;
      const fileType = item.name.split('.').pop().toLowerCase();
      if(this.unsupportedFileTypes.includes(fileType))
      {
        this.globeService.showErrorNotification('File type ' + fileType + ' is not supported.');
        return false;
      }
      else if(fileSize > this.maxFileSize)
      {
        this.globeService.showErrorNotification(item.name + ' size (' + this.formatBytes(fileSize) + ') is too big. System only allows 25MB');
        return false;
      }
      else
      {
        raw_size = raw_size + item.size;
      }

      if (raw_size > this.maxFileSize) {
        this.globeService.showErrorNotification('Combine file size is (' + this.formatBytes(raw_size) + ') is too big. System only allows 25MB');
        return false;
      } 
    }
    let final_raw_size = this.sizeOfAllFiles + raw_size;
    if (final_raw_size > this.maxFileSize) {
      this.globeService.showErrorNotification('Combine file size is (' + this.formatBytes(final_raw_size) + ') is too big. System only allows 25MB');
      return false;
    } 
    this.sizeOfAllFiles = this.sizeOfAllFiles + raw_size;

    return true;
  }
  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    let remove_file = this.allFiles[index];
    if(remove_file)
    {
      this.sizeOfAllFiles = this.sizeOfAllFiles - remove_file.size;
      this.allFiles.splice(index, 1);
    }
    if(this.files[index])
    {
      if (this.files[index].progress < 100) 
        return;
    }
    if(this.files)
      this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  async prepareFilesList(files: Array<any>) {
    for (const item of files) {
      await  this.addPreviewImage(item);
      item.progress = 0;
      this.files.push(item);
      this.allFiles.push(item);
    }
    this.filePass.emit(this.files);
    this.fileDropEl.nativeElement.value = "";
    this.files = [];
    // this.uploadFilesSimulator(0);
  }

  addPreviewImage(item) {
    const mimeType = item.type;
    if (mimeType.toString().includes('image/')) {
      const reader = new FileReader();
      reader.readAsDataURL(item);
      reader.onload = (event: any) => {
        item.previewUrl = reader.result;
      };
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  // formatBytes(bytes, decimals = 2) {
  //   if (bytes === 0) return '0 Bytes';

  //   const k = 1024;
  //   const dm = decimals < 0 ? 0 : decimals;
  //   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  //   const i = Math.floor(Math.log(bytes) / Math.log(k));

  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  // }
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    // return bytes / (1024 * 1024) + 'MB';
    return parseFloat((bytes / (1024 * 1024)).toFixed(2))+ 'MB';
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SharedComponentService } from "../shared-component.service";
import { GlobalService } from "../../global/global.service";
import { Router } from "@angular/router";
import { AccountService } from "../../accounts/account.service";
import { StaffAndEducatorService } from "../../staff-and-educator/staff-and-educator.service";
import { MatCheckboxChange } from "@angular/material/checkbox";

@Component({
  selector: 'app-staff-assessment-registration',
  templateUrl: './staff-assessment-registration.component.html',
  styleUrls: ['./staff-assessment-registration.component.sass']
})
export class StaffAssessmentRegistrationComponent implements OnInit {
  @Input() staffRef: any;
  @Input() is_from_link: true;
  @Output() formSubmited = new EventEmitter<any>();
  sectionForm: FormGroup;
  peoples: FormArray;
  loggedUser: any = [];
  isEdit = false;
  allMembers: any = [];
  error = '';
  submitted = false;
  signatureImg = null;
  signatureImg2 = null;
  isNewSignature = true;
  isNewSignature2 = true;
  deleted_attachments = [];
  dropZoneFiles = [];
  dropZoneFilesPreview = [];
  checkBoxChange = false;
  checkBoxChange1 = false;
  checkBoxChange2 = false;
  checkBoxChange3 = false;
  showMoreDetails = false;
  checkFullTime = false;
  checkPartTime = false;
  checkD = false;
  checkLicense = false;
  checkOwnCar = false;
  checkHOmeUnit = false;
  checkFlat = false;
  checkSafeToPlay = false;
  checkSafePark = false;
  checkPool = false;
  checkInsurer = false;
  checkQ17 = false;
  otherPosition = false;
  constructor(
    private sharedService: SharedComponentService,
    private fb: FormBuilder,
    private globalService: GlobalService,
    private router: Router,
    private accountService: AccountService,
    private staffEducatorFormService: StaffAndEducatorService,
  ) {
    this.initForm();
    this.loggedUser = JSON.parse(localStorage.getItem('user'));
  }

  initForm() {
    this.sectionForm = this.fb.group({
      staff_name: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      address_1: ['', [Validators.required]],
      suburb: ['', [Validators.required]],
      state_id: ['', [Validators.required]],
      postal_code: [''],
      country_of_birth: [''],
      phone: [''],
      mobile_phone: [''],
      email: [''],
      nationality: [''],
      language_spoken: [''],
      current_employer: [''],
      occupation: [''],
      c_e_length_years: [''],
      c_e_length_months: [''],
      previous_employer: [''],
      previous_occupation: [''],
      p_e_length_years: [''],
      p_e_length_months: [''],
      self_emp: [''],
      contact_details: [''],
      is_abn_number: [0],
      abn_number: [''],
      b_school_care: [0],
      b_age_care: [0],
      b_vacation_care: [0],
      c_full_time: [0],
      full_start_time: [''],
      full_finish_time: [''],
      c_part_time: [0],
      part_start_time: [''],
      part_finish_time: [''],
      have_you_children: [0],
      children_count: [''],
      have_transport_vehicle: [0],
      have_license: [0],
      license_no: [''],
      license_expiry_date: [''],
      have_own_car: [0],
      type_of_car: [''],
      is_registered: [0],
      is_insured: [0],
      name_of_insurer: [''],
      f_owned: [0], f_rented: [0], f_single_story: [0], f_two_story: [0], f_villa: [0],
      f_home_unit: [0], f_flat: [0],
      flat_level: [''],
      is_fully_fenced: [0],
      is_child_proof: [0],
      is_gates: [0],
      is_pets: [0],
      is_backyard_safe: [0],
      is_backyard_fully_fenced: [0],
      is_near_park: [0],
      is_park_fully_fenced: [0],
      is_have_pool: [0],
      is_pool_safety: [0],
      g_adults_count: [0], g_children_count: [0], g_single_count: [0], g_married_count: [''], g_others: [''],
      h_type: ['mr'],
      h_first_name: [''], h_last_name: [''], h_date_of_birth: [''], h_address_1: [''], h_suburb: [''],
      h_state_id: [''], h_postal_code: [''], h_home_phone: [''], h_mobile_phone: [''], h_is_smoker: [0],
      h_p_spoken_lng: [''], h_other_lng: [''], h_occupation: [''], h_employer: [''], h_work_phone: [''],
      peoples: this.fb.array([this.createPeoples()]),
      question_1: [''], question_2: [''], question_3: [''], question_4: [''], question_5: [''],
      question_6: [''], question_7: [''], question_8: [''], question_9: [''], question_10: [''],
      question_11: [''], question_12: [''], question_13: [''], question_14: [''], question_15: [''], question_16: [''],
      question_17: [''], question_18: [''],
      question_19: [''],
      question_20: [''],
      question_17_des: [''],
      signature: [''],
      s_date: [''],
      r_position: [''],
      r_other_position: [''],
      r_interview_conducted: [''],
      r_interview_completed: [''],
      r_interview_notification: [''],
      r_date: [''],
      r_coordinator_name: [''],
      pre_assessment_staff_form_ref: [''],
      flat_lifts: [0],
      interview_staff_user_id: [''],
      title: [''],
      fax_number: [''],
      have_spouse_partner: [''],
      partner_details: [''],
      partner_living_with_you: [''],
      important_details: [''],
      qualification_details: [''],
      police_details: [''],
      provide_details: [''],
      coordinator_signature_date: [''],
    });

  }
  ngOnInit() {
    this.getFromData();
    this.getAllMembers();
  }
  getAllMembers() {
    this.accountService.getAllMembers().subscribe((res) => {
      this.allMembers = res;
    }, (error) => {
      this.error = error.error.error;
      this.globalService.showErrorNotification('Something went wrong!');
    }
    );
  }

  async getFromData() {
    await this.sharedService.getAssessmentRegistrationStaff(this.staffRef).subscribe((res) => {

      if (res['is_edit'] == 0) {
        this.isEdit = false;
        this.sectionForm.patchValue({
          staff_name: res['staff']['staff_name'],
          dob: res['staff']['dob'],
          gender: res['staff']['gender'],
          address_1: res['staff']['address_1'],
          suburb: res['staff']['suburb'],
          state_id: res['staff']['state_id'].toString(),
          postal_code: res['staff']['postal_code'],
          phone: res['staff']['telephone'],
          mobile_phone: res['staff']['mobile_no'],
          email: res['staff']['email'],
        })
      } else {
        this.isEdit = true;
        this.setData(res['data']);

      }
    }, (error) => {
      this.globalService.showErrorNotification(error);
    });
  }

  setData(row) {
    (this.sectionForm.get('peoples') as FormArray).clear();

    this.sectionForm.enable();
    this.isEdit = true;
    this.sectionForm.reset();
    this.sectionForm.patchValue(row);

    this.sectionForm.patchValue({
      h_state_id: row['h_state_id'] != null ? row['h_state_id'].toString() : '',
      state_id: row['state_id'] != null ? row['state_id'].toString() : '',
      flat_lifts: row['flat_lifts'],
      s_date: this.globalService.formatDatetoServerSide(row['s_date']),
      r_date: this.globalService.formatDatetoServerSide(row['r_date']),
    });

    this.otherPosition = false;
    if (row['r_position'] == 'Other') {
      this.otherPosition = true;
    }
    //otherPosition
    // reset attachments
    this.deleted_attachments = [];
    this.dropZoneFiles = [];
    this.dropZoneFilesPreview = [];

    for (var i = 0; i < row['peoples'].length; i++) {
      this.addPeople(row['peoples'][i]);
    }

    // dropzone attachment
    this.dropZoneFiles = Object.assign([], row.attachments);
    this.CheckBoxChangesPatch(row);
    this.isNewSignature = false;
    this.signatureImg = row['signature1'];
    if (row['signature1'] == 'null') {
      this.isNewSignature = true;
      this.signatureImg = null;
    }

    this.signatureImg2 = row['signature2'];
    this.isNewSignature2 = false;
    if (row['signature2'] == 'null') {
      this.isNewSignature2 = true;
      this.signatureImg2 = null;
    }

    if (row['question_12']) {
      this.checkBoxChange3 = true;
    }
    if (row['partner_living_with_you']) {
      this.checkBoxChange2 = true;
    }
    if (row['have_spouse_partner']) {
      this.checkBoxChange1 = true;
    }
    if (row['have_you_children']) {
      this.checkQ17 = true;
    }

  }

  private CheckBoxChangesPatch(row: any) {

    if (row['is_abn_number'] == 1) {
      this.checkBoxChange = true;
    } else {
      this.checkBoxChange = false;
    }

    if (row['c_full_time'] == 1) {
      this.checkFullTime = true;
    } else {
      this.checkFullTime = false;
    }
    if (row['c_part_time'] == 1) {
      this.checkPartTime = true;
    } else {
      this.checkPartTime = false;
    }
    if (row['have_you_children'] == 1) {
      this.checkD = true;
    } else {
      this.checkD = false;
    }
    if (row['have_license'] == 1) {
      this.checkLicense = true;
    } else {
      this.checkLicense = false;
    }
    if (row['have_own_car'] == 1) {
      this.checkOwnCar = true;
    } else {
      this.checkOwnCar = false;
    }
    if (row['f_home_unit'] == 1) {
      this.checkHOmeUnit = true;
    } else {
      this.checkHOmeUnit = false;
    }
    if (row['f_flat'] == 1) {
      this.checkFlat = true;
    } else {
      this.checkFlat = false;
    }

    if (row['is_backyard_safe'] == 1) {
      this.checkSafeToPlay = true;
    } else {
      this.checkSafeToPlay = false;
    }
    if (row['is_near_park'] == 1) {
      this.checkSafePark = true;
    } else {
      this.checkSafePark = false;
    }
    if (row['is_have_pool'] == 1) {
      this.checkPool = true;
    } else {
      this.checkPool = false;
    }

    if (row['is_insured'] == 1) {
      this.checkInsurer = true;
    } else {
      this.checkInsurer = false;
    }
    if (row['have_you_children'] == 1) {
      this.checkQ17 = true;
    } else {
      this.checkQ17 = false;
    }
  }
  formSubmit(form: FormGroup) {
    if (!form.valid || this.submitted) {
      this.globalService.scrollToError();
      return;
    }

    this.submitted = true;
    const xForm = new FormData();
    xForm.append('form', JSON.stringify(this.sectionForm.value));
    xForm.append('signature1', this.signatureImg);
    xForm.append('signature2', this.signatureImg2);
    // File Upload
    for (var i = 0; i < this.dropZoneFilesPreview.length; i++) {
      xForm.append('attachments[]', this.dropZoneFilesPreview[i]);
      // xForm.append('attachments_mimeTypes[]', this.dropZoneFilesPreview[i].type);
    }
    xForm.append('delete_attachments', JSON.stringify(this.deleted_attachments));
    xForm.append('is_from_link', JSON.stringify(this.is_from_link == undefined ? true : this.is_from_link));
    this.staffEducatorFormService.createPreAssessmentStaffForm(xForm).subscribe((res) => {
      this.submitted = false;
      if (this.isEdit) {

        this.globalService.showSuccessNotification('Successfully updated');
        this.isEdit = false;
      } else {
        this.globalService.showSuccessNotification('Successfully saved');
      }
      this.formSubmited.emit(true);
    }, (error) => {
      this.error = error.error.error;
      this.globalService.showErrorNotification(this.error);
      this.submitted = false;
    }
    );
  }

  uploadFiles(data: any) {
    let fileList: FileList = data;
    for (var i = 0; i < fileList.length; i++) {
      this.dropZoneFilesPreview.push(fileList[i]);
    }
  }

  resetUploadFilePreview(key) {
    this.dropZoneFilesPreview.splice(key, 1);
  }

  resetUploadFile(key: number) {
    var data = { removing_item: this.dropZoneFiles[key] };
    this.deleted_attachments.push(data);
    if (key > -1) {
      this.dropZoneFiles.splice(key, 1)
    }
  }

  toggle($event: MatCheckboxChange) {
    this.checkBoxChange = $event.checked;
  }
  toggleFullTime($event: MatCheckboxChange) {
    this.checkFullTime = $event.checked;
  }

  togglePartTime($event: MatCheckboxChange) {
    this.checkPartTime = $event.checked;
  }

  toggleD($event: MatCheckboxChange) {
    this.checkD = $event.checked;
  }

  toggleLicense($event: MatCheckboxChange) {
    this.checkLicense = $event.checked;
  }

  toggleOwnCar($event: MatCheckboxChange) {
    this.checkOwnCar = $event.checked;
  }

  toggleHomeUnit($event: MatCheckboxChange) {
    this.checkHOmeUnit = $event.checked;
  }

  toggleFlat($event: MatCheckboxChange) {
    this.checkFlat = $event.checked;
  }

  toggleSafeToPlay($event: MatCheckboxChange) {
    this.checkSafeToPlay = $event.checked;
  }

  toggleSafePark($event: MatCheckboxChange) {
    this.checkSafePark = $event.checked;
  }

  togglePool($event: MatCheckboxChange) {
    this.checkPool = $event.checked;
  }

  toggleIsurer($event: MatCheckboxChange) {
    this.checkInsurer = $event.checked;
  }

  toggleQ17($event: MatCheckboxChange) {
    this.checkQ17 = $event.checked;
  }

  signatureImgOutput($event) {
    this.signatureImg = $event;
  }

  signatureImgOutput2($event) {
    this.signatureImg2 = $event;
  }

  removeSignature() {
    this.isNewSignature = true;
    this.signatureImg = '';
  }
  removeSignature2() {
    this.isNewSignature2 = true;
    this.signatureImg2 = '';
  }

  toggleDetails($event: MatCheckboxChange) {
    this.checkBoxChange3 = $event.checked;
  }

  togglePartner($event: MatCheckboxChange) {
    this.checkBoxChange1 = $event.checked;
  }

  togglePartner2($event: MatCheckboxChange) {
    this.checkBoxChange2 = $event.checked;
  }

  removeRow(key) {
    this.peoples.removeAt(key);
  }
  createPeoples(): FormGroup {
    return this.fb.group({
      i_first_name: [''],
      i_last_name: [''],
      i_dob: [''],
      i_country_birth: [''],
      i_id: [''],
      i_relationship: [''],
      i_is_smoke: [0],
    });
  }

  addPeople(data): void {
    this.peoples = this.sectionForm.get('peoples') as FormArray;
    if (data == null) {
      this.peoples.push(this.createPeoples());
    } else {
      this.peoples.push(this.createPeoples2(data));
    }
  }

  createPeoples2(data) {
    return this.fb.group({
      i_first_name: [data.i_first_name],
      i_last_name: [data.i_last_name],
      i_dob: [data.i_dob],
      i_country_birth: [data.i_country_birth],
      i_id: [data.i_id],
      i_relationship: [data.i_relationship],
      i_is_smoke: [data.i_is_smoke],
    });
  }
}

<table class="table table-sm c-61676c drop-zone-preview f-12" *ngIf="previewType == 1">
  <tr *ngFor="let item of items; let key = index">
    <!--    <td width="5%">{{ key +  1}}</td>-->
    <td width="10%">
      <img src="{{ item | getIconFileTypes}}" width="20" height="20">
    </td>
    <td width="75%">{{ item.name}}</td>
    <td width="10%">
      <a style="font-size: 7px;color: #ff4f3b" class="pointer" (click)="removeItem(key)">
        <mat-icon>close</mat-icon>
      </a>
    </td>
  </tr>
</table>


<div class="row text-center text-lg-left" *ngIf="previewType == 2">
  <div class="d-block p-15 img-preview" *ngFor="let item of items; let key = index">
    <ng-container *ngIf="item['type'].split('/')[0] == 'image'">
      <img class="img-fluid img-thumbnail" src="{{ item.previewUrl }}" alt="">
    </ng-container>
    <ng-container *ngIf="item['type'].split('/')[0] != 'image'">
      <img class="img-fluid img-thumbnail" src="{{ item | getIconFileTypes}}" alt="">
    </ng-container>
    <a style="font-size: 7px;color: #ff4f3b; position: absolute;" class="pointer" (click)="removeItem(key)">
      <mat-icon class="f-20">close</mat-icon>
    </a>
  </div>
</div>


<div class="row text-center text-lg-left" *ngIf="previewType == 3" align="center">
  <div class="d-block p-15" *ngFor="let item of items; let key = index" style="margin-left: 28%;">
    <ng-container *ngIf="item['type'].split('/')[0] == 'image'">
      <img class="img-fluid img-thumbnail radius-50p wh-120" src="{{ item.previewUrl }}" alt="">
    </ng-container>
    <a style="font-size: 7px;color: #ff4f3b; position: absolute;" class="pointer" (click)="removeItem(key)">
      <mat-icon class="f-20">close</mat-icon>
    </a>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from "../../../accounts/account.service";
import { Router } from "@angular/router";
@Component({
  selector: 'app-search-menu',
  templateUrl: './search-menu.component.html',
  styleUrls: ['./search-menu.component.css']
})
export class SearchMenuComponent implements OnInit {
  public addCusForm: FormGroup;
  showResults = false;
  loadingGif = false;
  error = '';
  results: any = [];
  constructor(private fb: FormBuilder,
    public dialog: MatDialog,
    private accountService: AccountService,
    private router: Router,
  ) { }
  public ngOnInit(): void {
    this.addCusForm = this.fb.group({
      keyword: [
        '',
        [Validators.required, Validators.pattern('[a-zA-Z]+([a-zA-Z ]+)*')]
      ]
    });
  }
  closeDialog(): void {
    this.dialog.closeAll();

  }
  onSubmitClick() {

    if (this.addCusForm.invalid)
      return;

    this.showResults = false;
    this.accountService.getSearchMenu(this.addCusForm.value).subscribe((res) => {
      this.results = res['results'];
      this.showResults = true;
    }, (error) => {
      this.error = error.error.error;
    });
  }

  onKey(event: KeyboardEvent) { // with type info
    this.loadingGif = true;
    let values = (event.target as HTMLInputElement).value;
    if (values.length > 0) {
      let data = { keyword: values };
      this.accountService.getSearchMenu(data).subscribe((res) => {
        this.results = res['results'];
        this.showResults = true;
        this.loadingGif = false;
      }, (error) => {
        this.error = error.error.error;
      }
      );
    } else {
      this.loadingGif = false;
      this.results = [];
    }
  }

  routerNavigate(path: any) {
    this.dialog.closeAll();
    this.router.navigate([path]);
  }
}

<form class="register-form" [formGroup]="sectionForm" (ngSubmit)="formSubmit(sectionForm)">
    <div class="modal-body f-13">

        <h5 class="form-group-heading">Basic Information</h5>
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Title</mat-label>
                    <input matInput formControlName="title" required>
                </mat-form-field>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Educator Name</mat-label>
                    <input matInput formControlName="educator_name" required>
                </mat-form-field>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" required type="email">
                </mat-form-field>
            </div>

        </div>

        <div class="row">

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Phone</mat-label>
                    <input matInput formControlName="phone" required>
                </mat-form-field>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Mobile Phone</mat-label>
                    <input matInput formControlName="mobile_phone" required>
                </mat-form-field>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Country of Birth</mat-label>
                    <input matInput formControlName="country_of_birth" type="text" required>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Address Line 1</mat-label>
                    <textarea matInput formControlName="address_1" rows="1" required></textarea>
                </mat-form-field>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Suburb</mat-label>
                    <input matInput formControlName="suburb" required>
                </mat-form-field>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="state_id" required>
                        <mat-option *ngFor="let state  of allMembers['states']" value="{{ state.state_id }}">{{
                            state.state_name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Postal code</mat-label>
                    <input matInput formControlName="postal_code" type="number" pattern="[0-9]*" numbersOnly required>
                </mat-form-field>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Date Of Birth</mat-label>
                    <input matInput [matDatepicker]="dob" formControlName="dob" required (click)="dob.open()">
                    <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                    <mat-datepicker #dob></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Gender</mat-label>
                    <input matInput formControlName="gender" type="text" required>
                </mat-form-field>
            </div>

        </div>

        <div class="row">

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Nationality</mat-label>
                    <input matInput formControlName="nationality" type="text" required>
                </mat-form-field>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Language Spoken at home</mat-label>
                    <input matInput formControlName="language_spoken" type="text" required>
                </mat-form-field>
            </div>
        </div>

        <div class="form-group-heading">
            <h5>Professional Working Experience</h5>
        </div>
        <div><label class="sub-title-form">Current Employer</label></div>
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Employer</mat-label>
                    <input matInput formControlName="current_employer">
                </mat-form-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Occupation</mat-label>
                    <input matInput formControlName="occupation">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Length of Employment Years</mat-label>
                    <input matInput type="number" formControlName="c_e_length_years">
                </mat-form-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Length of Employment Months</mat-label>
                    <input type="number" matInput formControlName="c_e_length_months">
                </mat-form-field>
            </div>
        </div>

        <div><label class="sub-title-form">Previous Employer</label></div>
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Employer</mat-label>
                    <input matInput formControlName="previous_employer">
                </mat-form-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Occupation</mat-label>
                    <input matInput formControlName="previous_occupation">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Length of Employment Years</mat-label>
                    <input matInput type="number" formControlName="p_e_length_years">
                </mat-form-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Length of Employment Months</mat-label>
                    <input type="number" matInput formControlName="p_e_length_months">
                </mat-form-field>
            </div>
        </div>

        <div><label class="sub-title-form">If Self-employed</label></div>
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>If Self-employed please provide details and address</mat-label>
                    <textarea matInput formControlName="self_emp" rows="2"></textarea>
                </mat-form-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Name and Contact details including accountant or institution if studying</mat-label>
                    <textarea matInput formControlName="contact_details" rows="2"></textarea>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                <label class="f-14a f-13 m-b-14 f-w-400"><strong>A.</strong> Do you have an ABN number? </label>
                <mat-checkbox (change)="toggle($event)" class="example-margin m-l-14" formControlName="is_abn_number">
                </mat-checkbox>
                <small class="m-t--8"> If you have no ABN please click/use this link to apply
                    <a class="f-12"
                        href="https://abr.gov.au/For-Business,-Super-funds---Charities/Applying-for-an-ABN/Apply-for-an-ABN/"
                        target="_blank">Click Here</a></small>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <ng-container *ngIf="checkBoxChange">
                    <section>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>If yes, ABN number?</mat-label>
                            <input type="text" matInput formControlName="abn_number">
                        </mat-form-field>
                    </section>
                </ng-container>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                <label class="f-14a f-13 m-b-14 f-w-400"><strong>B.</strong> Select the kind of service/care you can
                    provide:</label><br />
                <mat-checkbox class="example-margin" formControlName="b_school_care">Before and After School care
                </mat-checkbox>
                <mat-checkbox class="example-margin m-l-14" formControlName="b_age_care">Under School Age Care
                </mat-checkbox>
                <mat-checkbox class="example-margin m-l-14" formControlName="b_vacation_care">School Holidays/Vacation
                    Care</mat-checkbox>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                <label class="f-14a f-13 m-b-14 f-w-400"><strong>C.</strong> What days & hours are you
                    available?</label><br />
                <mat-checkbox (change)="toggleFullTime($event)" class="example-margin" formControlName="c_full_time">
                    Full time hours</mat-checkbox>
                <section *ngIf="checkFullTime" class="m-t-10">
                    <div class="row">
                        <div class="col-4">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Start Time</mat-label>
                                <input type="time" matInput formControlName="full_start_time">
                            </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Finish Time</mat-label>
                                <input type="time" matInput formControlName="full_finish_time">
                            </mat-form-field>
                        </div>
                    </div>
                </section>
                <br />
                <mat-checkbox (change)="togglePartTime($event)" class="example-margin " formControlName="c_part_time">
                    Part time hours</mat-checkbox>
                <section *ngIf="checkPartTime" class="m-t-10">
                    <div class="row">
                        <div class="col-4">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Start Time</mat-label>
                                <input type="time" matInput formControlName="part_start_time">
                            </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Finish Time</mat-label>
                                <input type="time" matInput formControlName="part_finish_time">
                            </mat-form-field>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 mb-2">
                <label class="f-14a f-13 m-b-14 f-w-400"><strong>D.</strong> Do you already have children you wish to
                    work with?</label>
                <mat-checkbox (change)="toggleD($event)" class="example-margin m-l-14"
                    formControlName="have_you_children"></mat-checkbox>
                <ng-container *ngIf="checkD">
                    <section class="">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>If yes how many?</mat-label>
                            <input type="number" matInput formControlName="children_count">
                        </mat-form-field>
                    </section>
                </ng-container>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                <h5 class="form-group-heading"><strong>E.</strong> Vehicle Information</h5>
                <mat-checkbox class="example-margin" formControlName="have_transport_vehicle">Do you plan to use a
                    vehicle to transport the children?</mat-checkbox><br />
                <mat-checkbox (change)="toggleLicense($event)" class="example-margin" formControlName="have_license">Do
                    you have a Driver’s License?</mat-checkbox><br />
                <section *ngIf="checkLicense" class="m-t-10">
                    <div class="row">
                        <div class="col-4">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>License Number</mat-label>
                                <input type="text" matInput formControlName="license_no">
                            </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-form-field class="example-full-width" appearance="outline"
                                style="padding-top: 0 !important;">
                                <mat-label>Expiry Date</mat-label>
                                <input matInput formControlName="license_expiry_date" [matDatepicker]="pickerEx">
                                <mat-datepicker-toggle matSuffix [for]="pickerEx"></mat-datepicker-toggle>
                                <mat-datepicker #pickerEx></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </section>
                <mat-checkbox (change)="toggleOwnCar($event)" class="example-margin" formControlName="have_own_car">Do
                    you own a car?</mat-checkbox><br />
                <section *ngIf="checkOwnCar" class="m-t-10">
                    <div class="row">
                        <div class="col-4">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Type Of Car</mat-label>
                                <input type="text" matInput formControlName="type_of_car">
                            </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-checkbox class="example-margin" formControlName="is_registered">Registered?
                            </mat-checkbox>
                        </div>
                    </div>
                </section>
                <mat-checkbox (change)="toggleIsurer($event)" class="example-margin" formControlName="is_insured">
                    Insured:?</mat-checkbox>
                <section *ngIf="checkInsurer" class="m-t-10">
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Name of Insurer</mat-label>
                                <input type="text" matInput formControlName="name_of_insurer">
                            </mat-form-field>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <br />
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                <h5 class="form-group-heading"><strong>F.</strong> Information about your residence</h5>
                <label class="f-14a f-13 m-b-14 f-w-400">Which type of residence/home applies to you? Select all that
                    apply to you</label><br />

                <mat-checkbox class="example-margin" formControlName="f_owned">Owned</mat-checkbox>
                <mat-checkbox class="example-margin m-l-14" formControlName="f_rented">Rented</mat-checkbox>
                <mat-checkbox class="example-margin m-l-14" formControlName="f_single_story">Single Story</mat-checkbox>
                <mat-checkbox class="example-margin m-l-14" formControlName="f_two_story">Two Storey</mat-checkbox>
                <mat-checkbox class="example-margin m-l-14" formControlName="f_villa">Villa</mat-checkbox>
                <mat-checkbox (change)="toggleFlat($event)" class="example-margin m-l-14" formControlName="f_home_unit">
                    Home Unit</mat-checkbox>
                <mat-checkbox (change)="toggleFlat($event)" class="example-margin m-l-14" formControlName="f_flat">Flat
                </mat-checkbox>
                <section *ngIf="checkFlat" class="m-t-10">
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>If you live in a unit/flat upstairs what level?</mat-label>
                                <input type="text" matInput formControlName="flat_level">
                            </mat-form-field>
                            <mat-checkbox class="example-margin" formControlName="flat_lifts"> Does the building have
                                elevators/lifts?</mat-checkbox>
                        </div>
                    </div>
                </section>

                <br />
                <br />
                <br />
                <mat-checkbox class="example-margin" formControlName="is_fully_fenced">Premises Fully Fenced
                </mat-checkbox>
                <mat-checkbox class="example-margin m-l-14" formControlName="is_child_proof">Child Proof</mat-checkbox>
                <mat-checkbox class="example-margin m-l-14" formControlName="is_gates">Locking Gates ?</mat-checkbox>
                <mat-checkbox class="example-margin m-l-14" formControlName="is_pets">Pets?</mat-checkbox><br />
                <mat-checkbox class="example-margin" (change)="toggleSafeToPlay($event)"
                    formControlName="is_backyard_safe">Is your backyard safe for the children to play?</mat-checkbox>
                <section *ngIf="checkSafeToPlay" class="m-l-25">
                    <div class="row">
                        <div class="col-6">
                            <mat-checkbox class="example-margin" formControlName="is_backyard_fully_fenced">If YES is it
                                Fully Fenced?</mat-checkbox>
                        </div>
                    </div>
                </section>
                <section *ngIf="!checkSafeToPlay" class="m-l-25">
                    <div class="row">
                        <div class="col-6">
                            <mat-checkbox (change)="toggleSafePark($event)" class="example-margin"
                                formControlName="is_near_park">If NO do you have a safe park/playground near your home?
                            </mat-checkbox>
                            <section *ngIf="checkSafePark">
                                <div class="row">
                                    <div class="col-6">
                                        <mat-checkbox class="example-margin" formControlName="is_park_fully_fenced">If
                                            YES is it Fully Fenced?</mat-checkbox>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
                <mat-checkbox (change)="togglePool($event)" class="example-margin" formControlName="is_have_pool">Do you
                    have a swimming pool?</mat-checkbox>
                <section *ngIf="checkPool" class="">
                    <div class="row">
                        <div class="col-6">
                            <mat-checkbox class="example-margin" formControlName="is_pool_safety">If YES do you have a
                                Pool Safety Compliance Inspection Certificate</mat-checkbox>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <br />
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                <h5 class="form-group-heading"><strong>G.</strong> Provide details of people who lives with you in the
                    house</h5>
                <label class="f-14a f-13 m-b-14 f-w-400 m-l-3">How many people live at the premises?</label><br />
                <div class="row">
                    <div class="col-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Adults</mat-label>
                            <input type="number" matInput formControlName="g_adults_count">
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Children</mat-label>
                            <input type="number" matInput formControlName="g_children_count">
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label> Pets(e.g. cat,dog)</mat-label>
                            <input type="number" matInput formControlName="g_single_count">
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label> Type of pet</mat-label>
                            <input type="text" matInput formControlName="g_married_count">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Other (please specify):</mat-label>
                            <input type="text" matInput formControlName="g_others">
                        </mat-form-field>
                    </div>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                <h5 class="form-group-heading"><strong>H.</strong> Spouse/partner</h5>

                <mat-checkbox class="example-margin" formControlName="have_spouse_partner"
                    (change)="togglePartner($event)">Do you have a spouse/partner?</mat-checkbox><br />
                <ng-container *ngIf="checkBoxChange1">
                    <section class="check-related-section m-t-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label> Details of your spouse/partner </mat-label>
                            <textarea rows="1" formControlName="partner_details" matInput required></textarea>
                        </mat-form-field>
                    </section>
                </ng-container>
                <label class="f-14a f-13 m-b-14 f-w-400 m-r-3 m-l-3">Is your spouse/partner living with you? </label>
                <mat-checkbox class="example-margin" formControlName="partner_living_with_you"
                    (change)="togglePartner2($event)"></mat-checkbox><br />
                <ng-container *ngIf="checkBoxChange2">
                </ng-container>
                <div class="row">
                    <div class="col-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Title</mat-label>
                            <mat-select formControlName="h_type">
                                <mat-option value="mr">Mr</mat-option>
                                <mat-option value="mrs">Mrs</mat-option>
                                <mat-option value="ms">Ms</mat-option>
                                <mat-option value="miss">Miss</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-5">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>First Name</mat-label>
                            <input type="text" matInput formControlName="h_first_name">
                        </mat-form-field>
                    </div>
                    <div class="col-5">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Last Name</mat-label>
                            <input type="text" matInput formControlName="h_last_name">
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field class="example-full-width" appearance="outline"
                            style="padding-top: 0 !important;">
                            <mat-label>Date of birth</mat-label>
                            <input matInput formControlName="h_date_of_birth" [matDatepicker]="pickerDob"
                                (click)="pickerDob.open()">
                            <mat-datepicker-toggle matSuffix [for]="pickerDob"></mat-datepicker-toggle>
                            <mat-datepicker #pickerDob></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <ng-container *ngIf="!checkBoxChange2">
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 mb-2">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Address</mat-label>
                                <input matInput formControlName="h_address_1">
                            </mat-form-field>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Suburb</mat-label>
                                <input matInput formControlName="h_suburb">
                            </mat-form-field>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>State</mat-label>
                                <mat-select formControlName="h_state_id">
                                    <mat-option *ngFor="let state  of allMembers['states']"
                                        value="{{ state.state_id }}">{{ state.state_name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Postal code</mat-label>
                                <input type="number" matInput formControlName="h_postal_code">
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Home Phone</mat-label>
                            <input matInput formControlName="h_home_phone">
                        </mat-form-field>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Mobile Phone</mat-label>
                            <input matInput formControlName="h_mobile_phone">
                        </mat-form-field>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                        <mat-checkbox class="example-margin" formControlName="h_is_smoker">Smoker (Checked, If YES)
                        </mat-checkbox>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Primary Language spoken</mat-label>
                            <input type="text" matInput formControlName="h_p_spoken_lng">
                        </mat-form-field>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Other Language/s</mat-label>
                            <input type="text" matInput formControlName="h_other_lng">
                        </mat-form-field>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Occupation</mat-label>
                            <input type="text" matInput formControlName="h_occupation">
                        </mat-form-field>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Employer</mat-label>
                            <input type="text" matInput formControlName="h_employer">
                        </mat-form-field>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Work Phone</mat-label>
                            <input matInput formControlName="h_work_phone">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                <label class="f-14a f-13 m-b-14 f-w-400"><strong>I. Are there any other people living in your household?
                    </strong>
                    (if applicable include all children over 18 years of age, partner and any other adults that live
                    with you as they will be all required to do a Working with Children Check
                    before you can work)</label><br />
                <table class="table table-bordered">
                    <tbody>
                        <tr formArrayName="peoples"
                            *ngFor="let people of sectionForm.get('peoples')['controls']; let i = index;">
                            <td style="vertical-align: top; width: 20px;">{{ i + 1}}</td>
                            <td>
                                <div class="row" [formGroupName]="i">
                                    <div class="col-md-3">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>First Name</mat-label>
                                            <input type="text" matInput formControlName="i_first_name">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Last Name</mat-label>
                                            <input type="text" matInput formControlName="i_last_name">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>DOB</mat-label>
                                            <input matInput formControlName="i_dob" [matDatepicker]="pickeriDOB"
                                                (click)="pickeriDOB.open()">
                                            <mat-datepicker-toggle matSuffix [for]="pickeriDOB"></mat-datepicker-toggle>
                                            <mat-datepicker #pickeriDOB></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Country Of Birth</mat-label>
                                            <input type="text" matInput formControlName="i_country_birth">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Driver’s License/ Photo ID/Passport No</mat-label>
                                            <input type="text" matInput formControlName="i_id">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Relationship</mat-label>
                                            <input type="text" matInput formControlName="i_relationship">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3">
                                        <mat-checkbox class="example-margin" formControlName="i_is_smoke">Smoker
                                            (Checked, If YES)</mat-checkbox>
                                    </div>
                                    <div class="col-md-3">
                                        <a class="text-underline pointer" href="javascript:void(0)"
                                            (click)="removeRow(i)">Remove</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button type="button" class="btn btn-info btn-sm pull-right m-b-10" (click)="addPeople(null)">Add
                    Person</button>
            </div>
        </div>

        <div>
            <h6>Please return this form with a copy of</h6>
            <ul>
                <li>Your 100 points of ID <a href="assets/data/Proof-of-Identity 100 points.pdf" target="_blank">See
                        Document</a></li>
                <li>100 points of ID of all adults living with you</li>
                <li>Copy of Your CV if you have one and all qualifications</li>
            </ul>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2 p-l-0">
                <p class="text-black text-underline f-12">Attachments</p>
                <app-custom-dropzone (filePass)="uploadFiles($event)"></app-custom-dropzone>
                <app-dropzone-priview [items]="dropZoneFilesPreview" (removeItemEvent)="resetUploadFilePreview($event)">
                </app-dropzone-priview>
                <app-dropzone-recieved [items]="dropZoneFiles" (removeItemEvent)="resetUploadFile($event)">
                </app-dropzone-recieved>
            </div>
            <!--        <p>To apply for working with children checks for you and all the adults over 18 years of age that live with you please use the details below and it is important to keep you application-->
            <!--          number. Online link is <a href="http://www.kids.nsw.gov.au" target="_blank">http://www.kids.nsw.gov.au</a> click the blue working with children check button on the right then the ‘Start Here’button to apply. Once the form is completed-->
            <!--          online, take the confirmation of application in person with identification e.g. Driver’s license, NSW Photo ID, Passport to you nearest RMS station or formerly known as RTA</p>-->
        </div>


        <h5 class="form-group-heading">Please fill out all of the below questions. If you are not sure, please contact
            one of our staff members for assistance.</h5>
        <div class="row">
            <div class="col-12 m-t-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>1. Why are you interested in working in the Family Day Care industry?</mat-label>
                    <textarea matInput formControlName="question_1" rows="1"></textarea>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>2. Please tell us about your experience in working with children...</mat-label>
                    <textarea matInput formControlName="question_2" rows="1"></textarea>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>3. What do you think the key responsibilities of a Family Day Care educator are?
                    </mat-label>
                    <textarea matInput formControlName="question_3" rows="1"></textarea>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>4. What kind of activities do you enjoy doing with children?</mat-label>
                    <textarea matInput formControlName="question_4" rows="1"></textarea>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>5. How would parents find out about their children’s day?</mat-label>
                    <textarea matInput formControlName="question_5" rows="1"></textarea>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>6. Tell us how you have made your house safe for children?</mat-label>
                    <textarea matInput formControlName="question_6" rows="1"></textarea>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>7. How would you handle disagreements between two children?</mat-label>
                    <textarea matInput formControlName="question_7" rows="1"></textarea>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>8. What is Child Protection and how might it apply in an FDC setting?</mat-label>
                    <textarea matInput formControlName="question_8" rows="1"></textarea>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>9. List 3 possible types of abuse.</mat-label>
                    <textarea matInput formControlName="question_9" rows="1"></textarea>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>10. What is a Learning Story?</mat-label>
                    <textarea matInput formControlName="question_10" rows="1"></textarea>
                </mat-form-field>
            </div>
            <div class="col-12 f-12">
                <mat-checkbox class="example-margin" formControlName="question_11">11. Do you agree to attend
                    professional training, regular meeting, both internally and externally, face to face and/or virtual
                    meetings ?</mat-checkbox>
                <section class="check-related-section m-t-3">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Why do you think this is important? </mat-label><textarea rows="1"
                            formControlName="important_details" matInput></textarea>
                    </mat-form-field>
                </section>
            </div>
            <div class="col-12 f-12">
                <mat-checkbox class="example-margin" formControlName="question_12" (change)="toggleDetails($event)">12.
                    Do you hold a public liability insurance?</mat-checkbox>
                <ng-container *ngIf="!checkBoxChange3">
                    <section class="check-related-section m-t-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>When are you able to obtain this? Please provide details </mat-label><textarea
                                rows="1" formControlName="provide_details" matInput></textarea>
                        </mat-form-field>
                    </section>
                </ng-container>
            </div>
            <div class="col-12 f-12">
                <mat-checkbox class="example-margin" formControlName="question_13">13. Do you have a minimum
                    qualification in Certificate III or Diploma?</mat-checkbox>
                <section class="check-related-section m-t-3">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Please provide details </mat-label><textarea rows="1"
                            formControlName="qualification_details" matInput></textarea>
                    </mat-form-field>
                </section>
            </div>
            <div class="col-12 ">
                <mat-label class="f-12">14. Do you have current First Aid, Asthma, Anaphylaxis Certificate and CPR if
                    not you need to do your course
                    before commencing work, if yes please provide the obtained date?</mat-label>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2 p-t-15">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Date</mat-label>
                        <input matInput formControlName="question_14" [matDatepicker]="pickerQ14"
                            (click)="pickerQ14.open()">
                        <mat-datepicker-toggle matSuffix [for]="pickerQ14"></mat-datepicker-toggle>
                        <mat-datepicker #pickerQ14></mat-datepicker>
                    </mat-form-field>
                </div>

            </div>
            <div class="col-12 f-12">
                <mat-checkbox class="example-margin f-12" formControlName="question_15">15. Do you have a current police
                    check (within six months of application to the service)? </mat-checkbox>
                <section class="check-related-section m-t-3">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Please provide details </mat-label><textarea rows="1"
                            formControlName="police_details" matInput></textarea>
                    </mat-form-field>
                </section>
            </div>
            <div class="col-12 m-t-12 ">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>16. Do you encourage parental involvement? In what ways?</mat-label>
                    <textarea matInput formControlName="question_16" rows="1"></textarea>
                </mat-form-field>
            </div>
            <div class="col-12 f-12">
                <mat-checkbox (change)="toggleQ17($event)" class="example-margin" formControlName="have_you_children">
                    17. Have you or any of family member had dealings with Department of Education or non-compliance
                    history
                    record with your previous Family Day Care Scheme?</mat-checkbox>
                <ng-container *ngIf="checkQ17">
                    <section class="m-l-14 m-t-12">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>If YES Please provide details.</mat-label>
                            <textarea matInput formControlName="question_17_des" rows="1"></textarea>
                        </mat-form-field>
                    </section>
                </ng-container>
            </div>
            <div class="col-12 m-t-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>18. How would handle a parent/guardian suggestions or complaint? How would you apply this
                        feedback to improve your service? </mat-label>
                    <textarea matInput formControlName="question_18" rows="1"></textarea>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>19. What are your fees and why did you use this type of fee?</mat-label>
                    <textarea matInput formControlName="question_19" rows="1"></textarea>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>20. How did you hear about us? </mat-label>
                    <textarea matInput formControlName="question_20" rows="1"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p><strong>By having signed below, I agree that all the details I provided is true and correct.
                        I agree to indemnify {{ loggedUser['user']['center_name']}} for any misleading information or
                        fraud.</strong></p>
                <br />
                <br />
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2" style="margin-top: -27px;"
                *ngIf="loggedUser['role_id'] == 6">
                <label class="f-12">Your Signature</label>
                <div *ngIf="!isNewSignature">
                    <!--            <span *ngIf="isEdit"  (click)="removeSignature()" class="badge badge-danger remove-signature pointer b-r-15">x</span>-->
                    <img src="{{ signatureImg }}">
                </div>
                <!--          <ng-container *ngIf="isNewSignature">-->
                <!--            <app-signature-pads (signaturePadEmit)="signatureImgOutput($event)"></app-signature-pads>-->
                <!--          </ng-container>-->
                <br />
                <br />
                <mat-form-field class="example-full-width" appearance="outline" style="padding-top: 0 !important;">
                    <mat-label>Date</mat-label>
                    <input matInput formControlName="s_date" [matDatepicker]="s_date" (click)="s_date.open()" disabled>
                    <mat-datepicker-toggle matSuffix [for]="s_date"></mat-datepicker-toggle>
                    <mat-datepicker #s_date></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2" style="margin-top: -27px;"
                *ngIf="loggedUser['role_id'] != 6">
                <label class="f-12">Your Signature</label>
                <div *ngIf="!isNewSignature">
                    <span *ngIf="isEdit" (click)="removeSignature()"
                        class="badge badge-danger remove-signature pointer b-r-15">x</span>
                    <img src="{{ signatureImg }}">
                </div>
                <ng-container *ngIf="isNewSignature">
                    <app-signature-pads (signaturePadEmit)="signatureImgOutput($event)"></app-signature-pads>
                </ng-container>
                <br />
                <br />
                <mat-form-field class="example-full-width" appearance="outline" style="padding-top: 0 !important;">
                    <mat-label>Date</mat-label>
                    <input matInput formControlName="s_date" [matDatepicker]="s_date" (click)="s_date.open()">
                    <mat-datepicker-toggle matSuffix [for]="s_date"></mat-datepicker-toggle>
                    <mat-datepicker #s_date></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <h5 class="form-group-heading">INTERVIEW : Coordination unit member completing educator interview</h5>
        <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Position</mat-label>
                    <mat-select formControlName="r_position"
                        (selectionChange)="$event.value == 'Other' ? otherPosition = true : otherPosition = false">
                        <mat-option value="Administration Officer">Administration Officer</mat-option>
                        <mat-option value="Assistant Administration Officer">Assistant Administration Officer
                        </mat-option>
                        <mat-option value="Assistant Director">Assistant Director</mat-option>
                        <mat-option value="Business Development Officer">Business Development Officer</mat-option>
                        <mat-option value="Cleaner">Cleaner</mat-option>
                        <mat-option value="Cook">Cook</mat-option>
                        <mat-option value="Coordinator">Coordinator</mat-option>
                        <mat-option value="Director">Director</mat-option>
                        <mat-option value="Early Childhood Teacher">Early Childhood Teacher</mat-option>
                        <mat-option value="Educator">Educator</mat-option>
                        <mat-option value="Educator Assistant">Educator Assistant</mat-option>
                        <mat-option value="Family Day Care Educator">Family Day Care Educator</mat-option>
                        <mat-option value="Manager">Manager</mat-option>
                        <mat-option value="Nominated Supervisor">Nominated Supervisor</mat-option>
                        <mat-option value="Operational Manager">Operational Manager</mat-option>
                        <mat-option value="Room Leader">Room Leader</mat-option>
                        <mat-option value="Supervisor">Supervisor</mat-option>
                        <mat-option value="Trainee">Trainee</mat-option>
                        <mat-option value="Other">Other </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2" *ngIf="otherPosition">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Other Position</mat-label>
                    <input type="text" matInput formControlName="r_other_position">
                </mat-form-field>
            </div>

            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>interview has been conducted with</mat-label>
                    <mat-select formControlName="r_interview_conducted">
                        <mat-option value="In Person">In Person</mat-option>
                        <mat-option value="On Phone">On Phone</mat-option>
                        <mat-option value="Virtual Meeting">Virtual Meeting</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline" style="padding-top: 0 !important;">
                    <mat-label>Date</mat-label>
                    <input matInput formControlName="r_date" [matDatepicker]="r_date" (click)="r_date.open()">
                    <mat-datepicker-toggle matSuffix [for]="r_date"></mat-datepicker-toggle>
                    <mat-datepicker #r_date></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Reminder notification</mat-label>
                    <mat-select formControlName="r_interview_notification">
                        <mat-option value="30 days before">30 days before</mat-option>
                        <mat-option value="14 days before">14 days before</mat-option>
                        <mat-option value="7 days before">7 days before</mat-option>
                        <mat-option value="1 day before">1 day before</mat-option>
                        <mat-option value="1 hour before">1 hour before</mat-option>
                        <mat-option value="15 minutes before">15 minutes before</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline" style="padding-top: 0 !important;">
                    <mat-label>Interview completed</mat-label>
                    <input matInput formControlName="r_interview_completed" [matDatepicker]="r_interview_completed"
                        (click)="r_interview_completed.open()">
                    <mat-datepicker-toggle matSuffix [for]="r_interview_completed"></mat-datepicker-toggle>
                    <mat-datepicker #r_interview_completed></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <h5 class="form-group-heading">Completed by Coordinator or Educator</h5>
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Received by Coordinator or Educator</mat-label>
                    <mat-select formControlName="interview_staff_user_id">
                        <mat-option value="{{ member.user_id }}" *ngFor="let member of allMembers['members']">{{
                            member.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="!isNewSignature2">
                    <span *ngIf="isEdit" (click)="removeSignature2()"
                        class="badge badge-danger remove-signature pointer b-r-15">x</span>
                    <img src="{{ signatureImg2 }}">
                </div>
                <ng-container *ngIf="isNewSignature2">
                    <app-signature-pads (signaturePadEmit)="signatureImgOutput2($event)"></app-signature-pads>
                </ng-container>
                <br />
                <br />
                <mat-form-field class="example-full-width" appearance="outline" style="padding-top: 0 !important;">
                    <mat-label>Signature Date</mat-label>
                    <input matInput formControlName="coordinator_signature_date" [matDatepicker]="cs_picker"
                        (click)="cs_picker.open()">
                    <mat-datepicker-toggle matSuffix [for]="cs_picker"></mat-datepicker-toggle>
                    <mat-datepicker #cs_picker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <input type="hidden" formControlName="pre_assessment_educator_form_ref">

    </div>
    <div class="modal-footer">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <button mat-flat-button color="primary" [disabled]="submitted " [class.spinner]="submitted"
                class="pull-right  text-white" type="submit">
                Submit
            </button>
        </div>
    </div>
</form>
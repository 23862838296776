<nav #navbar class="navbar active bg-primary-green">
  <div class="container-fluid">
    <div class="navbar-header">
      <a
        href="#"
        onClick="return false;"
        class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed"
        aria-expanded="false"
      ></a>
      <a
        href="#"
        onClick="return false;"
        class="bars"
        (click)="mobileMenuSidebarOpen($event, 'overlay-open')"
      ></a>
      <a
        class="navbar-brand"
        style="margin-left: unset !important"
      >
        <img src="assets/logo.png" alt="" style="height: 39px" />
        <!--        <span class="logo-name">ACESS</span>-->
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <div class="nav_menu_top">
        <div class="hamburger_menu">
          <ul class="pull-left collapse-menu-icon">
            <li>
              <button
                mat-icon-button
                (click)="callSidemenuCollapse()"
                class="sidemenu-collapse"
              >
                <mat-icon>menu</mat-icon>
              </button>
            </li>
          </ul>
        </div>
        <div class="name_user">
          <h4 class="mb-0 text-white">{{ userData["user"]["center_name"] }}</h4>
        </div>
        <div class="profile_menu">
          <ul class="nav navbar-nav navbar-right">
            <li class="" title="Search menu">
              <button
                mat-icon-button
                (click)="dialog()"
                class="nav-notification-icons setting-btn"
              >
                <mat-icon id="settingBtn">search</mat-icon>
              </button>
            </li>

            <!-- Full Screen Button -->
            <li
              class="fullscreen d-sm-none d-md-none d-lg-block"
              title="Full-screen"
            >
              <button
                mat-icon-button
                (click)="callFullscreen()"
                class="nav-notification-icons"
              >
                <mat-icon>fullscreen</mat-icon>
              </button>
            </li>
            <!-- #END# Full Screen Button -->

            <!-- #START# Notifications-->
            <li class="nav-item" ngbDropdown title="Notifications">
              <button
                *ngIf="userData.is_temp_user != 1"
                mat-icon-button
                ngbDropdownToggle
                class="nav-notification-icons"
              >
                <mat-icon>notifications_none</mat-icon>
                <span
                  *ngIf="notifications.length > 0"
                  class="label-count bg-orange"
                ></span>
              </button>
              <div ngbDropdownMenu class="notification-dropdown pullDown">
                <div
                  class="noti-list"
                  style="
                    position: relative;
                    max-width: 600px;
                    max-height: 250px;
                  "
                  [perfectScrollbar]
                >
                  <ul class="menu">
                    <li
                      *ngIf="notifications.length < 1"
                      style="text-align: center"
                    >
                      No Notification found
                    </li>
                    <li *ngIf="notifications.length > 0">
                      <a
                        href=""
                        *ngFor="
                          let notification of notifications | slice : 0 : 5
                        "
                        (click)="openNotificationItem($event, notification)"
                      >
                        <span class="table-img msg-user">
                          <img
                            *ngIf="notification['created_user_photo'] == null"
                            src="assets/images/users.jpg"
                            alt=""
                          />
                          <img
                            *ngIf="notification['created_user_photo'] != null"
                            src="{{ notification['created_user_photo'] }}"
                            alt=""
                          />
                        </span>
                        <span class="menu-info">
                          <span class="menu-title">{{
                            notification["created_user_name"]
                          }}</span>
                          <span class="menu-desc">
                            <i class="material-icons">access_time</i>
                            {{ notification["time_ago"] }}
                          </span>
                          <span class="menu-desc"
                            >{{
                              notification["description"] | truncatetext : 55
                            }}
                          </span>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <a
                  class="text-center d-block border-top py-1"
                  *ngIf="notifications.length > 5"
                  >Read All Notifications</a
                >
              </div>
            </li>
            <!-- #END# Notifications-->

            <li class="" title="Switch Child" *ngIf="userData.role == 'parent'">
              <button
                mat-icon-button
                (click)="switchChild()"
                class="nav-notification-icons setting-btn"
              >
                <mat-icon id="settingBtn">autorenew</mat-icon>
              </button>
            </li>
            <li
              class=""
              title="Switch Branch"
              *ngIf="userData.role == 'director' && branch_count > 1"
            >
              <button
                mat-icon-button
                (click)="switchBranch()"
                class="nav-notification-icons setting-btn"
              >
                <mat-icon id="settingBtn2">autorenew</mat-icon>
              </button>
            </li>

            <li class="nav-item user_profile" ngbDropdown>
              <a
                href="#"
                onClick="return false;"
                *ngIf="!userData"
                ngbDropdownToggle
                role="button"
                class="nav-notification-icons pt-0"
              >
                <img
                  src="assets/images/user.jpg"
                  class="rounded-circle"
                  width="32"
                  height="32"
                  alt="User"
                />
              </a>
              <a
                href="#"
                *ngIf="userData"
                onClick="return false;"
                ngbDropdownToggle
                role="button"
                class="nav-notification-icons pt-0"
              >
                <img
                  *ngIf="userData.profile_image !== null"
                  class="rounded-circle"
                  style="object-fit: cover"
                  width="32"
                  height="32"
                  alt="User"
                  src="{{ userData.profile_image }}"
                />
                <img
                  *ngIf="userData.profile_image === null"
                  class="rounded-circle"
                  style="object-fit: cover"
                  width="32"
                  height="32"
                  alt="User"
                  src="assets/images/users.jpg"
                />
              </a>

              <div ngbDropdownMenu class="notification-dropdown pullDown">
                <div
                  class="noti-list"
                  style="
                    position: relative;
                    max-width: 600px;
                    max-height: 300px;
                  "
                  [perfectScrollbar]
                >
                  <ul class="menu">
                    <li class="body">
                      <ul class="user_dw_menu">
                        <li *ngIf="loggedUser.role_id != 1">
                          <a
                            href="javascript:void(0)"
                            (click)="navigateProfile()"
                          >
                            <mat-icon aria-hidden="false" class="mr-2"
                              >person</mat-icon
                            >Profile
                          </a>
                        </li>
                        <li>
                          <a href="#" onClick="return false;">
                            <mat-icon aria-hidden="false" class="mr-2"
                              >settings</mat-icon
                            >Settings
                          </a>
                        </li>
                        <li>
                          <a
                          href="javascript:void(0)"
                          (click)="navigateSupportAcess()"
                        >
                          <mat-icon aria-hidden="false" class="mr-2"
                            >help</mat-icon
                          >Support
                        </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" (click)="logout()">
                            <mat-icon aria-hidden="false" class="mr-2"
                              >power_settings_new</mat-icon
                            >Logout
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <!-- #END# Tasks -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>
<ng-template #formModal let-modal>
  <div class="modal-body">
    <div class="w-100-p" align="right">
      <button
        mat-icon-button
        (click)="modalRef.dismiss()"
        class="bg-light-gray m-r-14 dash-model-close z-10"
        aria-label="Close dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <app-relief-educator-notification-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'relief_educator'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    ></app-relief-educator-notification-view>

    <app-f1-incident-notification-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'f1_incident_form'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    ></app-f1-incident-notification-view>

    <app-authorisation-rmp-notification-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'authorisation_rmp_form'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    >
    </app-authorisation-rmp-notification-view>

    <app-monthly-observation-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'monthly_observation_form'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    ></app-monthly-observation-view>

    <app-weekly-pragram-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'weekly_programming'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    ></app-weekly-pragram-view>

    <app-daily-reflection-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'daily_reflection_form'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    ></app-daily-reflection-view>

    <app-food-s-n-change-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'food_s_n_change_forms'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    ></app-food-s-n-change-view>

    <app-child-asthma-mgt-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'child_asthma_form'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    ></app-child-asthma-mgt-view>

    <app-risk-minimization-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'risk_minimisation_form'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    ></app-risk-minimization-view>

    <app-child-absence-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'leaves_of_absence'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    ></app-child-absence-view>

    <app-child-termination-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'termination_care'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    ></app-child-termination-view>

    <app-family-input-sheet-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'family_input_sheet_form'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    ></app-family-input-sheet-view>

    <app-educator-development-appraisal-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'educator_performance_development'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    ></app-educator-development-appraisal-view>

    <app-missed-monitoring-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'missed_monitoring'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    ></app-missed-monitoring-view>

    <app-monitoring-support-visit
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'monitoring_support'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    ></app-monitoring-support-visit>

    <app-educator-leave-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'educator_leaves_form'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    ></app-educator-leave-view>

    <app-educator-p-leave-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'educator_permanent_leaves_form'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    ></app-educator-p-leave-view>

    <app-educator-clearance-items-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'educator_clearance_items'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    >
    </app-educator-clearance-items-view>

    <app-report-grievance-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'grievance_forms'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    >
    </app-report-grievance-view>

    <app-change-booking-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'change_booking_form'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    >
    </app-change-booking-view>

    <app-first-aid-check-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'first_aid_check_form'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    >
    </app-first-aid-check-view>

    <app-educator-registration-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'educator_registration'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    >
    </app-educator-registration-view>

    <app-staff-registration-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'staff_registration'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    >
    </app-staff-registration-view>

    <app-vacation-care-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'vacation_care'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    >
    </app-vacation-care-view>
    <app-medication-view
      [notificationRef]="notificationRef"
      *ngIf="selectedType == 'medication'"
      [rowRef]="selectedRef"
      (closeModal)="closeModelFun($event)"
    >
    </app-medication-view>
  </div>
</ng-template>

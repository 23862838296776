<div class="row p-15">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13">
    <h5 class="form-group-heading" *ngIf="headerHide != 1">Child Details</h5>
    <ng-container *ngIf="loggedUser['role_id'] != 6">
      <div class="row">
        <div class="col-3" *ngFor="let sChild of selectedChildren">
          <div class="display-inline">
            <img
              class="rounded-circle size-40"
              src="{{ sChild['url'] }}"
              alt="user"
              title=""
              matTooltip="{{ sChild['child_givenname'] }} {{
                sChild['child_surname']
              }}"
            />
          </div>
          <div class="display-inline m-l-11">
            {{ sChild["child_givenname"] }} {{ sChild["child_surname"] }}
          </div>
        </div>
      </div>
      <br />
      <p><strong>Period Type: </strong>{{ data["period_type"] }}</p>
      <p *ngIf="data['period_type'] == 'DAILY'">
        <strong>Programming Date : </strong>{{ data["c_date"] | date : "longDate" }}
      </p>
      <p *ngIf="data['period_type'] == 'WEEKLY'">
        <strong>Programming Date : </strong>{{ data["c_date"] | date : "longDate" }} - {{ data["c_date_2"] | date : "longDate" }}
      </p>
    </ng-container>
    <ng-container *ngIf="loggedUser['role_id'] == 6">
      <table class="table table-sm border-0">
        <tr>
          <td style="width: 20%">Child Name</td>
          <td>: {{ data["child_name"] }}</td>
        </tr>
        <tr>
          <td>Date of birth</td>
          <td>: {{ data["child_dob"] | date : "longDate" }}</td>
        </tr>
        <tr>
          <td>Created Date</td>
          <td>: {{ data["c_date"] | date : "longDate" }}</td>
        </tr>
      </table>
    </ng-container>

    <h5 class="form-group-heading">Other Details</h5>
    <p>
      <strong>Children have a strong sense of identity: </strong><br />
      {{ data["strong_sense"] }}
    </p>
    <p>
      <strong
        >Children are connected with and contribute to their world : </strong
      ><br />{{ data["contribute_world"] }}
    </p>
    <p>
      <strong>Children have a strong sense of well being : </strong><br />{{
        data["sense_well_being"]
      }}
    </p>
    <p>
      <strong>Children are confident and involve learners : </strong><br />{{
        data["involve_learners"]
      }}
    </p>
    <p>
      <strong>Children are effective communicators : </strong><br />{{
        data["communicators"]
      }}
    </p>
    <p><strong>Spontaneous : </strong><br />{{ data["spontaneous"] }}</p>
    <p><strong>Family Input : </strong><br />{{ data["family_input_text"] }}</p>
    <p><strong>Link to Family Input : </strong><br />{{ data["linktofamilyinputtxtgoals"] }}</p>
    <p><strong>Link to Family Suggested Follow Up Activities : </strong><br />{{ data["linktofamilyinputtxtact"] }}</p>

    <p>
      <strong>Activity/Learning Experience : </strong><br />{{
        data["activity_learning_experience"]
      }}
    </p>

    <p *ngIf="data['question']">
      <strong>My Reflection : </strong>{{ data["question"] }}
    </p>
    <p *ngIf="data['reflection_answer']">
      <strong>Details : </strong>{{ data["reflection_answer"] }}
    </p>
    <p>
      <strong>Link to the extension of learning, enter the date : </strong
      ><br />{{ data["extension_date"] | date : "longDate" }}
    </p>
    <br />
    <p>
      <strong>Notes For Future Program Planning: </strong><br />{{
        data["notes_for_future_planning"]
      }}
    </p>
    <br />

    <ng-container *ngIf="loggedUser['role_id'] != 6">
      <h5 class="form-group-heading">Parent Feedback</h5>
      <div class="row">
        <div class="col-12" *ngFor="let feedback of parentsFeedback">
          <p>
            <strong
              >{{ feedback["child_name"] }}'s parent -
              {{ feedback["parent_name"] }} - </strong
            >
            {{ feedback["created_date"] | date : "longDate" }} : <br />
            {{ feedback["feedback"] }}
          </p>
        </div>
      </div>
      <p *ngIf="parentsFeedback.length < 1">No feedback found.</p>
    </ng-container>

    <form
      class="register-form filter-form"
      [formGroup]="sectionForm"
      *ngIf="loggedUser['role_id'] == 6"
    >
      <h5 class="form-group-heading">Parent Feedback</h5>
      <p *ngIf="data['feedback'] != null">{{ data["feedback"] }}</p>
      <div class="row" *ngIf="data['feedback'] == null">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field
            class="example-full-width"
            appearance="outline"
            style="padding-top: 0 !important"
          >
            <mat-label>Parent Feedback</mat-label>
            <textarea
              matInput
              formControlName="feedback"
              rows="2"
              required
            ></textarea>
          </mat-form-field>
        </div>
      </div>
      <br />
      <div
        class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2"
        *ngIf="data['feedback'] == null"
      >
        <button
          mat-flat-button
          color="primary"
          (click)="formSubmit(sectionForm)"
          class="pull-right text-white"
          type="submit"
        >
          Submit
        </button>
        <button
          class="pull-right cancel-btn text-white mr-3 bg-light-red"
          type="button"
          (click)="modalClose()"
          mat-raised-button
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </form>
  </div>
</div>

<!-- ng-image-fullscreen-view  -->
<ng-image-fullscreen-view
  [images]="data['images']"
  [imageIndex]="currentIndex"
  [show]="showFlag"
  (close)="closeEventHandler()"
></ng-image-fullscreen-view>

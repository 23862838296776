<form class="register-form" [formGroup]="childrenForm" (ngSubmit)="childrenFormSubmit(true)">
    <div class="modal-body">
        <div class="row m-t-15">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <h5 class="modal-title text-black" id="modal-basic-title1">
                    Child/Children Involved
                </h5>
                <br />
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <span matPrefix>
                        <mat-icon class="mr-2">search</mat-icon>
                    </span>
                    <input matInput (keyup)="filterChild($event, selectionlist)" placeholder="Type here to search for Child/Children" />
                </mat-form-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-checkbox #childrenCB [checked]="isCheckedAll" (change)="selectAllAction($event,selectionlist)">Select all</mat-checkbox>
            </div>
        </div>
        <mat-selection-list formControlName="child_ids"
            (selectionChange)="MSLSelectionChange($event.option, selectionlist)" #selectionlist>
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2" *ngFor="let child of allMembers['children']">
                    <mat-list-option [value]="child.child_id">
                        <div class="display-inline mat-select-custom">
                            <img src="{{ child.url }}" class="rounded-circle" />
                            <span>{{ child.child_givenname }} {{ child.child_surname }}</span>
                        </div>
                    </mat-list-option>
                </div>
            </div>
        </mat-selection-list>
    </div>
    <div class="modal-footer">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <button mat-flat-button color="primary" class="pull-right text-white" type="submit">
                Select
            </button>
            <button (click)="cancelMatSel()" class="pull-right cancel-btn text-white mr-3 bg-light-red" type="button"
                mat-raised-button data-dismiss="modal">
                Cancel
            </button>
        </div>
    </div>
</form>
<div class="row p-15">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13">
    <h5 class="form-group-heading" *ngIf="headerHide != 1">Child Details</h5>
    <ng-container *ngIf="loggedUser['role_id'] != 6">
      <div class="row">
        <div class="col-3" *ngFor="let sChild of selectedChildren">
          <div class="display-inline">
            <img
              class="rounded-circle size-40"
              src="{{ sChild['url'] }}"
              alt="user"
              title=""
              matTooltip="{{ sChild['child_givenname'] }} {{
                sChild['child_surname']
              }}"
            />
          </div>
          <div class="display-inline m-l-11">
            {{ sChild["child_givenname"] }} {{ sChild["child_surname"] }}
          </div>
        </div>
      </div>
      <br />
      <p>
        <strong>Created Date : </strong>{{ data["date"] | date : "longDate" }}
      </p>
    </ng-container>
    <ng-container *ngIf="loggedUser['role_id'] == 6">
      <table class="table table-sm border-0">
        <tr>
          <td style="width: 20%">Child Name</td>
          <td>: {{ data["child_name"] }}</td>
        </tr>
        <tr>
          <td>Date of birth</td>
          <td>: {{ data["child_dob"] | date : "longDate" }}</td>
        </tr>
        <tr>
          <td>Created Date</td>
          <td>: {{ data["date"] | date : "longDate" }}</td>
        </tr>
      </table>
    </ng-container>

    <h5 class="form-group-heading">{{ data["title"] }}</h5>
    <p>{{ data["description"] }}</p>

    <h5 class="form-group-heading">Daily Reflection</h5>
    <p *ngIf="data['monthly_observation_title']">
      <strong>Link To Learning Story, Date and Title : </strong
      >{{ data["monthly_observation_title"] }}
    </p>
    <p *ngIf="data['question']">
      <strong>Reflection Question: </strong>{{ data["question"] }}
    </p>
    <p *ngIf="data['custom_question']">
      <strong>Critical Reflection : </strong>{{ data["custom_question"] }}
    </p>
    <p *ngIf="data['reflection_answer']">
      <strong>My Reflection : </strong>{{ data["reflection_answer"] }}
    </p>
    <p *ngIf="data['outcome_description']">
      <strong>Linking to the EYLF Outcomes : </strong
      >{{ data["outcome_description"] }}
    </p>
    <p *ngIf="data['option_description']">
      <strong>EYLF Sub Outcomes : </strong>{{ data["option_description"] }}
    </p>
    <p *ngIf="data['principle_eylf']">
      <strong>Principles : </strong>{{ data["principle_eylf"] }}
    </p>
    <p *ngIf="data['principle_eylf']">
      Details : {{ data["pri_eylf_description"] }} <br />
    </p>
    <p *ngIf="data['practice_eylf']">
      <strong>Practice : </strong>{{ data["practice_eylf"] }}
    </p>
    <p *ngIf="data['practice_eylf']">
      <strong>Details : </strong>{{ data["pra_eylf_description"] }} <br />
    </p>

    <p *ngIf="data['mtop_outcome_description']">
      <strong>Linking to the MTOP Outcomes : </strong
      >{{ data["mtop_outcome_description"] }}
    </p>
    <p *ngIf="data['mtop_option_description']">
      <strong>MTOP Sub Outcomes : </strong>{{ data["mtop_option_description"] }}
    </p>
    <p *ngIf="data['principle_mtop']">
      <strong>Principles : </strong>{{ data["principle_mtop"] }}
    </p>
    <p *ngIf="data['principle_mtop']">
      Details : {{ data["pri_mtop_description"] }} <br />
    </p>
    <p *ngIf="data['practice_mtop']">
      <strong>Practice : </strong>{{ data["practice_mtop"] }}
    </p>
    <p *ngIf="data['practice_mtop']">
      <strong>Details : </strong>{{ data["pra_mtop_description"] }} <br />
    </p>

    <p *ngIf="data['theorists_philosophies']">
      <strong>Linking to the Theorists/Philosophies : </strong
      >{{ data["theorists_philosophies"] }}
    </p>

    <h5 class="form-group-heading">Images/Videos</h5>
    <div class="row text-center text-lg-left">
      <div
        class="d-block p-10 img-preview pointer"
        *ngFor="let image of data['images']; let i = index"
        (click)="showLightbox(i)"
      >
        <mat-icon class="center-icon" *ngIf="image['is_video']"
          >play_arrow</mat-icon
        >
        <img
          class="img-fluid img-thumbnail"
          src="{{ image['thumbImage'] }}"
          alt=""
        />
      </div>
    </div>

    <br />
    <br />

    <ng-container *ngIf="loggedUser['role_id'] != 6">
      <h5 class="form-group-heading">Parent Feedback</h5>
      <div class="row">
        <div class="col-12" *ngFor="let feedback of parentsFeedback">
          <p>
            <strong
              >{{ feedback["child_name"] }}'s parent -
              {{ feedback["parent_name"] }}</strong
            >
            ({{ feedback["created_date"] | date : "longDate" }}) : <br />
            {{ feedback["feedback"] }}
          </p>
        </div>
      </div>
      <p *ngIf="parentsFeedback.length < 1">No feedback found.</p>
    </ng-container>

    <form
      class="register-form filter-form"
      [formGroup]="sectionForm"
      *ngIf="loggedUser['role_id'] == 6"
    >
      <h5 class="form-group-heading">Parent Feedback</h5>
      <p *ngIf="data['feedback'] != null">{{ data["feedback"] }}</p>
      <div class="row" *ngIf="data['feedback'] == null">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field
            class="example-full-width"
            appearance="outline"
            style="padding-top: 0 !important"
          >
            <mat-label>Parent Feedback</mat-label>
            <textarea
              matInput
              formControlName="feedback"
              rows="2"
              required
            ></textarea>
          </mat-form-field>
        </div>
      </div>
      <br />
      <div
        class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2"
        *ngIf="data['feedback'] == null"
      >
        <button
          mat-flat-button
          color="primary"
          (click)="formSubmit(sectionForm)"
          class="pull-right text-white"
          type="submit"
        >
          Submit
        </button>
        <button
          class="pull-right cancel-btn text-white mr-3 bg-light-red"
          type="button"
          (click)="modalClose()"
          mat-raised-button
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </form>
  </div>
</div>

<!-- ng-image-fullscreen-view  -->
<ng-image-fullscreen-view
  [images]="data['images']"
  [imageIndex]="currentIndex"
  [show]="showFlag"
  (close)="closeEventHandler()"
></ng-image-fullscreen-view>

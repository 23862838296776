import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sayMorning'
})
export class SaymorningPipe implements PipeTransform {

  transform(imgData: string, type: string): any {
    var sayText = '';
    const date = new Date();
    const day = date.getUTCDay();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const time = date.getHours();
    /* hour is before noon */
    if ( time < 12 )
    {
      sayText = "Good Morning";
    }
    else  /* Hour is from noon to 5pm (actually to 5:59 pm) */
    if ( time >= 12 && time <= 17 )
    {
      sayText = "Good Afternoon";
    }
    else  /* the hour is after 5pm, so it is between 6pm and midnight */
    if ( time > 17 && time <= 24 )
    {
      sayText = "Good Evening";
    }
    else  /* the hour is not between 0 and 24, so something is wrong */
    {
      sayText = "I'm not sure what time it is";
    }

    return sayText;
  }
}

<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <form class="login100-form validate-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <span class="login100-form-title p-b-45">
          Reset Password
        </span>
        <span class="error-subheader2 p-t-20 p-b-15">
          Enter your registered email address.
        </span>
        <div class="form-group mb-5">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username" required>
            <mat-icon matSuffix>face</mat-icon>
            <mat-error *ngIf="loginForm.get('username').hasError('required')">
              Username is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="container-login100-form-btn">
          <button mat-flat-button color="primary" class="login100-form-btn" type="submit">
            Reset My Password
          </button>
        </div>
        <div class="w-full p-t-25 text-center">
          <div>
            <a routerLink="/authentication/signin" class="txt1">
              Login?
            </a>
          </div>
        </div>
      </form>
      <div class="login100-more" style="background-image: url('assets/images/pages/bg-02.png');">
      </div>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { SharedComponentService } from "../../shared-component.service";
import { GlobalService } from "../../../global/global.service";
import { StaffAndEducatorService } from '../../../staff-and-educator/staff-and-educator.service';

@Component({
  selector: 'app-educator-p-leave-view',
  templateUrl: './educator-p-leave-view.component.html',
  styleUrls: ['./educator-p-leave-view.component.sass']
})
export class EducatorPLeaveViewComponent implements OnInit {

  @Input() rowRef: string;
  @Input() headerHide: number = 0;
  @Input() notificationRef: string = '';
  @Output() closeModal = new EventEmitter<boolean>();
  data: any = [];
  loggedUser: any = [];
  signatureImg = null;
  currentDate = null;
  isNewSignature = true;
  isEdit = false;
  sectionForm: FormGroup;
  submitted = false;
  error = '';
  sigError = false;
  allMembers: any = [];
  selectedChildren: any = [];
  children: any = [];
  signatureLength = 0;
  currentIndex: any = -1;
  htmlBody = null;

  constructor(
    private shardService: SharedComponentService,
    private fb: FormBuilder,
    private globeService: GlobalService,
    private staffService: StaffAndEducatorService,
  ) {
    this.loggedUser = JSON.parse(localStorage.getItem('user'));
    this.sectionForm = this.fb.group({
      ref: [''],
      table: [''],
      feedback: [''],
      parent_id: [''],
      child_id: [''],
      notification_ref: [''],
      date: [''],
      signature: [''],
      request_type: ['']
    });
  }

  ngOnInit(): void {
    this.sectionForm.patchValue({
      ref: this.rowRef,
      table: 'educator_permanent_leaves_form',
      notification_ref: this.notificationRef,
      request_type: 'own-table'
    });
    this.getNotification();
    if (this.loggedUser['role_id'] == 6) {
      let child = JSON.parse(localStorage.getItem('child'));
      this.sectionForm.patchValue({
        child_id: child['child_id']
      });
    }
  }

  getNotification() {
    this.htmlBody = null;
    var data = { educator_permanent_leaves_form_ref: this.rowRef, type: 2, };
    this.staffService.downloadEducatorPLeave(data).subscribe(res => {
      this.htmlBody = res['view_html'];
      this.signatureLength = res['signature_length'];
    }, error => {
      this.globeService.showErrorNotification(error.statusText);
    });
  }

  formSubmit(sectionForm: any) {

    this.sectionForm.patchValue({
      date: this.globeService.formatDatetoServerSide(this.sectionForm.value.date),
      signature: this.signatureImg,
    });

    this.sigError = false;
    if (this.signatureImg == null) {
      this.sigError = true;
      return false;
    }

    this.shardService.updateParentSignature(this.sectionForm.value).subscribe((res) => {
      this.globeService.showSuccessNotification('Successfully updated');
      this.modalClose();
    }, (error) => {
      this.error = error.error.error;
      this.globeService.showErrorNotification('Something went wrong!');
    }
    );
  }

  modalClose() {
    this.closeModal.emit(true);
  }

  removeSignature() { this.isNewSignature = true; }

  signatureImgOutput($event) { this.signatureImg = $event; }


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MaterialModule } from './material.module';
import {PermissionPipePipe} from "../core/pipe/permission-pipe.pipe";
import {PhoneNumberPipe} from "../core/pipe/phone-number.pipe";
import { FirstLetterPipe } from '../pipes/first-letter.pipe';
import {SignaturePadsComponent} from "./sections/signature-pads/signature-pads.component";
import {YearPickerComponent} from "./sections/year-picker/year-picker.component";
import {SignaturePadModule} from "angular2-signaturepad";
import {TruncateTextPipe} from "../core/pipe/truncate-text.pipe";
import {GetAgePipe} from "../core/pipe/get-age.pipe";
import {ExcelService} from "./excel.service";
import {SignaturePipe} from "../core/pipe/signature.pipe";
import {RoleRenamePipe} from "../core/pipe/role-rename.pipe";
@NgModule({
  declarations: [
    PermissionPipePipe,
    PhoneNumberPipe,
    FirstLetterPipe,
    SignaturePadsComponent,
    YearPickerComponent,
    TruncateTextPipe,
    GetAgePipe,
    SignaturePipe,
    RoleRenamePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    SignaturePadModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MaterialModule,
    PermissionPipePipe,
    PhoneNumberPipe,
    FirstLetterPipe,
    SignaturePadsComponent,
    YearPickerComponent,
    TruncateTextPipe,
    GetAgePipe,
    SignaturePipe,
    RoleRenamePipe
  ],
  providers:[ExcelService]
})
export class SharedModule {}


<div class="row p-15">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13">
    <ng-container *ngIf="headerHide != 1">
      <h3>Incident, Injury, Trauma, and Illness Record</h3>
    </ng-container>
    <span class="label l-bg-red shadow-style m-b-14" *ngIf="data.is_approval == 2">Declined - {{ data.parent_signature_date | date: 'longDate'}}</span>
    <span class="label l-bg-green shadow-style m-b-14" *ngIf="data.is_approval == 1">Approved - {{ data.parent_signature_date | date: 'longDate'}}</span>
    <span class="label l-bg-orange shadow-style m-b-14" *ngIf="data.is_approval == 0">Pending </span>
    <br/>
    <table  width="100%" class="w-100-p">
      <tr>
        <td width="43%"><strong>Service Name</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ data['center_name'] }}</td>
      </tr>
    </table>
    <br/>
    <h5 class="form-group-heading">Child Details</h5>
    <table  width="100%" class="w-100-p">
      <tr><td width="43%"><strong>Child Name</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ data['child_givenname'] }} {{ data['child_surname'] }}</td>
      </tr>
      <tr>
        <td width="43%"><strong>Date of birth</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ data['child_dob'] | date: 'longDate'}}</td>
      </tr>
      <tr>
        <td width="43%"><strong>Age</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ calculateAge(data['child_dob']) }}</td>
      </tr>
    </table>
    <br/>
    <h5 class="form-group-heading">Incident/injury/trauma/illness details </h5>
    <table  width="100%" class="w-100-p">
      <tr>
        <td width="43%"><strong>Type</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ data['type'] }}</td>
      </tr>
      <tr>
        <td width="43%"><strong>Location of the Service</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ data['location_service'] }}</td>
      </tr>
      <tr>
        <td width="43%"><strong>Location</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ data['location'] }}</td>
      </tr>
      <tr>
        <td width="43%"><strong>Date and Time</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ data['incident_date'] | date: 'medium' }}</td>
      </tr>
      <tr>
        <td width="43%"><strong>Name of witness</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ data['name_witness'] }}</td>
      </tr>
      <!-- <tr>
        <td width="43%"><strong>Signature</strong></td>
        <td width="2%"><strong></strong></td>
        <td width="55%"></td>
      </tr>
      <tr>
        <td width="43%"><img src="{{ data['signature'] }}"></td>
        <td width="2%"><strong></strong></td>
        <td width="55%"></td>
      </tr>
      <tr>
        <td width="43%"><strong>Date</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ data['signature_date'] | date: 'longDate'}}</td>
      </tr> -->
    </table>
    <br/>
    <table width="100%" class="w-100-p">
      <tr>
          <td width="43%" style="float: left"><strong>Signature : </strong><br/>
              <img src="{{ data['signature'] }}">
              <br><strong>Date : </strong>{{ data['signature_date'] | date: 'longDate'}}
          </td>
      </tr>
    </table>
  <!-- <p><strong>Type : </strong>{{ data['type'] }}</p> -->
    <!-- <p><strong>Circumstances leading to the incident/injury/trauma : </strong> <br/>{{ data['circumstances'] }}</p> -->
    <!-- <p><strong>Products or structures involved : </strong> <br/>{{ data['involved_description'] }}</p> -->
    <!-- <p><strong>Location of the Service : </strong>{{ data['location_service'] }}</p>
    <p><strong>Location : </strong>{{ data['location'] }}</p>
    <p><strong>Date and Time : </strong>{{ data['incident_date'] | date: 'medium'}}</p>
    <p><strong>Name of witness : </strong>{{ data['name_witness'] }}</p> -->
    <!-- <p><strong>Signature : </strong></p>
    <img src="{{ data['signature'] }}">
    <br/>
    <p><strong>Date : </strong>{{ data['signature_date'] | date: 'longDate'}}</p> -->
    <br>
    <br>
    <p><strong>Details : </strong><br/>{{ data['circumstances_details'] }}</p>

    <p><strong>Circumstances leading to the incident/ injury/trauma/illness, including any apparent symptoms : </strong><br/>{{ data['circumstances_symptoms'] }}</p>
    <p><strong>Circumstances if child appeared to be missing or otherwise unaccounted for (incl. duration, who found child, etc.) : </strong><br/>{{ data['circumstances_child_missing'] }}</p>
    <p><strong>Circumstances if child appeared to have been taken or removed from service or was locked in/out of service (incl. who took the child, duration) : </strong><br/>{{ data['circumstances_child_removed'] }}</p>

    <h5 class="form-group-heading">Nature of injury/trauma/illness : </h5>
    <div class="row">
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2">
        <img src="assets/images/forms/healthandsafetyform.JPG" class="full-width">
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
        <mat-icon class="f-11"  *ngIf="data['abrasion_scrape'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['abrasion_scrape'] == 0">close</mat-icon>
        Abrasion / scrape<br/>
        <mat-icon class="f-11"  *ngIf="data['allergic_reaction'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['allergic_reaction'] == 0">close</mat-icon>
        Allergic reaction (not anaphylaxis)<br/>
        <mat-icon class="f-11"  *ngIf="data['amputation'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['amputation'] == 0">close</mat-icon>
        Amputation<br/>
        <mat-icon class="f-11"  *ngIf="data['anaphylaxis'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['anaphylaxis'] == 0">close</mat-icon>
        Anaphylaxis<br/>
        <mat-icon class="f-11"  *ngIf="data['asthma_respiratory'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['asthma_respiratory'] == 0">close</mat-icon>
        Asthma / respiratory<br/>
        <mat-icon class="f-11"  *ngIf="data['bite'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['bite'] == 0">close</mat-icon>
        Bite wound<br/>
        <mat-icon class="f-11"  *ngIf="data['broken_bone'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['broken_bone'] == 0">close</mat-icon>
        Broken bone / fracture / dislocaton<br/>
        <mat-icon class="f-11"  *ngIf="data['bruise'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['bruise'] == 0">close</mat-icon>
        Bruise<br/>
        <mat-icon class="f-11"  *ngIf="data['burn'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['burn'] == 0">close</mat-icon>
        Burn / sunburn<br/>
        <mat-icon class="f-11"  *ngIf="data['choking'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['choking'] == 0">close</mat-icon>
        Choking<br/>
        <mat-icon class="f-11"  *ngIf="data['concussion'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['concussion'] == 0">close</mat-icon>
        Concussion<br/>
        <mat-icon class="f-11"  *ngIf="data['crush_jam'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['crush_jam'] == 0">close</mat-icon>
        Crush / jam<br/>
        <mat-icon class="f-11"  *ngIf="data['cut'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['cut'] == 0">close</mat-icon>
        Cut / open wound<br/>
        <mat-icon class="f-11"  *ngIf="data['drowning'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['drowning'] == 0">close</mat-icon>
        Drowning / (non-fatal)<br/>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 mb-2">
        <mat-icon class="f-11"  *ngIf="data['electric_shock'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['electric_shock'] == 0">close</mat-icon>
        Electric shock<br/>
        <mat-icon class="f-11"  *ngIf="data['eye_injury'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['eye_injury'] == 0">close</mat-icon>
        Eye injury<br/>
        <mat-icon class="f-11"  *ngIf="data['high_temperature'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['high_temperature'] == 0">close</mat-icon>
        High temperature<br/>
        <mat-icon class="f-11"  *ngIf="data['infectious_disease'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['infectious_disease'] == 0">close</mat-icon>
        Infectious disease (incl. gastrointestinal)<br/>        
        <mat-icon class="f-11"  *ngIf="data['ingestion_inhalation_insertion'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['ingestion_inhalation_insertion'] == 0">close</mat-icon>
        Ingestion / inhalation / insertion<br/>        
        <mat-icon class="f-11"  *ngIf="data['internal_injury'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['internal_injury'] == 0">close</mat-icon>
        Internal injury / infection<br/>        
        <mat-icon class="f-11"  *ngIf="data['poisoning'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['poisoning'] == 0">close</mat-icon>
        Poisoning<br/>
        <mat-icon class="f-11"  *ngIf="data['rash'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['rash'] == 0">close</mat-icon>
        Rash<br/>
        <mat-icon class="f-11"  *ngIf="data['respiratory'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['respiratory'] == 0">close</mat-icon>
        Respiratory<br/>
        <mat-icon class="f-11"  *ngIf="data['seizure_unconscious_convulsion'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['seizure_unconscious_convulsion'] == 0">close</mat-icon>
        Seizure / unconscious / convulsion<br/>
        <mat-icon class="f-11"  *ngIf="data['sprain'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['sprain'] == 0">close</mat-icon>
        Sprain / swelling<br/>
        <mat-icon class="f-11"  *ngIf="data['stabbing_piercing'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['stabbing_piercing'] == 0">close</mat-icon>
        Stabbing / piercing<br/>
        <mat-icon class="f-11"  *ngIf="data['tooth'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['tooth'] == 0">close</mat-icon>
        Tooth<br/>
        <mat-icon class="f-11"  *ngIf="data['venomous_bite_sting'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['venomous_bite_sting'] == 0">close</mat-icon>
        Venomous bite / sting<br/>
        <mat-icon class="f-11"  *ngIf="data['other'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['other'] == 0">close</mat-icon>
        Other (please specify)<br/>
        <p *ngIf="data['other'] == 1"><strong>Description : </strong> {{ data['other_description'] }}</p>
      </div>
    </div>

    <!-- <br/>
    <h5 class="form-group-heading"> Illness </h5>
    <p><strong>Circumstances surrounding child becoming ill, including apparent symptoms : </strong> <br/>{{ data['illness_symptoms'] }}</p>
    <p><strong>Date and Time : </strong>{{ data['illness_date'] | date: 'medium'}}</p> -->

    <br/>
    <h5 class="form-group-heading">Action Taken</h5>
    <p><strong>Details of action taken (including first aid, administration of medication, etc.) : </strong><br/>{{ data['action_taken'] }}</p>
    <!-- <p><strong>Medical personnel contacted : </strong>
      <span *ngIf="data['is_contact_medical_person'] == 1">Yes</span>
      <span *ngIf="data['is_contact_medical_person'] == 0">No</span>
    </p>
    <p *ngIf="data['is_contact_medical_person'] == 1"><strong>Medical Details : </strong> <br/>{{ data['medical_person_details'] }}</p> -->
    <p><strong>Did emergency services attend? : </strong>{{ data['emergency_service_attended'] }}</p>

    <p><strong>Time emergency services contacted : </strong>{{ data['time_emergency_service_contacted']}}</p>
    
    <p><strong>Time emergency services arrived : </strong>{{ data['time_emergency_service_arrived']}}</p>
    
    <p><strong>Was medical attention sought from a registered practitioner / hospital? : </strong>{{ data['sought_from_registered_hospital'] }}</p>

    <p><strong>If yes to either of the above, provide details : </strong><br/>{{ data['details_more'] }}</p>
    <p><strong>Have any steps been taken to prevent or minimise this type of incident in the future? If yes, provide details : </strong><br/>{{ data['detailed_steps_to_prevent_the_inceident']}}</p>
    
    <h5 class="form-group-heading">Notifications (including attempted notifications)</h5>
    <table  width="100%" class="w-100-p">
      <tr>
        <td width="43%"><strong>Parent/guardian/carer</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ data['notify_guardian'] }}</td>
      </tr>
      <tr>
        <td width="43%"><strong>Date and Time</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ data['notify_guardian_date'] | date: 'medium'}}</td>
      </tr>
      <tr>
        <td width="43%"><strong>Director/educator/ coordinator</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ data['notify_director'] }}</td>
      </tr>
      <tr>
        <td width="43%"><strong>Date and Time</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ data['notify_director_date'] | date: 'medium'}}</td>
      </tr>
      <tr>
        <td width="43%"><strong>Other agency (if applicable)</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ data['notify_other'] }}</td>
      </tr>
      <tr>
        <td width="43%"><strong>Date and Time</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ data['notify_other_date'] | date: 'medium'}}</td>
      </tr>
      <tr>
        <td width="43%"><strong>Regulatory authority (if applicable)</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ data['notify_authotiry'] }}</td>
      </tr>
      <tr>
        <td width="43%"><strong>Date and Time</strong></td>
        <td width="2%"><strong>:</strong></td>
        <td width="55%">{{ data['notify_authotiry_date'] | date: 'medium'}}</td>
      </tr>
    </table>

    <br/>
    <h5 class="form-group-heading">Details of person completing this record</h5>
    <p><strong>Name : </strong>{{ data['recorder_name'] }}</p>
    <p><strong>Position / role : </strong>{{ data['recorder_postition'] }}</p>
    <p><strong>Signature : </strong></p>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13">
      <img src="{{ data['recorder_signature'] }}">
      <br/>
    </div>
    <p><strong>Date and Time record made : </strong>{{ data['recorder_date'] | date: 'medium'}}</p>

    <br/>
    <ng-container *ngIf="loggedUser['role'] == 'parent'">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13 parent-area">
        <h5 class="form-group-heading">Parent Acknowledgement</h5>
        <p *ngIf="data['type'] == 'Incident'">I {{ data['sign_parent_name'] }} have been notified of my child's incident.</p>
        <p *ngIf="data['type'] == 'Injury'">I {{ data['sign_parent_name'] }} have been notified of my child's injury.</p>
        <p *ngIf="data['type'] == 'Trauma'">I {{ data['sign_parent_name'] }} have been notified of my child's trauma.</p>
        <p *ngIf="data['type'] == 'Illness'">I {{ data['sign_parent_name'] }} have been notified of my child's illness.</p>
        <br/>
        <ng-container *ngIf="data.is_approval == 0">
          <form class="register-form filter-form" [formGroup]="sectionForm" >
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
                <label class="f-12">Signature</label>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
                <div *ngIf="!isNewSignature">
                  <span *ngIf="isEdit"  (click)="removeSignature()" class="badge badge-danger remove-signature pointer b-r-15">x</span>
                  <img src="{{ signatureImg }}">
                </div>
                <ng-container *ngIf="isNewSignature">
                  <app-signature-pads (signaturePadEmit)="signatureImgOutput($event)"></app-signature-pads>
                </ng-container>
                <br/>
                <span class="text-danger"  *ngIf="sigError">Signature is required</span>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline" style="padding-top: 0 !important;">
                  <mat-label>Signature Date</mat-label>
                  <input matInput formControlName="signature_date" [matDatepicker]="picker">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <input type="hidden" formControlName="f1_incident_form_ref">
            <input type="hidden" formControlName="parent_id">
  
            <br/>
            <br/>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
              <button mat-flat-button color="primary"  (click)="formSubmit(sectionForm, 1)"  [class.spinner]="submitted" class="pull-right  text-white" type="submit">
                Approved
              </button>
              <button (click)="formSubmit(sectionForm, 2)"  class="pull-right cancel-btn text-white mr-3  bg-light-red" type="button" mat-raised-button data-dismiss="modal">Decline</button>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="data.is_approval != 0">
          <img src="{{ data['parent_signature'] }}">
          <br/>
          <em>{{ data.parent_signature_date | date: 'longDate'}}</em>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="loggedUser['role'] != 'parent' && data.is_approval != 0">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13 parent-area">
        <h5 class="form-group-heading">Parent Acknowledgement</h5>
        <p *ngIf="data['type'] == 'Incident'">I {{ data['sign_parent_name'] }} have been notified of my child's incident.</p>
        <p *ngIf="data['type'] == 'Injury'">I {{ data['sign_parent_name'] }} have been notified of my child's injury.</p>
        <p *ngIf="data['type'] == 'Trauma'">I {{ data['sign_parent_name'] }} have been notified of my child's trauma.</p>
        <p *ngIf="data['type'] == 'Illness'">I {{ data['sign_parent_name'] }} have been notified of my child's illness.</p>
        <br/>
        <ng-container *ngIf="data.is_approval != 0">
          <img src="{{ data['parent_signature'] }}">
          <br/>
          <em>{{ data.parent_signature_date | date: 'longDate'}}</em>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>

<div class="row p-15">
    <h4 class="text-black" *ngIf="headerHide != 1">Request for Change of Booking</h4>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13">
        <div class="m-t-15" [innerHTML]="htmlBody"></div>
    </div>

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13 parent-area"
        *ngIf="signatureLength < 10 || signature2Length < 10">
        <form class="register-form filter-form" [formGroup]="sectionForm">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2"
                    *ngIf="signature2Length < 10 && loggedUser['role_id'] != 6">
                    <h5 class="form-group-heading">Received by Signature</h5>
                    <mat-form-field class="example-full-width" appearance="outline" style="padding-top: 0 !important;">
                        <mat-label>Signature Date</mat-label>
                        <input matInput formControlName="received_sign_date" [matDatepicker]="picker2"
                            (click)="picker2.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>

                    <label class="f-12 m-t-15">Signature</label>
                    <div *ngIf="!isNewSignature2">
                        <span *ngIf="isEdit" (click)="removeSignature()"
                            class="badge badge-danger remove-signature pointer b-r-15">x</span>
                        <img src="{{ signatureImg2 }}">
                    </div>
                    <ng-container *ngIf="isNewSignature2">
                        <app-signature-pads (signaturePadEmit)="signatureImgOutput2($event)"></app-signature-pads>
                    </ng-container>
                    <br />
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2" *ngIf="signatureLength < 10">
                    <h5 class="form-group-heading">Your Signature</h5>
                    <mat-form-field class="example-full-width" appearance="outline" style="padding-top: 0 !important;">
                        <mat-label>Signature Date</mat-label>
                        <input matInput formControlName="date" [matDatepicker]="picker" (click)="picker.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <label class="f-12 m-t-15">Signature</label>
                    <div *ngIf="!isNewSignature">
                        <span *ngIf="isEdit" (click)="removeSignature()"
                            class="badge badge-danger remove-signature pointer b-r-15">x</span>
                        <img src="{{ signatureImg }}">
                    </div>
                    <ng-container *ngIf="isNewSignature">
                        <app-signature-pads (signaturePadEmit)="signatureImgOutput($event)"></app-signature-pads>
                    </ng-container>
                    <br />
                    <span class="text-danger" *ngIf="sigError">Signature is required</span>

                </div>
            </div>
            <br />
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 p-r-0">
                <button mat-flat-button color="primary" (click)="formSubmit(sectionForm)" class="pull-right  text-white"
                    type="submit">
                    Submit
                </button>
                <button class="pull-right cancel-btn text-white mr-3  bg-light-red" type="button" (click)="modalClose()"
                    mat-raised-button data-dismiss="modal">Close</button>
            </div>
        </form>
    </div>
</div>

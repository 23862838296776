import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectionList } from '@angular/material/list';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'src/app/global/global.service';
import { StaffAndEducatorService } from 'src/app/staff-and-educator/staff-and-educator.service';

@Component({
  selector: 'app-children-msl',
  templateUrl: './children-msl.component.html',
  styleUrls: ['./children-msl.component.sass']
})
export class ChildrenMslComponent implements OnInit {
  @Input() educator_id: number;
  @Input() child_ids: number[] = [];
  childrenForm: FormGroup;
  selectedChildren: any = [];
  allMembers: any = [];
  cacheChildren: any = [];
  isCheckedAll: boolean = false;
  childIDSList: number[] = [];
  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private globeService: GlobalService,
    private staffEducatorService: StaffAndEducatorService
  ) {
    console.log('a1');

    this.childrenForm = this.fb.group({
      child_ids: [''],
    });

    console.log('a2');
   }

  ngOnInit(): void {
    console.log('b1');
    console.log('ChildrenMslComponent');
    console.log(this.educator_id);
    console.log(this.child_ids);
    if(this.child_ids)
      this.childIDSList = this.child_ids;

    this.getChildrenByEducatorId();

    console.log('b2');
  }
  getChildrenByEducatorId()
  {
    let data = {
      'educator_id': this.educator_id,
      'module': 'AARAFTAE'
    };
    this.staffEducatorService.getChildrenByEducatorId(data).subscribe((res) => {
      this.allMembers['children'] = res['children'];
      this.cacheChildren = [...this.allMembers['children']];
      this.childrenForm.patchValue({
        child_ids: this.child_ids
      });
      this.updateChildrenSelectAllStatus();
    }, (error) => {
      let err = error.error.error;
      this.globeService.showErrorNotification(err);
    }
    );
  }
  filterChild(event: KeyboardEvent, matSelectionList: MatSelectionList) {
    this.allMembers['children'] = this.cacheChildren;
    if(event)
    {
      let val = (event.target as HTMLInputElement).value.toLowerCase();

      if (val.length > 0) {
        const temp = this.allMembers['children'].filter(function (d) {
          return (
            d.child_givenname.toLowerCase().indexOf(val) !== -1 ||
            !val ||
            d.child_surname.toLowerCase().indexOf(val) !== -1
          );
        });
        this.allMembers['children'] = temp;
      }
    }
    this.childrenForm.patchValue({
      child_ids: this.childIDSList
    });
    if(event)
      this.updateChildrenSelectAllStatus(matSelectionList);
    else
      this.updateChildrenSelectAllStatus();
  }
  updateChildrenSelectAllStatus(selection_list: MatSelectionList = null)
  {
    console.log(selection_list);
    console.log(this.childIDSList);
    console.log(this.allMembers['children']);
    if(selection_list)
    {
      setTimeout(() => {
        console.log(selection_list.options.length);
        this.checkAllOptions(selection_list);
      }, 500); 
    }
    else
    {
      if(this.childIDSList.length == this.allMembers['children'].length)
        this.isCheckedAll = true;
      else
        this.isCheckedAll = false;
    }
  }
  checkAllOptions(selection_list: MatSelectionList) {
    let allChecked = true;
    selection_list.options.forEach(option => {
      if (!option.selected) {
        allChecked = false;
        return;
      }
    });
    if (allChecked) 
      this.isCheckedAll = true;
    else 
      this.isCheckedAll = false;
  }
  childrenFormSubmit(modelClose = false) {
    this.filterChild(null,null);
    this.selectedChildren = [];
 
    this.childIDSList = Array.from(new Set( this.childIDSList));
    this.childIDSList.forEach((obj, key) => {
      this.selectedChildren.push(
        this.allMembers['children'].filter((element) => {
          return element.child_id == obj;
        })[0]
      );
    });
    //removing unassign child
    this.selectedChildren = this.selectedChildren.filter(function (element) {
      return element !== undefined;
    });
    let child_count = this.selectedChildren.length;
    let selectedChildrenIds = [];

    this.selectedChildren.forEach((obj) => {
      selectedChildrenIds.push(obj.child_id);
    });

    this.modal.close({selectedChildren: this.selectedChildren, selectedChildrenIds: selectedChildrenIds, child_count: child_count });
  }
  cancelMatSel()
  {
    this.modal.dismiss();
    this.childIDSList = [];
    this.selectedChildren.forEach((obj) => {
      this.childIDSList.push(obj.child_id);
    });
  }
  selectAllAction($event: MatCheckboxChange, msl: MatSelectionList) {
    if ($event.checked) {
      msl.selectAll();
      this.allMembers['children'].forEach((item) => {
        this.childIDSList.push(item.child_id);
      });
      this.isCheckedAll = true;
    } else {
      msl.deselectAll();
      this.childIDSList = [];
      this.isCheckedAll = false;
    }
  }
  findNumberIndex(numberToFind: number): number {
    return this.childIDSList.indexOf(numberToFind);
  }
  MSLSelectionChange(option:any, matSelectionList: MatSelectionList)
  {
    let indexOfNumber: number;
    indexOfNumber = this.findNumberIndex(option.value);
    if(option.selected && indexOfNumber == -1)
      this.childIDSList.push(option.value);
    else
      this.childIDSList.splice(indexOfNumber, 1);
    this.updateChildrenSelectAllStatus(matSelectionList);
  }
  
}


<div class="row p-15">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13">
    <h3 *ngIf="headerHide != 1">Relief Educator Notification and Permission</h3>
    <h6>{{ data.created_at | date: 'longDate' }}</h6>
    <span class="label l-bg-red shadow-style m-b-14" *ngIf="data.is_approval == 2">Declined - {{ data.parent_signature_date | date: 'longDate'}}</span>
    <span class="label l-bg-green shadow-style m-b-14" *ngIf="data.is_approval == 1">Approved - {{ data.parent_signature_date | date: 'longDate'}}</span>
    <br/>
    <p>Dear <strong>{{ data['parent_name'] }}, </strong></p>
    <p><strong>RE: Relief Educator Notification for Parent/Guardian Approval</strong></p>
    <p>This is to notify you that {{ data['educator_name'] }} will not be available today, so an approved Relief Educator has been assigned to your child.</p>
    <p><strong>Date: </strong>{{ data['on_date'] | date: 'longDate' }} - {{ data['to_date'] | date: 'longDate' }} &nbsp; <br/> <strong>Time: </strong>{{ data['on_time'] }} - {{ data['to_time'] }} </p>

    <p>I, {{ data['educator_name'] }}, will not be available for the following reason : <br/> <strong>{{ data['description'] }}</strong> </p>

    <p>The Relief Educator is approved to provide care either in emergencies or for long periods of time, to the children who are primarily in the care of (educator name). This care is covered under the Educators insurance.</p>

    <p>The Relief Educator is registered with {{ loggedUser['user']['center_name'] }} and successfully met the following criteria:</p>
    <ul>
      <li>- Has obtained a current National Criminal History check</li>
      <li>- Holds a current Working with Children</li>
      <li>- Has a current Apply First Aid Certificate</li>
      <li>- Has undertaken Anaphylaxis, Asthma and Fire Safety training.</li>
      <li>- Attended an interview with Co-ordination Unit</li>
      <li>- Signed both the Confidentiality Agreement and theFDCEducator Contract</li>
      <li>- Has provided copy of Motor Vehicle License and Child Restraint/Fitting Inspection of the vehicle being used by theRelief Educator.</li>
    </ul>
    <p>It is important that you are aware of all details regarding the care being provided to your child/ren.</p>
    <p>Please take the time to complete the permission note below and return it to either your Educator or direct to the Co-ordination Unit.</p>
    <p>Should you have any questions or concerns regarding this issue, please do not hesitate to contact the educator or the Co-ordination Unit.</p>
    <p>Your sincerely,</p>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-2 f-13">
    <img src="{{ data['educator_signature'] }}">
    <br/>
    <em>{{ data['educator_name'] }} (Educator)</em>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-2 f-13" *ngIf="data.is_approval != 0">
    <img src="{{ data['parent_signature'] }}">
    <br/>
    <em>{{ data['sign_parent_name'] }} (Parent) -  {{ data.parent_signature_date | date: 'longDate'}}</em>
  </div>


  <ng-container *ngIf="data.is_approval == 0">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13 parent-area">
      <h5 class="form-group-heading">Parent Details For Approval</h5>
      <form class="register-form filter-form" [formGroup]="sectionForm" >
        <div class="row">
          <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 mb-2">

            <mat-form-field class="example-full-width" appearance="outline" style="padding-top: 0 !important;">
              <mat-label>Signature Date</mat-label>
              <input matInput formControlName="signature_date" [matDatepicker]="picker">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <label class="f-12">Signature of Parent</label>
            <div *ngIf="!isNewSignature">
              <span *ngIf="isEdit"  (click)="removeSignature()" class="badge badge-danger remove-signature pointer b-r-15">x</span>
              <img src="{{ signatureImg }}">
            </div>
            <ng-container *ngIf="isNewSignature">
              <app-signature-pads (signaturePadEmit)="signatureImgOutput($event)"></app-signature-pads>
            </ng-container>
            <br/>
            <span class="text-danger"  *ngIf="sigError">Signature is required</span>
          </div>
        </div>
        <input type="hidden" formControlName="relief_educator_ref">
        <input type="hidden" formControlName="parent_id">

        <br/>
        <br/>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <button mat-flat-button color="primary"  (click)="formSubmit(sectionForm, 1)"  [class.spinner]="submitted" class="pull-right  text-white" type="submit">
            Approved
          </button>
          <button (click)="formSubmit(sectionForm, 2)"  class="pull-right cancel-btn text-white mr-3  bg-light-red" type="button" mat-raised-button data-dismiss="modal">Decline</button>
        </div>
      </form>
    </div>
  </ng-container>
</div>

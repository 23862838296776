import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { GlobalService } from "../global/global.service";
@Injectable({
  providedIn: 'root',
})
export class HealthandsafetyFormService {

  constructor(private http: HttpClient, private globeService: GlobalService) { }

  createF1IncidentForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-incident', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getF1IncidentList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-incident-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteF1IncidentForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-incident?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getChildrenList() {
    return this.http.get(this.globeService.baseUrl + 'account/get-children-list', this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  uploadInjuryDocument(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/upload-incident-form', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  createDailyReflectionForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-daily-reflection', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getDailyReflectionList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-daily-reflection-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteDailyReflectionForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-daily-reflection?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }


  getViewForF1IncidentInjuryForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-f1-incident-injury-form', data, this.globeService.getHttpOptionsAuth());
  }

  downloadF1IncidentInjuryForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-f1-incident-injury-form', data, this.globeService.getHttpOptionsBlob());
  }

  downloadF1IncidentInjuryFormCvs(filters) {
    return this.http.post(this.globeService.baseUrl + 'reports/f1-incident-injury-form-cvs', filters, this.globeService.getHttpOptionsAuth());
  }

  createChildAsthmaForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-child-asthma', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getChildAsthmaList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-child-asthma-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }
  deleteChildAsthmaForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-child-asthma?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  createCleaningMaintenceForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-cleaning-maintenance', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getCleaningMaintenceList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-cleaning-maintenance-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteCleaningMaintenceForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-cleaning-maintenance?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getEvacuationList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-evacuation-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  createEvacuationForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-evacuation-emergencies', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  deleteEvacuationForm(formRef: any) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-evacuation-emergencies?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getMedicationList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-medication-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  createMedicationForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-medication-form', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  deleteMedicationForm(formRef: any) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-medication?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getViewForCleaningMaintenanceAndProfessionalDevelopmentChecklist(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-cleaning-maintenance-and-professional-development-check-list', data, this.globeService.getHttpOptionsAuth());
  }

  downloadCleaningMaintenanceAndProfessionalDevelopmentChecklist(data) {
    // return this.http.post(this.globeService.baseUrl + 'forms/download-cleaning-maintenance-and-professional-development-check-list', data, this.globeService.getHttpOptionsBlob());
    return this.http.post(this.globeService.baseUrl + 'forms/download-cleaning-maintenance-and-professional-development-check-list', data, this.globeService.getHttpOptionsAuth());
  }


  createEmergencyEvacuationForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-emergency-evacuation', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getEmergencyEvacuationList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-emergency-evacuation-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteEmergencyEvacuationForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-emergency-evacuation?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  createAuthorisationTransportForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-authorisation-transport', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  duplicateAuthorisationTransportForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/duplicate-authorisation-transport', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getAuthorisationTransportList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-authorisation-transport-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteAuthorisationTransportForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-authorisation-transport?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }


  // Food Sleep Nappy Change
  createNappyChangeForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-nappy-change-form', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getNappyChangeForm(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-nappy-change-form?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteNappyChangeForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-nappy-change-form?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  viewNappyChangeForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-nappy-change-form', data, this.globeService.getHttpOptionsAuth());
  }

  downloadNappyChangeForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-nappy-change-form', data, this.globeService.getHttpOptionsBlob());
  }

  downChildAsthmaManagementPlan(data: { child_asthma_form_ref: any; type: number }) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-child-asthma-management-plan', data, this.globeService.getHttpOptionsBlob());
  }

  downChildAsthmaManagementPlanCSV(filters) {
    return this.http.post(this.globeService.baseUrl + 'reports/child-asthma-mgt-plan-csv', filters, this.globeService.getHttpOptionsAuth());
  }

  getViewForEvacuationDrillReport(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-evacuation-drill-report', data, this.globeService.getHttpOptionsAuth());
  }

  downloadEvacuationDrillReport(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-evacuation-drill-report', data, this.globeService.getHttpOptionsAuth());
  }

  downloadEvacuationDrillCSV(data) {
    return this.http.post(this.globeService.baseUrl + 'reports/evacuation-drill-report-csv', data, this.globeService.getHttpOptionsAuth());
  }

  downloadMedicationForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-medication-form', data, this.globeService.getHttpOptionsAuth());
  }

  viewMedicationForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-medication-form', data, this.globeService.getHttpOptionsAuth());
  }

  downloadMedicationFormCSV(filters) {
    return this.http.post(this.globeService.baseUrl + 'reports/medication-consent-csv', filters, this.globeService.getHttpOptionsAuth());
  }

  createToyCleaningForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-toy-cleaning', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getToyCleaningList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-toy-cleaning-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteToyCleaningForm(formRef: any) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-toy-cleaning?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  createRiskMinimistationForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-risk-minimisation', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getRiskMinimistationList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-risk-minimisation-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteRiskMinimistationForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-risk-minimisation?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  downloadToyCleaningAndMaintenance(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-toy-cleaning-and-maintenance', data, this.globeService.getHttpOptionsAuth());
  }

  downloadToyCleaningCSV(filters) {
    return this.http.post(this.globeService.baseUrl + 'reports/toy-cleaning-csv', filters, this.globeService.getHttpOptionsAuth());
  }

  downloadRiskMinimisationCSV(filters) {
    return this.http.post(this.globeService.baseUrl + 'reports/risk-minimisation-csv', filters, this.globeService.getHttpOptionsAuth());
  }

  downloadEvacuationAndEmergenciesRiskManagementPlan(data: { evacuation_ref: string[] | any; type: number }) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-evacuation-and-emergencies-risk-management-plan', data, this.globeService.getHttpOptionsAuth());
  }

  downloadEvacuationAndEmergenciesCSV(filters) {
    return this.http.post(this.globeService.baseUrl + 'reports/evacuation-and-emergencies-csv', filters, this.globeService.getHttpOptionsAuth());
  }

  downloadAuthorisationTransportAndExcursionRiskManagementPlan(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-authorisation-transport-and-excursion-risk-management-plan', data, this.globeService.getHttpOptionsAuth());
  }

  downloadAuthorisationCSV(filters) {
    return this.http.post(this.globeService.baseUrl + 'reports/authorisation-csv', filters, this.globeService.getHttpOptionsAuth());
  }

  getParentAndEducatorsByChilId(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-parent-and-educators-by-child-id', data, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  sendIncidentNotifications(sendForm: any) {
    return this.http.post(this.globeService.baseUrl + 'forms/send-incident-notification', sendForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getSingleFormByRef(refId: string, table: string) {
    return this.http.get(this.globeService.baseUrl + 'forms/get-nappy-change-form-single?ref=' + refId + '&table=' + table, this.globeService.getHttpOptionsAuth());
  }

  // nofityToParent(ref: any) {
  //   return this.http.get(this.globeService.baseUrl + 'forms/notify-authorisation-notification?ref=' + ref, this.globeService.getHttpOptionsAuth());
  // }

  downloadNappyChangeFormCSV(filters: any) {
    return this.http.post(this.globeService.baseUrl + 'reports/nappy-change-csv', filters, this.globeService.getHttpOptionsAuth());
  }

  checkZohoStatus(data) {
    return this.http.post(this.globeService.baseUrl + 'zoho/check-zoho-status', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsFoodSleepNappy(data: { ids: any }) {
    return this.http.post(this.globeService.baseUrl + 'notify/food-sleep-nappy', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsAuthorisationRMP(data: { ids: any }) {
    return this.http.post(this.globeService.baseUrl + 'notify/authorisation-rmp', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsEmergencyEvacuation(data: { ids: any }) {
    return this.http.post(this.globeService.baseUrl + 'notify/emergency-evacuation', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsMedication(data: { ids: any }) {
    return this.http.post(this.globeService.baseUrl + 'notify/medication', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsChildAsthma(data: { ids: any }) {
    return this.http.post(this.globeService.baseUrl + 'notify/child-asthma-mgt', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsRiskMinimization(data: { ids: any }) {
    return this.http.post(this.globeService.baseUrl + 'notify/risk-minimization', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsEvacuationRiskMGT(data: { ids: any }) {
    return this.http.post(this.globeService.baseUrl + 'notify/evacuation-risk-mgt', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsCleaningMaintance(data: { ids: any }) {
    return this.http.post(this.globeService.baseUrl + 'notify/cleaning-maintenance', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsToyCleaning(data: { ids: any }) {
    return this.http.post(this.globeService.baseUrl + 'notify/toy-leaning', data, this.globeService.getHttpOptionsAuth());
  }

}

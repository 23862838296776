import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { GlobalService } from "../global/global.service";
@Injectable({
  providedIn: 'root',
})

export class SharedComponentService {

  constructor(private http: HttpClient, private globeService: GlobalService) { }

  getRelief(ref) {
    return this.http.get(this.globeService.baseUrl + 'forms/get-relief-notification?ref=' + ref, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getIncidentForm(ref) {
    return this.http.get(this.globeService.baseUrl + 'forms/get-incident-notification?ref=' + ref, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  // updateReliefNotification(xForm: FormData) {
  //   return this.http.post(this.globeService.baseUrl + 'forms/update-relief-notification', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  // }

  updateReliefNotification(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/update-parent-signature', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  updateIncidentNotification(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/update-incident-notification', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getAuthorisation(rowRef) {
    let loggedUser = JSON.parse(localStorage.getItem('user'));
    if (loggedUser['role_id'] == 6) {
      let child = JSON.parse(localStorage.getItem('child'));
      return this.http.get(this.globeService.baseUrl + 'forms/get-authorisation-view?ref=' + rowRef + '&child_id=' + child['child_id'], this.globeService.getHttpOptionsAuth());
    } else {
      return this.http.get(this.globeService.baseUrl + 'forms/get-authorisation-view?ref=' + rowRef + '&child_id=all', this.globeService.getHttpOptionsAuth());
    }
  }

  getGrievanceForm(ref) {
    return this.http.get(this.globeService.baseUrl + 'forms/get-grievance-notification?ref=' + ref, this.globeService.getHttpOptionsAuthWithOutContentType());
  }
  updateGrievanceNotification(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/update-grievance-notification', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getMonthlyObservationView(rowRef: string) {
    let loggedUser = JSON.parse(localStorage.getItem('user'));
    if (loggedUser['role_id'] == 6) {
      let child = JSON.parse(localStorage.getItem('child'));
      return this.http.get(this.globeService.baseUrl + 'forms/get-monthly-observation-view?ref=' + rowRef + '&child_id=' + child['child_id'], this.globeService.getHttpOptionsAuth());
    } else {
      return this.http.get(this.globeService.baseUrl + 'forms/get-monthly-observation-view?ref=' + rowRef + '&child_id=all', this.globeService.getHttpOptionsAuth());
    }
  }

  getDailyReflectionView(rowRef: string) {
    let loggedUser = JSON.parse(localStorage.getItem('user'));
    if (loggedUser['role_id'] == 6) {
      let child = JSON.parse(localStorage.getItem('child'));
      return this.http.get(this.globeService.baseUrl + 'forms/get-daily-reflection-view?ref=' + rowRef + '&child_id=' + child['child_id'], this.globeService.getHttpOptionsAuth());
    } else {
      return this.http.get(this.globeService.baseUrl + 'forms/get-daily-reflection-view?ref=' + rowRef + '&child_id=all', this.globeService.getHttpOptionsAuth());
    }
  }

  getWeeklyProgramView(rowRef: string) {
    let loggedUser = JSON.parse(localStorage.getItem('user'));
    if (loggedUser['role_id'] == 6) {
      let child = JSON.parse(localStorage.getItem('child'));
      return this.http.get(this.globeService.baseUrl + 'forms/get-weekly-program-view?ref=' + rowRef + '&child_id=' + child['child_id'], this.globeService.getHttpOptionsAuth());
    } else {
      return this.http.get(this.globeService.baseUrl + 'forms/get-weekly-program-view?ref=' + rowRef + '&child_id=all', this.globeService.getHttpOptionsAuth());
    }
  }

  viewNappyChangeForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-nappy-change-form', data, this.globeService.getHttpOptionsAuth());
  }


  viewChildAsthmaManagementPlan(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-child-asthma-management-plan', data, this.globeService.getHttpOptionsAuth());
  }

  viewRiskMinimisationAndCommunicationPlan(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-risk-minimisation-and-communication-plan', data, this.globeService.getHttpOptionsAuth());
  }

  getEducatatorBackGroundScreeningFormData(educatorRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/get-educator-background-screening?ref=' + educatorRef, this.globeService.getHttpOptionsAuth());
  }

  getAssessmentRegistration(educatorRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/get-assessment-registration?ref=' + educatorRef, this.globeService.getHttpOptionsAuth());
  }

  updateParentSignature(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/update-parent-signature', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }


  getEducatorRegistrationForm(ref) {
    return this.http.get(this.globeService.baseUrl + 'account/get-educator-registration-notification?ref=' + ref, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getStaffBackGroundScreeningFormData(staffRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/get-staff-background-screening?ref=' + staffRef, this.globeService.getHttpOptionsAuth());
  }


  getAssessmentRegistrationStaff(educatorRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/get-assessment-registration-staff?ref=' + educatorRef, this.globeService.getHttpOptionsAuth());
  }

  getStaffRegistrationForm(ref) {
    return this.http.get(this.globeService.baseUrl + 'account/get-staff-registration-notification?ref=' + ref, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

}

<!-- my-loader.component.html -->
<!--<div *ngIf="loading"  style="z-index: 9999;position: relative;">-->
  <ngx-spinner [name]="'http-loading'"  bdColor="rgba(63,69,95, 0.8)" size="medium" color="#fff" type="ball-scale-pulse" [fullScreen]="true"></ngx-spinner>
<!--    <div class="loading-spinner">-->

<!--        <img src="assets/images/spinner.gif" width="60">-->
<!--        <span class="loading-message">Please wait...</span>-->
<!--    </div>-->
<!--</div>-->


<!--<ngx-spinner bdColor="rgb(63,69,95)" [name]="'http-loading'" size="medium" color="#0af0d5" type="ball-scale-pulse" [fullScreen]="true">-->
<!--  <p style="color: white"> </p>-->
<!--</ngx-spinner>-->

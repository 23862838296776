import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { GlobalService } from "../global/global.service";
@Injectable({
  providedIn: 'root',
})
export class FamiliesCommunitiesFormService {

  constructor(private http: HttpClient, private globeService: GlobalService) { }

  createDailyReflectionForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-daily-reflection', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getDailyReflectionList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-daily-reflection-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteDailyReflectionForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-daily-reflection?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  createLeaveOfAbsenceForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-leave_of_absence', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  deleteLeaveOfAbsence(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-leave-of-absence?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getLeavesOfAbsenceList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-leave-of-absence-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  getLeaveCategories() {
    return this.http.get(
      this.globeService.baseUrl + "forms/get-leave-categories",
      this.globeService.getHttpOptionsAuth()
    );
  }

  getChildEducator(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-child-educator', data, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  createChangeBooking(xForm) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-change-booking', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getChangeBookingList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-change-booking-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteChangeBookingForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-change-booking?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());

  }

  createFamilyInputSheetForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-family-input-sheet', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getFamilyInputSheetList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-family-input-sheet-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteFamilyInputSheetForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-family-input-sheet?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  createInclusionSupportForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-inclusion-support', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getInclusionSupportList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-inclusion-support-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  getTerminationCareList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-termination-care?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  createTerminationCareForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/termination-care', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  deleteTerminationCareForm(formRef: any) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-termination_of-care?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  deleteInclusionSupportForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-inclusion-support?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getViewForFamilyInputSheet(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-family-input-sheet-form', data, this.globeService.getHttpOptionsAuth());
  }

  downloadFamilyInputSheet(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-family-input-sheet-form', data, this.globeService.getHttpOptionsBlob());
  }

  downloadFamilyInputSheetCSV(data) {
    return this.http.post(this.globeService.baseUrl + 'reports/family-input-csv', data, this.globeService.getHttpOptionsBlob());
  }

  getViewForInclusionSupportPlanForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-inclusion-support-plan-form', data, this.globeService.getHttpOptionsAuth());
  }

  downloadInclusionSupportForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-inclusion-support-plan-form', data, this.globeService.getHttpOptionsBlob());
  }

  downloadInclusionSupportCSV(data) {
    return this.http.post(this.globeService.baseUrl + 'reports/inclusion-support-plan-csv', data, this.globeService.getHttpOptionsBlob());
  }

  downloadF7ChangeOfBooking(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-f7-change-of-booking', data, this.globeService.getHttpOptionsBlob());
  }

  getViewForF7ChangeOfBooking(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-f7-change-of-booking', data, this.globeService.getHttpOptionsAuth());
  }

  getViewForTerminationOfCareForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-termination-of-care-form', data, this.globeService.getHttpOptionsAuth());
  }

  downloadTerminationOfCareForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-termination-of-care-form', data, this.globeService.getHttpOptionsBlob());
  }

  downloadTerminationOfCareCSV(data) {
    return this.http.post(this.globeService.baseUrl + 'reports/termination-of-care-csv', data, this.globeService.getHttpOptionsBlob());
  }

  viewForLeaveOfAbsenceRequestForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download_leave_of_absence_request_form', data, this.globeService.getHttpOptionsAuth());
  }

  downloadLeaveOfAbsenceRequestForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download_leave_of_absence_request_form', data, this.globeService.getHttpOptionsBlob());
  }

  downloadLeaveOfAbsenceCSV(data) {
    return this.http.post(this.globeService.baseUrl + 'reports/leave-of-absence-csv', data, this.globeService.getHttpOptionsBlob());
  }

  createVacationCareForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-vacation-care', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  deleteVacationCareForm(formRef: any) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-vacation-care?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getVacationCareList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-vacation-care?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  getStaffMembers() {
    return this.http.get(this.globeService.baseUrl + 'forms/get-staff-members', this.globeService.getHttpOptionsAuth());
  }

  downloadVacationCare(data) {

    return this.http.post(this.globeService.baseUrl + 'forms/download-vacation-care-form', data, this.globeService.getHttpOptionsBlob());
  }

  downloadVacationCareCSV(data) {
    return this.http.post(this.globeService.baseUrl + 'reports/vacation-care-csv', data, this.globeService.getHttpOptionsBlob());
  }

  notificationsChildAbsence(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/child-absence', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsChildTermination(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/child-termination', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsFamilyInputSheet(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/family-input-sheet', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsInclusionSupport(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/inclusion-support', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsF7ChangeOfBooking(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/f7-change-booking', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsVacationCare(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/book-vacation-care', data, this.globeService.getHttpOptionsAuth());
  }

}

<div class="limiter bg-primary-green">
  <div class="container-login100 bg-primary-green">
    <div class="wrap-login100">
      <form class="login100-form validate-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="login100-form-logo">
          <div class="image" style="width: 100px; height: 100px;">
            <img *ngIf="user.profile_image !== null" class="rounded-circle" style="width: 100%; height: 100%; object-fit: cover"  alt="User" src="{{ user.profile_image }}" />
            <img *ngIf="user.profile_image === null" class="rounded-circle" style="width: 100%; height: 100%; object-fit: cover"  alt="User" src="assets/images/users.jpg" />
          </div>
        </div>
        <span class="login100-form-title p-b-34 p-t-27">
          {{ user['first_name'] }} {{ user['last_name'] }}
        </span>
        <div class="text-center">
          <p class="txt1 p-b-20">
            Locked
          </p>
        </div>
        <div class="form-group mb-3">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required>
            <mat-icon matSuffix (click)="hide = !hide">
              {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="loginForm.get('password').hasError('required')">
              Password is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="container-login100-form-btn p-t-30">
          <button mat-flat-button color="primary" [disabled]="submitted || !loginForm.valid" [class.not-allowed]="submitted" [class.spinner]="submitted" class="login100-form-btn">
            Login
          </button>
        </div>
        <div class="w-full p-t-15 p-b-15 text-center">
          <div>
            <a routerLink="/login" class="txt1">
              Login Page
            </a>
          </div>
        </div>
      </form>
      <div class="login100-more" style="background-image: url('assets/images/banner/locked.png');">
      </div>
    </div>
  </div>
</div>

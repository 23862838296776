import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { GlobalService } from "../global/global.service";

@Injectable({
    providedIn: 'root'
})
export class ChildEnrollmentService {


    constructor(
        private http: HttpClient,
        private globalService: GlobalService,
    ) { }

    SendEmail(data) {
        return this.http.post(this.globalService.baseUrl + "child-enrollment-auth/send-email-parent", data, this.globalService.getHttpOptionsAuth());
    }

    getEnrollmentList(data, pageSize, page) {
        return this.http.post(this.globalService.baseUrl + "child-enrollment-auth/get-enrollment-form-list?pageSize=" + pageSize + "&page=" + page, data, this.globalService.getHttpOptionsAuth());
    }

    getEnrollmentFormData(enrollmentFormRef) {
        return this.http.get(this.globalService.baseUrl + "child-enrollment-auth/get-enrollment-form-data?enrollment_form_ref=" + enrollmentFormRef, this.globalService.getHttpOptionsAuth());
    }

    ApproveRejectEnrollment(data) {
        return this.http.post(this.globalService.baseUrl + "child-enrollment-auth/change-enrollment-form-status", data, this.globalService.getHttpOptionsAuth());

    }
    DeactivateChild(data) {
        return this.http.post(this.globalService.baseUrl + "child-enrollment-auth/deactivate-child", data, this.globalService.getHttpOptionsAuth());
    }
    validateEnrollmentChild(data) {
        return this.http.post(this.globalService.baseUrl + "child-enrollment-auth/enrollment-validate-child", data, this.globalService.getHttpOptionsAuth());
    }


    SaveChildParentData(data) {
        return this.http.post(this.globalService.baseUrl + "child-enrollment-auth/enrollment-save-child-parent", data, this.globalService.getHttpOptionsAuth());
    }



    getInductionsList(data, pageSize, page) {
        return this.http.post(this.globalService.baseUrl + "child-enrollment-inductions/get-inductions-form-list?page_size=" + pageSize + "&page=" + page, data, this.globalService.getHttpOptionsAuth());

    }

    SubmitInduction(data) {
        return this.http.post(this.globalService.baseUrl + "child-enrollment-inductions/induction-form-submit", data, this.globalService.getHttpOptionsAuth());
    }

    SaveParentChildDataInduction(data) {
        return this.http.post(this.globalService.baseUrl + "child-enrollment-inductions/induction-save-child-parent", data, this.globalService.getHttpOptionsAuth());
    }

    getKeyPoints(FormId) {
        return this.http.get(this.globalService.baseUrl + "child-enrollment/get-key-points?form_id=" + FormId, this.globalService.getHttpOptionsAuth());
    }

    saveResponsibilityKeypoints(data) {
        return this.http.post(this.globalService.baseUrl + "child-enrollment-inductions/induction-save-responsibility-keypoints", data, this.globalService.getHttpOptionsAuth());
    }

    sendEmailToParent(data) {
        return this.http.post(this.globalService.baseUrl + "child-enrollment-inductions/induction-send-email", data, this.globalService.getHttpOptionsAuthWithOutContentType());
    }

    checkRandomKeys(randomKey) {
        return this.http.get(this.globalService.baseUrl + "child-enrollment-auth/check-random-key?random_key=" + randomKey, this.globalService.getHttpOptions());
    }

    checkVerifiedInductee(verification_link, module = "inductions") {
        return this.http.get(this.globalService.baseUrl + "child-enrollment-auth/verification?key=" + verification_link + '&module=' + module, this.globalService.getHttpOptions());
    }

    sendVerificationEmail(data) {
        return this.http.post(this.globalService.baseUrl + "child-enrollment-auth/send-verification-email", data, this.globalService.getHttpOptionsAuth());
    }
    removeMedia(data) {
        return this.http.post(this.globalService.baseUrl + "child-enrollment-auth/remove-document", data, this.globalService.getHttpOptionsAuth());
    }

    downloadEnrollmentChildListCSV(data) {
        return this.http.post(this.globalService.baseUrl + "reports/enrollment-child-list-csv", data, this.globalService.getHttpOptionsAuth());
    }

    downloadOrientationCSV(data) {
        return this.http.post(this.globalService.baseUrl + "reports/orientation-csv", data, this.globalService.getHttpOptionsAuth());
    }

    deleteInductionRecord(data,type = false) {
        return this.http.get(this.globalService.baseUrl + 'child-enrollment-inductions/delete-inductions-form?ref=' + data+'&type='+type, this.globalService.getHttpOptionsAuth());
    }

  checkEmailExsist(data) {
    return this.http.post(this.globalService.baseUrl + 'child-enrollment-inductions/email-check',data, this.globalService.getHttpOptionsAuth());
  }
}

<div class="row p-15">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13">
        <ng-container *ngIf="headerHide != 1">
            <h3>Educator Registration</h3>
            <h6>{{ data.created_at | date: 'longDate' }}</h6>
        </ng-container>
        <span class="label l-bg-red shadow-style m-b-14" *ngIf="data.status == 3">Email Sent - {{
            data.created_at | date: 'longDate'}}</span>
        <span class="label l-bg-red shadow-style m-b-14" *ngIf="data.status == 2">Pending - {{
            data.created_at | date: 'longDate'}}</span>
        <span class="label l-bg-green shadow-style m-b-14" *ngIf="data.status == 1">Approved - {{
            data.created_at | date: 'longDate'}}</span>
        <span class="label l-bg-orange shadow-style m-b-14" *ngIf="data.status == 0">Rejected </span>
        <br />

        <h5 class="form-group-heading"> Educator Details</h5>
        <table class="table table-sm border-0">
            <tr>
                <td style="width: 20%">Educator name</td>
                <td>: {{ data['educator_name'] | titlecase }}</td>
            </tr>
            <tr>
                <td>Gender</td>
                <td>: {{ data['gender'] | titlecase }}</td>
            </tr>
            <tr>
                <td>Date of birth</td>
                <td>: {{ data['dob'] | date: 'longDate'}}</td>
            </tr>
            <tr>
                <td>Position</td>
                <td>: {{ data['position'] }}</td>
            </tr>
            <tr *ngIf="data['position'] == 'Other'">
                <td>Other Position</td>
                <td>: {{ data['other_position'] }}</td>
            </tr>
            <tr>
                <td>Phone</td>
                <td>: {{ data['telephone'] }}</td>
            </tr>
            <tr>
                <td>Mobile Number</td>
                <td>: {{ data['mobile_no'] }}</td>
            </tr>
            <tr>
                <td>Email Address</td>
                <td>: {{ data['email'] }}</td>
            </tr>
            <tr>
                <td>Address</td>
                <td>: {{ data['address_1'] | titlecase }}</td>
            </tr>
            <tr>
                <td>Suburb</td>
                <td>: {{ data['suburb'] | titlecase }}</td>
            </tr>
            <tr>
                <td>State</td>
                <td>: {{ data['state_name'] | titlecase }}</td>
            </tr>
            <tr>
                <td>Postal Code</td>
                <td>: {{ data['postal_code'] }}</td>
            </tr>
        </table>

    </div>
</div>
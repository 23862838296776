import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'permissionPipe'
})
export class PermissionPipePipe implements PipeTransform {

  transform(value: any, permission: string): any {
    let user = JSON.parse(localStorage.getItem('user'));
    let permissions =  user['permissions'];
    if (permission === 'true') {
      return true;
    } else {
      let index = permissions.filter(x => x.toLowerCase().toString() ===  permission.toLowerCase().toString());
      if(index.length === 0)
        return false;
      return true;
    }
  }

}

import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-web-home',
  templateUrl: './web-home.component.html',
  styleUrls: ['./web-home.component.css']
})
export class WebHomeComponent implements OnInit {

  slides = [
    { url: 'assets/images/slider/1.JPG' },
    { url: 'assets/images/slider/2.JPG' },
    { url: 'assets/images/slider/3.JPG' }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}

import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {SignaturePad} from "angular2-signaturepad";
import {GlobalService} from "../../../global/global.service";

@Component({
  selector: 'app-signature-pads',
  templateUrl: './signature-pads.component.html',
  styleUrls: ['./signature-pads.component.scss']
})

export class SignaturePadsComponent implements OnInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  @Output() signaturePadEmit = new EventEmitter<any>();
  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 0.5,
    'maxWidth': 2.5,
    'canvasWidth': 292.22,
    'canvasHeight': 100,
    'penColor': "rgb(5,5,5)",
    'backgroundColor':"rgba(255,255,255,0.01)"
  };

  constructor(
    private globeService: GlobalService
  ) { }

  ngOnInit(): void {}

  // Signature pad integration
  drawComplete() {
    this.signaturePadEmit.emit(this.signaturePad.toDataURL());
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('begin drawing');
  }

  clearPad() {
    this.signaturePadEmit.emit(null);
    this.signaturePad.clear();
    this.signaturePadEmit.emit(null);
  }

}

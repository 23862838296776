<nav class="container-fluid bg-primary-green">
  <mat-toolbar class="bg-primary-green container">
    <mat-toolbar-row>
      <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
        <mat-icon>menu</mat-icon>
      </button>
      <span>
        <img src="../../../assets/logo.png" class="h-55">
      </span>
      <span class="menu-spacer"></span>
      <div fxShow="true" fxHide.lt-md class="menu-item">
        <!-- The following menu items will be hidden on both SM and XS screen sizes -->
        <a href="#">Feature</a>
        <a href="#" class="active">Home</a>
        <a href="#">Support</a>
        <a href="#">Resources</a>
        <a routerLink="signin">Login</a>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container fxFlexFill>
    <mat-sidenav #sidenav>
      <mat-nav-list>
        <a (click)="sidenav.toggle()" href="" mat-list-item>Close</a>
        <a href="#" mat-list-item>Feature</a>
        <a href="#" class="active" mat-list-item>Home</a>
        <a href="#" mat-list-item>Support</a>
        <a href="#" mat-list-item>Resources</a>
        <a href="#" mat-list-item>Login</a>
      </mat-nav-list>
    </mat-sidenav>
  </mat-sidenav-container>
</nav>

<div class="container-fluid padding-0">
    <!-- Slider -->
    <mat-carousel
      timings="250ms ease-in"
      [autoplay]="true"
      interval="5000"
      color="primary"
      maxWidth="auto"
      proportion="35"
      slides="5"
      [loop]="true"
      [hideArrows]="false"
      [hideIndicators]="true"
      orientation="ltr"
    >
      <mat-carousel-slide
        #matCarouselSlide
        *ngFor="let slide of slides; let i = index"
        [image]="slide.url"
        overlayColor="#00000040"
        [hideOverlay]="false"
      ></mat-carousel-slide>
    </mat-carousel>
    <!-- // Slider -->
</div>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {SharedComponentService} from "../../shared-component.service";
import {GlobalService} from "../../../global/global.service";
import {AccountService} from "../../../accounts/account.service";

@Component({
  selector: 'app-food-s-n-change-view',
  templateUrl: './food-s-n-change-view.component.html',
  styleUrls: ['./food-s-n-change-view.component.sass']
})
export class FoodSNChangeViewComponent implements OnInit {

  @Input() rowRef: string;
  @Input() headerHide: number = 0;
  @Input() notificationRef: string = '';
  @Output() closeModal = new EventEmitter<boolean>();
  data: any = [];
  loggedUser: any = [];
  signatureImg = null;
  currentDate = null;
  isNewSignature = true;
  isEdit = false;
  sectionForm: FormGroup;
  submitted =  false;
  error = '';
  sigError = false;
  allMembers: any = [];
  selectedChildren: any = [];
  children: any = [];
  parentsFeedback: any = [];

  currentIndex: any = -1;
  showFlag: any = false;
  htmlBody = null;

  constructor(
    private shardService: SharedComponentService,
    private fb: FormBuilder,
    private globeService: GlobalService,
    private accountService: AccountService,
  ) {
    this.loggedUser = JSON.parse(localStorage.getItem('user'));
    this.sectionForm = this.fb.group({
      ref: [''],
      table: [''],
      feedback: [''],
      parent_id: [''],
      child_id: [''],
      notification_ref: [''],
    });
  }

  ngOnInit(): void {
    this.getAllMembers();
    this.sectionForm.patchValue({
      ref: this.rowRef,
      table: 'food_s_n_change_forms',
      notification_ref: this.notificationRef,
    });
    this.getNotification();
    if(this.loggedUser['role_id'] == 6){
      let child = JSON.parse(localStorage.getItem('child'));
      this.sectionForm.patchValue({
        child_id: child['child_id']
      });
    }
  }

  showLightbox(index) {
    this.currentIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }

  getNotification() {
    this.htmlBody = null;
    var data = {food_s_n_change_ref: this.rowRef, type : 2,};
    this.shardService.viewNappyChangeForm(data).subscribe(res => {
      this.htmlBody = res['view_html'];
      this.parentsFeedback  = res['parents_feedback'];
    },error => {
      this.globeService.showErrorNotification(error.statusText);
    });
  }

  getAllMembers() {
    this.accountService.getAllMembers().subscribe((res) => {
        this.allMembers = res['members'];
      }, (error) => {
        this.error = error.error.error;
        this.globeService.showErrorNotification('Something went wrong!');
      }
    );
  }

  formSubmit(sectionForm: any) {
    this.accountService.updateParentFeedback(this.sectionForm.value).subscribe((res) => {
        this.globeService.showSuccessNotification('Successfully updated');
        this.modalClose();
      }, (error) => {
        this.error = error.error.error;
        this.globeService.showErrorNotification('Something went wrong!');
      }
    );
  }

  modalClose() {
    this.closeModal.emit(true);
  }

}

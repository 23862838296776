<mat-vertical-stepper [linear]="isLinear" #stepper (selectionChange)="onStepChange($event)">
    <mat-step [stepControl]="HFormGroup1">
        <form [formGroup]="HFormGroup1" style="margin-top: 5px;" (submit)="formSubmit('staff_details')">
            <ng-template matStepLabel>A. Staff Information</ng-template>
            <div class="p-t-15">
                <div class="row">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Applicant’s Name</mat-label>
                            <input formControlName="applicant_name" matInput>
                        </mat-form-field>
                        <!--                        <mat-label *ngIf="loggedUser['role_id'] == 7">Educator :</mat-label>-->
                        <!--                        <mat-label *ngIf="loggedUser['role_id'] == 7">{{this.loggedUser['first_name']}}</mat-label>-->
                        <!--                        <mat-form-field *ngIf="loggedUser['role_id'] != 7" class="example-full-width" appearance="outline" >-->
                        <!--                            <mat-label>Educator</mat-label>-->
                        <!--                            <mat-select formControlName="educator_id" required (selectionChange)="selectedEducator($event.source.triggerValue)">-->
                        <!--                                <mat-option *ngFor="let educator of educators let index = key;"  value="{{educator.educator_id}}">{{educator.educator_name}}</mat-option>-->
                        <!--                            </mat-select>-->
                        <!--                        </mat-form-field>-->
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Please Provide any Former Name or Other Name</mat-label>
                            <input formControlName="former_name" matInput (keyup)="showFormerNameDropzone($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12" *ngIf="showDropzone">
                        <em>Please attached evidence of change of name such as a copy of married certificate.</em>
                        <app-custom-dropzone (filePass)="uploadDocuments($event, 'formerName')"></app-custom-dropzone>
                        <app-dropzone-recieved [items]="formerNameDocuments"
                            (removeItemEvent)="resetUpload($event, 'formerName')"></app-dropzone-recieved>
                        <app-dropzone-priview [items]="formerNameDocumentsPreview"
                            (removeItemEvent)="resetUploadPreview($event, 'formerName')"></app-dropzone-priview>
                    </div>
                </div>

                <div class="row m-t-15">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                        <h6>Please attach a copy of one of the following as evidence of your identity.</h6>
                        <em>a. a current passport, or</em><br />
                        <em>b. a current driver’s license, or</em><br />
                        <em>c. a current proof of age card.</em>
                    </div>

                    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 mb-2">
                        <em>A current passport, or a current driver’s license, or a current proof of age card.</em>
                        <app-custom-dropzone (filePass)="uploadDocuments($event,'evidenceDocument')">
                        </app-custom-dropzone>
                        <app-dropzone-recieved [items]="evidenceDocuments"
                            (removeItemEvent)="resetUpload($event, 'evidenceDocument')"></app-dropzone-recieved>
                        <app-dropzone-priview [items]="evidenceDocumentsPreview"
                            (removeItemEvent)="resetUploadPreview($event, 'evidenceDocument')"></app-dropzone-priview>
                    </div>
                </div>

                <div class="row m-t-15">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline"
                            matTooltip="Please fill in your Working with Children Check Clearance Number (WWCC number)">
                            <mat-label>Please fill in your Working with Children To be checked Clearance Number (WWCC
                                number)</mat-label>
                            <input formControlName="wwcc_number" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
                        <h6>Please attach one of the following</h6>
                        <em>A copy of your current working with children check e.g. letter</em>
                        <br />
                        <p>Evidence of current teacher registration in NSW</p>
                        <app-custom-dropzone (filePass)="uploadDocuments($event,'evidenceCurrentWork')">
                        </app-custom-dropzone>
                        <app-dropzone-recieved [items]="evidenceCurrentWorkDocuments"
                            (removeItemEvent)="resetUpload($event, 'evidenceCurrentWork')"></app-dropzone-recieved>
                        <app-dropzone-priview [items]="evidenceCurrentWorkDocumentsPreview"
                            (removeItemEvent)="resetUploadPreview($event, 'evidenceCurrentWork')">
                        </app-dropzone-priview>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <section class="example-section">
                            <mat-checkbox (change)="toggleIsWorkBefore($event)" class="example-margin"
                                formControlName="is_work_before"> In the previous three years have you held any role
                                with an education and care service or a children’s services?</mat-checkbox>
                        </section>
                        <ng-container *ngIf="checkBokIsWorkBefore">
                            <section class="check-related-section">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <table class="table  table-remove-padding-form-fields">
                                        <tbody>
                                            <tr formArrayName="work_before_details"
                                                *ngFor="let item of HFormGroup1.get('work_before_details')['controls']; let key = index;">
                                                <td>
                                                    <div class="row p-t-6" [formGroupName]="key">
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2">
                                                            <mat-form-field class="example-full-width"
                                                                appearance="outline">
                                                                <mat-label>Name Of Service</mat-label>
                                                                <input formControlName="name_of_service" matInput>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2">
                                                            <mat-form-field class="example-full-width field-with-eg"
                                                                appearance="outline">
                                                                <mat-label>Service Address</mat-label>
                                                                <textarea rows="1" formControlName="service_address"
                                                                    matInput></textarea>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2">
                                                            <mat-form-field class="example-full-width field-with-eg"
                                                                appearance="outline">
                                                                <mat-label>Position</mat-label>
                                                                <input formControlName="position" matInput>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2">
                                                            <input #eamt_attachment type="file"
                                                                formControlName="work_before_file"
                                                                [multiple]="multiple_upload" accept='pdf/*'
                                                                (change)="mandatoryTrainingFileChange(item,$event)"
                                                                class="form-control form-control-sm border-0 p-l-0" />
                                                            <div *ngIf="isEdit">
                                                                <a *ngIf="work_before_details.value[key].work_before_file_url"
                                                                    [href]="work_before_details.value[key].work_before_file_url"
                                                                    target="_blank"
                                                                    download="eamt_attachment.pdf">Download
                                                                    File</a>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-1 col-lg-1 col-md-1 col-sm-12 mb-2">
                                                            <a class="text-underline pointer" href="javascript:void(0)"
                                                                (click)="removeMandatoryTraining(key)">Remove</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button type="button" class="btn btn-info btn-sm pull-right m-b-10"
                                        (click)="addMandatoryTraining(null)">Add New Record </button>
                                </div>
                            </section>
                        </ng-container>

                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <section class="example-section m-t-20 m-b-14">
                        <mat-checkbox (change)="toggleIsCriminal($event)" class="example-margin"
                            formControlName="is_criminal"> If you have provided a criminal history record check, have
                            you been convicted in Australia of any offences, relevant to a person <br /> seeking to work
                            with children in the period from the date this check was issued to the date of this
                            declaration?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBokIsCriminal">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Please provide details including the date and type of conviction</mat-label>
                                <textarea rows="1" formControlName="criminal_record_details" matInput></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <section class="example-section">
                        <mat-checkbox (change)="toggleIsOutsideAustralia($event)" class="example-margin"
                            formControlName="is_outside_australia">
                            Have you lived and worked outside Australia anytime within the previous three years?
                        </mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBoxIsOutsideAustralia">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Please provide a statement about whether you have been
                                    convicted outside Australia of any offences relevant to working
                                    with children</mat-label>
                                <textarea rows="1" formControlName="outside_australia_details" matInput></textarea>
                            </mat-form-field>
                        </section>
                        <section class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <app-custom-dropzone (filePass)="uploadDocuments($event,'outSideAustraliaDocument')">
                            </app-custom-dropzone>
                            <app-dropzone-recieved [items]="outSideWorkDocuments"
                                (removeItemEvent)="resetUpload($event, 'outSideAustraliaDocument')">
                            </app-dropzone-recieved>
                            <app-dropzone-priview [items]="outSideWorkDocumentsPreview"
                                (removeItemEvent)="resetUploadPreview($event, 'outSideAustraliaDocument')">
                            </app-dropzone-priview>
                        </section>
                    </ng-container>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <section class="example-section">
                        <mat-checkbox (change)="toggleIsDisciplinaryAction($event)" class="example-margin"
                            formControlName="is_disciplinary_action">
                            Have you ever been subject to a formal disciplinary proceeding or action under an education
                            law of any Australian state or territory?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBoxIsDisciplinaryAction">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline"
                                matTooltip="Please provide details including of the date and type of action or proceeding and the outcomes">
                                <mat-label>Please provide details including of the date and type of action or
                                    proceeding and the outcomes</mat-label>
                                <textarea rows="1" formControlName="disciplinary_action_details" matInput></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <section class="example-section">
                        <mat-checkbox (change)="toggleIsBankruptcy($event)" class="example-margin"
                            formControlName="is_bankruptcy">
                            Have you ever been the subject of any proceeding in bankruptcy?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBoxIsBankruptcy">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline"
                                matTooltip="Please provide details including of the date and type of action or proceeding and the outcomes">
                                <mat-label>Please provide details</mat-label>
                                <textarea rows="1" formControlName="bankruptcy_details" matInput></textarea>
                            </mat-form-field>
                        </section>
                        <section class="">
                            <h6>Please provide details, including</h6>
                            <em>a. Whether any actions have been taken against you under Part IV of the Bankruptcy Act
                                1966 of Commonwealth, and</em> <br />
                            <em>b. Whether you have made any debt agreement under Part IX of the Bankruptcy Act 1966
                                of the Commonwealth; and</em> <br />
                            <em>c. Whether you have made any personal insolvency agreement under Part X of the
                                Bankruptcy Act 1966 of the Commonwealth.</em>
                            <br />
                        </section>
                        <section class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <app-custom-dropzone (filePass)="uploadDocuments($event,'bankruptcy')">
                            </app-custom-dropzone>
                            <app-dropzone-recieved [items]="bankruptcyDocuments"
                                (removeItemEvent)="resetUpload($event, 'bankruptcy')"></app-dropzone-recieved>
                            <app-dropzone-priview [items]="bankruptcyDocumentsPreview"
                                (removeItemEvent)="resetUploadPreview($event, 'bankruptcy')"></app-dropzone-priview>
                        </section>
                    </ng-container>
                </div>
            </div>
            <input type="hidden" formControlName="assessment_staff_background_ref">
            <div class="pull-right">
                <button mat-raised-button matStepperNext class="text-white color-bg-purple">Next >></button>
                <!--            <button mat-flat-button matStepperNext class="text-white" color="primary" [disabled]="submitted " [class.not-allowed]="submitted" [class.spinner]="submitted">Next</button>-->
            </div>
        </form>
    </mat-step>
    <mat-step [formGroup]="HFormGroup2">
        <form [formGroup]="HFormGroup2" style="margin-top: 5px;" (submit)="formSubmit('declaration')">
            <ng-template matStepLabel>B. Declaration</ng-template>
            <div class="row m-t-15">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                    <h5>I declare that :</h5>
                    <ol class="list-style-number p-l-20">
                        <li> The information provided in this form (including any attachments) is true, complete and
                            correct.</li>
                        <li> I have read, understood and agree to the conditions and the associated material contained
                            in this form.</li>
                        <li> I understand that {{ loggedUser['user']['center_name'] }} has the right to act in reliance
                            upon the contents of this form, including its attachments.</li>
                        <li> I have read and understood the legal obligations of an educator under the Education and
                            Care Service National Law.</li>
                        <li> {{ loggedUser['user']['center_name'] }} has the right to verify any information provided in
                            this form.</li>
                        <li> Some information provided in this form may be disclosed in the Commonwealth for the purpose
                            of
                            the Family Assistance Law and may be disclosed to the persons/authorities where authorised
                            by the
                            Education and Care Services National Law or other legislation, and.</li>
                        <li> I am aware that under the Education and Care Services National Law penalties apply if false
                            or misleading information is provided.</li>
                    </ol>
                </div>

            </div>

            <div class="row m-t-15">

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Sign at:</mat-label>
                        <input type="text" formControlName="sign_at" matInput>
                    </mat-form-field>

                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Sign on:</mat-label>
                        <input matInput formControlName="sign_date" [matDatepicker]="sign_date"
                            (click)="sign_date.open()">
                        <mat-datepicker-toggle matSuffix [for]="sign_date"></mat-datepicker-toggle>
                        <mat-datepicker #sign_date></mat-datepicker>
                    </mat-form-field>

                </div>

            </div>

            <div class="row" *ngIf="loggedUser['role_id'] != 6">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <label class="f-12">Signature</label>
                    <div *ngIf="!isNewSignature && signatureImg != ''">
                        <span *ngIf="isEdit" (click)="removeSignature()"
                            class="badge badge-danger remove-signature pointer b-r-15">x</span>
                        <img src="{{ signatureImg }}">
                    </div>
                    <ng-container *ngIf="isNewSignature || signatureImg == ''">
                        <app-signature-pads (signaturePadEmit)="signatureImgOutput($event)"></app-signature-pads>
                    </ng-container>
                </div>
            </div>
            <div class="row" *ngIf="loggedUser['role_id'] == 6">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <label class="f-12">Signature</label>
                    <div *ngIf="!isNewSignature && signatureImg != ''">
                        <img src="{{ signatureImg }}">
                    </div>
                </div>
            </div>


            <div class="pull-right">
                <button mat-raised-button matStepperPrevious color="warn" class="mr-2">
                    << Back</button>
                        <button mat-raised-button matStepperNext class="text-white color-bg-purple">Next >></button>
            </div>
        </form>
    </mat-step>
    <mat-step [formGroup]="HFormGroup3">
        <form [formGroup]="HFormGroup3" style="margin-top: 5px;" (submit)="formSubmit('confidentiality_agreement')">
            <ng-template matStepLabel>Confidentiality Agreement</ng-template>
            <div class="row m-t-15">
                <div class="col-12 m-t-15">
                    <div class="display-inline">
                        <p>I acknowledge that I have read and understood the
                            Privacy and Confidentiality Policy of {{ loggedUser['user']['center_name'] }}.</p>
                        <p>I agree that all information about the service, staff, children and families, sighted or
                            heard, will be
                            treated as strictly confidential and will not be discussed outside the service unless with
                            consent from
                            the management or as required by law such as agencies and authorities.</p>
                        <p>I agree to maintain confidentiality of all records and information received during the course
                            of my
                            employment/engagement with {{ loggedUser['user']['center_name'] }} or thereafter.</p>
                        <p>I understand that if I breach this confidentiality agreement then disciplinary action and/or
                            penalty
                            against Copyright Act 1968 and Privacy Act 1988 will be taken.</p>
                    </div>
                </div>
            </div>

            <!--            <div class="row m-t-15">-->
            <!--                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">-->
            <!--                    <mat-form-field class="example-full-width" appearance="outline">-->
            <!--                        <mat-label>Position/ Role</mat-label>-->
            <!--                        <input formControlName="position" matInput>-->
            <!--                    </mat-form-field>-->
            <!--                </div>-->
            <!--                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">-->
            <!--                    <mat-form-field class="example-full-width" appearance="outline">-->
            <!--                        <mat-label>Date:</mat-label>-->
            <!--                        <input matInput formControlName="sign_date1" [matDatepicker]="sign_date1" (click)="sign_date1.open()">-->
            <!--                        <mat-datepicker-toggle matSuffix [for]="sign_date1"></mat-datepicker-toggle>-->
            <!--                        <mat-datepicker #sign_date1></mat-datepicker>-->
            <!--                    </mat-form-field>-->
            <!--                </div>-->
            <!--            </div>-->

            <div class="row">
                <!--                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">-->
                <!--                    <mat-form-field class="example-full-width" appearance="outline">-->
                <!--                        <mat-label>Staff/Personnel Name</mat-label>-->
                <!--                        <input formControlName="nominated_supervisor" matInput>-->
                <!--                    </mat-form-field>-->
                <!--                </div>-->
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Date:</mat-label>
                        <input matInput formControlName="sign_date2" [matDatepicker]="sign_date2"
                            (click)="sign_date2.open()">
                        <mat-datepicker-toggle matSuffix [for]="sign_date2"></mat-datepicker-toggle>
                        <mat-datepicker #sign_date2></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <label class="f-12">Signature</label>
                    <div *ngIf="!isNewSignature2 && signatureImg2 != ''">
                        <span *ngIf="isEdit" (click)="removeSignature2()"
                            class="badge badge-danger remove-signature pointer b-r-15">x</span>
                        <img src="{{ signatureImg2 }}">
                    </div>
                    <ng-container *ngIf="isNewSignature2 || signatureImg2 == ''">
                        <app-signature-pads (signaturePadEmit)="signatureImgOutput2($event)"></app-signature-pads>
                    </ng-container>
                    <br />
                    <br />
                </div>
            </div>



            <div class="pull-right">
                <button mat-raised-button matStepperPrevious color="warn" class="mr-2">
                    << Back</button>
                        <button mat-raised-button matStepperNext class="text-white color-bg-purple">Next >></button>
            </div>
        </form>
    </mat-step>
    <mat-step [formGroup]="HFormGroup4">
        <form [formGroup]="HFormGroup4" style="margin-top: 5px;" (submit)="formSubmit('reference_background')">
            <ng-template matStepLabel>Reference and Background Consent</ng-template>
            <div class="row m-t-15">
                <div class="col-12 m-t-15">
                    <p>I give consent to {{ loggedUser['user']['center_name'] }} to contact my referees:</p>
                    <p> Must provide 2 previous employer if there’s no current employer.</p>
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggleCurrentEmployee($event)" class="example-margin"
                            formControlName="is_current_employee"> if there’s no current employer ?</mat-checkbox>
                    </section>
                </div>
            </div>
            <ng-container *ngIf="!currentWork">
                <h5 class="m-b-14 form-group-heading">Current Employer/Company</h5>
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Current Employer/Company name</mat-label>
                            <input formControlName="current_company" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Contact Name</mat-label>
                            <input formControlName="current_company_contact" matInput>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Position</mat-label>
                            <input formControlName="current_company_position" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Work/Institution Address</mat-label>
                            <input formControlName="current_company_address" matInput>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Contact Details</mat-label>
                            <input formControlName="current_company_contact_details" matInput>
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
            <h5 class="m-b-14 form-group-heading">Previous Employer/Company 1</h5>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Previous Employer/Company name</mat-label>
                        <input formControlName="previous_company" matInput>
                    </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Contact Name</mat-label>
                        <input formControlName="previous_company_contact" matInput>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Position</mat-label>
                        <input formControlName="previous_company_position" matInput>
                    </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Work/Institution Address</mat-label>
                        <input formControlName="previous_company_address" matInput>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Contact Details</mat-label>
                        <input formControlName="previous_company_contact_details" matInput>
                    </mat-form-field>
                </div>
            </div>
            <ng-container *ngIf="currentWork">
                <h5 class="m-b-14 form-group-heading">Previous Employer/Company 2</h5>
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Previous Employer/Company name</mat-label>
                            <input formControlName="previous_company_2" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Contact Name</mat-label>
                            <input formControlName="previous_company_contact_2" matInput>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Position</mat-label>
                            <input formControlName="previous_company_position_2" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Work/Institution Address</mat-label>
                            <input formControlName="previous_company_address_2" matInput>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Contact Details</mat-label>
                            <input formControlName="previous_company_contact_details_2" matInput>
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>

            <h5 class="m-b-14 form-group-heading">Character Reference 1</h5>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Full Name</mat-label>
                        <input formControlName="cr_full_name" matInput>
                    </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Position</mat-label>
                        <input formControlName="cr_position" matInput>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Work/Institution Address</mat-label>
                        <input formControlName="cr_address" matInput>
                    </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Contact Details</mat-label>
                        <input formControlName="cr_contact_details" matInput>
                    </mat-form-field>
                </div>
            </div>

            <h5 class="m-b-14 form-group-heading">Character Reference 2</h5>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Full Name</mat-label>
                        <input formControlName="cr2_full_name" matInput>
                    </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Position</mat-label>
                        <input formControlName="cr2_position" matInput>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Work/Institution Address</mat-label>
                        <input formControlName="cr2_address" matInput>
                    </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Contact Details</mat-label>
                        <input formControlName="cr2_contact_details" matInput>
                    </mat-form-field>
                </div>
            </div>

            <!--            <div class="row">-->
            <!--                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">-->
            <!--                    <mat-form-field class="example-full-width" appearance="outline">-->
            <!--                        <mat-label>Applicant’s Name</mat-label>-->
            <!--                        <input formControlName="applicant_name" matInput>-->
            <!--                    </mat-form-field>-->
            <!--                </div>-->
            <!--            </div>-->


            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <label class="f-12">Signature of the Applicant</label>
                    <div *ngIf="!isNewSignature3 && signatureImg3 != ''">
                        <span *ngIf="isEdit" (click)="removeSignature3()"
                            class="badge badge-danger remove-signature pointer b-r-15">x</span>
                        <img src="{{ signatureImg3 }}">
                    </div>
                    <ng-container *ngIf="isNewSignature3 || signatureImg3 == ''">
                        <app-signature-pads (signaturePadEmit)="signatureImgOutput3($event)"></app-signature-pads>
                    </ng-container>
                    <br />
                    <br />
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Signature Date</mat-label>
                        <input matInput formControlName="sign_date3" [matDatepicker]="sign_date3"
                            (click)="sign_date3.open()">
                        <mat-datepicker-toggle matSuffix [for]="sign_date3"></mat-datepicker-toggle>
                        <mat-datepicker #sign_date3></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <p>Please be advised that making false statements or providing misleading information is a serious
                        offence
                        under the Criminal Code Act 1995 (Cth) section 137.1 which may constitute a criminal offence and
                        lead to
                        imprisonment</p>
                </div>
            </div>

            <div class="pull-right">
                <button mat-raised-button matStepperPrevious color="warn" class="mr-2">
                    << Back</button>
                        <button mat-raised-button matStepperNext class="text-white color-bg-purple">Next >></button>
            </div>
        </form>
    </mat-step>
    <mat-step [formGroup]="HFormGroup5">
        <form [formGroup]="HFormGroup5" style="margin-top: 5px;" (submit)="formSubmit('medical_health')">
            <ng-template matStepLabel>Medical and Health Declaration</ng-template>
            <div class="row m-t-15">
                <div class="col-12 m-t-15">
                    <h5 class="m-b-14 form-group-heading">I. PRE-REGISTRATION MEDICAL HISTORY</h5>
                    <p>To be completed by potential family day care educators and forwarded to the
                        {{loggedUser['user'].center_name}}.
                        'Educators, staff and children who are not immunised must be excluded when there is an outbreak
                        of a vaccine preventable contagious disease?
                        Immunisation is a key way of minimising the spread of many infectious' update to 'Educators,
                        staff and children who are not immunised must be excluded when there is an outbreak of a vaccine
                        preventable contagious disease?
                        Immunisation is a key way of minimising the spread of many infectious diseases.'
                    </p>
                </div>
            </div>
            <h5 class="m-b-14 form-group-heading">Please provide immunisation history record from your doctor</h5>
            <div class="row">

                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Document Description</mat-label>
                        <input formControlName="medical_doc_description" matInput>
                    </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <app-custom-dropzone (filePass)="uploadDocuments($event,'medicalDocuments')"></app-custom-dropzone>
                    <app-dropzone-recieved [items]="medicalDocuments"
                        (removeItemEvent)="resetUpload($event, 'medicalDocuments')"></app-dropzone-recieved>
                    <app-dropzone-priview [items]="medicalDocumentsPreview"
                        (removeItemEvent)="resetUploadPreview($event, 'medicalDocuments')"></app-dropzone-priview>
                </div>
            </div>

            <h5 class="m-b-14 form-group-heading">Applicant Details</h5>
            <div class="row">
                <!--                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">-->
                <!--                    <mat-form-field class="example-full-width" appearance="outline">-->
                <!--                        <mat-label>Name</mat-label>-->
                <!--                        <input formControlName="aplicant_name" matInput>-->
                <!--                    </mat-form-field>-->
                <!--                </div>-->
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>D.O.B:</mat-label>
                        <input matInput formControlName="applicant_dob" [matDatepicker]="applicant_dob"
                            (click)="applicant_dob.open()">
                        <mat-datepicker-toggle matSuffix [for]="applicant_dob"></mat-datepicker-toggle>
                        <mat-datepicker #applicant_dob></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Address</mat-label>
                        <input formControlName="applicant_address" matInput>
                    </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Post code</mat-label>
                        <input formControlName="applicant_post_code" matInput>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Phone no</mat-label>
                        <input formControlName="applicant_phone" matInput>
                    </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Mobile no</mat-label>
                        <input formControlName="applicant_mobile" matInput>
                    </mat-form-field>
                </div>
            </div>
            <p>Have you had any of the following? If yes, please give brief details including medications
                taken for medical conditions.</p>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle1($event)" class="example-margin" formControlName="medical_1">
                            Severe headache or migraine?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox1">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_1"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle2($event)" class="example-margin" formControlName="medical_2">
                            Fits, convulsions or epilepsy?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox2">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_2"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle3($event)" class="example-margin" formControlName="medical_3">
                            Bronchitis, pneumonia or lung tuberculosis?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox3">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_3"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle4($event)" class="example-margin" formControlName="medical_4">
                            Arthritis or joint complaints?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox4">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_4"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle5($event)" class="example-margin" formControlName="medical_5">
                            Gastric or duodenal ulcer, frequent or prolonged indigestion?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox5">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_5"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle6($event)" class="example-margin" formControlName="medical_6"> Any
                            kidney or bladder infections?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox6">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_6"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle7($event)" class="example-margin" formControlName="medical_7">
                            Episodes of chest pain or breathlessness?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox7">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_7"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle8($event)" class="example-margin" formControlName="medical_8">
                            High blood pressure?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox8">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_8"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle9($event)" class="example-margin" formControlName="medical_9">
                            Prolonged or severe back pain?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox9">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_9"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle10($event)" class="example-margin" formControlName="medical_10">
                            Varicose vein or foot or leg ailments?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox10">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_10"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle11($event)" class="example-margin" formControlName="medical_11">
                            Eye conditions or injuries?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox11">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_11"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle12($event)" class="example-margin" formControlName="medical_12">
                            Ear infections, deafness, pain or discharge?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox12">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_12"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle13($event)" class="example-margin" formControlName="medical_13">
                            Diabetes?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox13">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_13"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle14($event)" class="example-margin" formControlName="medical_14">
                            Nervous or mental trouble?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox14">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_14"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle15($event)" class="example-margin" formControlName="medical_15">
                            Any surgical procedures or operations?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox15">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_15"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle16($event)" class="example-margin" formControlName="medical_16">
                            Any other conditions requiring hospital treatment?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox16">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_16"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle17($event)" class="example-margin" formControlName="medical_17">
                            Are you taking any regular prescribed medication?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox17">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_17"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle18($event)" class="example-margin" formControlName="medical_18">
                            Have you ever had any life insurance rejected or deferred?</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox18">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_18"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <section class="example-section m-t-20-">
                        <mat-checkbox (change)="toggle19($event)" class="example-margin" formControlName="medical_19">
                            Have you ever made any compensation claim for illness or injury (Work cover,
                            TAC, Other) If Yes please give details.</mat-checkbox>
                    </section>
                    <ng-container *ngIf="checkBox19">
                        <section class="check-related-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Details</mat-label><textarea matInput formControlName="medical_d_19"
                                    rows="1"></textarea>
                            </mat-form-field>
                        </section>
                    </ng-container>
                </div>
            </div>

            <div class="row m-t-15">
                <div class="col-12 m-t-15">
                    <h5 class="m-b-14 form-group-heading">II. HEALTH DECLARATION</h5>
                    <div class="row m-t-15">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                            <section class="example-section m-t-20-">
                                <mat-checkbox (change)="toggle20($event)" class="example-margin"
                                    formControlName="health_a"> A. Are you aware of any circumstances regarding your
                                    health or capacity to work that would interfere
                                    with your ability to perform the duties of your role as <br /> Family Day Care
                                    Educator?</mat-checkbox>
                            </section>
                            <p>In answering this question Yes or No you are also covering factors such as: existing or
                                exposure to
                                infectious diseases, taking of medication/treatment on a regular basis (daily, weekly,
                                monthly).</p>
                            <p>If yes, what adjustments do you need to perform the genuine and reasonable requirements
                                of the
                                employment (if any)?”</p>
                            <ng-container *ngIf="checkBox20">
                                <section>
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Details</mat-label><textarea matInput
                                            formControlName="health_a_details" rows="1"></textarea>
                                    </mat-form-field>
                                </section>
                            </ng-container>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                            <section class="example-section m-t-20-">
                                <mat-checkbox (change)="toggle21($event)" class="example-margin"
                                    formControlName="health_b"> B. Do you have an existing injury or condition or
                                    pre-existing injury or condition that could be affected
                                    by the nature of the proposed operating your own family day care business?
                                </mat-checkbox>
                            </section>
                            <p>Existing is a condition for which treatment is still being received. Pre-existing is
                                where an injury or
                                condition/s is present but treatment is not required. If yes please provide details of
                                the injury or
                                condition(s). If yes, what adjustments do you need to perform the genuine and reasonable
                                requirements of
                                your role as a Family Day Care Educator (if any)?”</p>
                            <ng-container *ngIf="checkBox21">
                                <section>
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Details</mat-label><textarea matInput
                                            formControlName="health_b_details" rows="1"></textarea>
                                    </mat-form-field>
                                </section>
                            </ng-container>
                        </div>
                    </div>
                    <div class="row m-t-15">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                            <section class="example-section m-t-20-">
                                <mat-checkbox (change)="toggle22($event)" class="example-margin"
                                    formControlName="health_c"> C. Have you ever worked with any substances or in any
                                    conditions which may have been hazardous to
                                    your health <br />(e.g. asbestos exposure, toxic chemicals, stressful or noisy
                                    environments) and for which you need a modified workplace?</mat-checkbox>
                            </section>
                            <p>If yes, what specific adjustments or modifications can be made (if any) to ensure your
                                family day
                                care service is safe and without further risk to your health?”</p>
                            <ng-container *ngIf="checkBox22">
                                <section>
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Details</mat-label><textarea matInput
                                            formControlName="health_c_details" rows="1"></textarea>
                                    </mat-form-field>
                                </section>
                            </ng-container>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-2">
                            <mat-label>D. Educator and Educator Assistant/Relief Vaccination Record</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-2">
                            <section class="example-section m-t-20-">
                                <mat-checkbox class="example-margin" formControlName="health_d"> Have you been
                                    vaccinated as per as the vaccinations listed below?</mat-checkbox>
                            </section>
                        </div>
                    </div>

                    <p>Please provide details</p>
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                            <section class="example-section m-t-20-">
                                <mat-checkbox (change)="toggle23($event)" class="example-margin"
                                    formControlName="disease_1"> Diphtheria, Tetanus, Pertussis (whooping cough)
                                </mat-checkbox>
                            </section>
                            <ng-container *ngIf="checkBox23">
                                <section class="check-related-section">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Date</mat-label>
                                        <input matInput formControlName="disease_d_1" [matDatepicker]="disease_d_1"
                                            (click)="disease_d_1.open()">
                                        <mat-datepicker-toggle matSuffix [for]="disease_d_1"></mat-datepicker-toggle>
                                        <mat-datepicker #disease_d_1></mat-datepicker>
                                    </mat-form-field>
                                </section>
                            </ng-container>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                            <section class="example-section m-t-20-">
                                <mat-checkbox (change)="toggle24($event)" class="example-margin"
                                    formControlName="disease_2"> Hepatitis B</mat-checkbox>
                            </section>
                            <ng-container *ngIf="checkBox24">
                                <section class="check-related-section">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Date</mat-label>
                                        <input matInput formControlName="disease_d_2" [matDatepicker]="disease_d_2"
                                            (click)="disease_d_2.open()">
                                        <mat-datepicker-toggle matSuffix [for]="disease_d_2"></mat-datepicker-toggle>
                                        <mat-datepicker #disease_d_2></mat-datepicker>
                                    </mat-form-field>
                                </section>
                            </ng-container>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                            <section class="example-section m-t-20-">
                                <mat-checkbox (change)="toggle25($event)" class="example-margin"
                                    formControlName="disease_3"> Measles, mumps, rubella (MMR)</mat-checkbox>
                            </section>
                            <ng-container *ngIf="checkBox25">
                                <section class="check-related-section">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Date</mat-label>
                                        <input matInput formControlName="disease_d_3" [matDatepicker]="disease_d_3"
                                            (click)="disease_d_3.open()">
                                        <mat-datepicker-toggle matSuffix [for]="disease_d_3"></mat-datepicker-toggle>
                                        <mat-datepicker #disease_d_3></mat-datepicker>
                                    </mat-form-field>
                                </section>
                            </ng-container>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                            <section class="example-section m-t-20-">
                                <mat-checkbox (change)="toggle26($event)" class="example-margin"
                                    formControlName="disease_4"> Varicella (chickenpox)</mat-checkbox>
                            </section>
                            <ng-container *ngIf="checkBox26">
                                <section class="check-related-section">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Date</mat-label>
                                        <input matInput formControlName="disease_d_4" [matDatepicker]="disease_d_4"
                                            (click)="disease_d_4.open()">
                                        <mat-datepicker-toggle matSuffix [for]="disease_d_4"></mat-datepicker-toggle>
                                        <mat-datepicker #disease_d_4></mat-datepicker>
                                    </mat-form-field>
                                </section>
                            </ng-container>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                            <section class="example-section m-t-20-">
                                <mat-checkbox (change)="toggle27($event)" class="example-margin"
                                    formControlName="disease_5"> Tuberculosis (TB)</mat-checkbox>
                            </section>
                            <ng-container *ngIf="checkBox27">
                                <section class="check-related-section">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Date</mat-label>
                                        <input matInput formControlName="disease_d_5" [matDatepicker]="disease_d_5"
                                            (click)="disease_d_5.open()">
                                        <mat-datepicker-toggle matSuffix [for]="disease_d_5"></mat-datepicker-toggle>
                                        <mat-datepicker #disease_d_5></mat-datepicker>
                                    </mat-form-field>
                                </section>
                            </ng-container>
                        </div>
                    </div>

                    <p>Educators, staff and children who are not immunised must be excluded when there is an outbreak of
                        a
                        vaccine preventable contagious disease? Immunisation is a key way of minimising the spread of
                        many
                        infectious</p>
                    <h5 class="form-group-heading m-t1-14">General Practioner’s (GP) Details</h5>
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Medical Doctor’s Name</mat-label>
                                <input type="text" matInput formControlName="doctor_name">
                            </mat-form-field>
                            <!--                            <br/>-->
                            <!--                            <label class="f-12">Doctor’s Signature:</label>-->
                            <!--                            <div *ngIf="!isNewSignature4 && signatureImg4 != ''">-->
                            <!--                                <span *ngIf="isEdit"  (click)="removeSignature4()" class="badge badge-danger remove-signature pointer b-r-15">x</span>-->
                            <!--                                <img src="{{ signatureImg4 }}">-->
                            <!--                            </div>-->
                            <!--                            <ng-container *ngIf="isNewSignature4 || signatureImg4 == ''">-->
                            <!--                                <app-signature-pads (signaturePadEmit)="signatureImgOutput4($event)"></app-signature-pads>-->
                            <!--                            </ng-container>-->
                            <!--                            <br/>-->
                            <!--                            <br/>-->
                            <!--                            <mat-form-field class="example-full-width" appearance="outline">-->
                            <!--                                <mat-label>Sign on:</mat-label>-->
                            <!--                                <input matInput formControlName="sign_date4" [matDatepicker]="sign_date4" (click)="sign_date4.open()">-->
                            <!--                                <mat-datepicker-toggle matSuffix [for]="sign_date4"></mat-datepicker-toggle>-->
                            <!--                                <mat-datepicker #sign_date4></mat-datepicker>-->
                            <!--                            </mat-form-field>-->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Medical Address</mat-label>
                                <input type="text" matInput formControlName="medical_address">
                            </mat-form-field>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Contact Number</mat-label>
                                <input formControlName="medical_contact_no" matInput>

                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pull-right">
                <button mat-raised-button matStepperPrevious color="warn" class="mr-2">
                    << Back</button>
                        <button mat-raised-button matStepperNext class="text-white color-bg-purple">Next >></button>
            </div>
        </form>
    </mat-step>
    <mat-step [formGroup]="HFormGroup6">
        <form [formGroup]="HFormGroup6" style="margin-top: 5px;" (submit)="formSubmit('educator_declaration')">
            <ng-template matStepLabel>Staff Declaration</ng-template>
            <div class="row m-t-15">
                <div class="col-12 m-t-15">
                    <p>I do sincerely declare that the contents of this form are true and correct and complete to the
                        best of my
                        knowledge and no information concerning my past or present state of health has been withheld. I
                        hereby
                        agree to undergo a health assessment by a medical practitioner if deemed necessary by the
                        {{loggedUser['user'].center_name}}
                    </p>
                    <p>I am aware that I may be required to undergo a hearing test. I will be advised that if a work
                        related noise
                        induced hearing deficit is detected that a compensation claim should be lodged against the
                        relevant past
                        employer. I am aware that the record of audiometry will be held in my file. I am aware that I
                        will be asked to
                        meet the cost of these examinations/reports.</p>
                    <p>I understand that any wilfully incorrect or misleading answer or material omission which relates
                        to any of the
                        questions before mentioned may make me ineligible to be registered and engaged as a Family Day
                        Care
                        Educator, or to operate a Family Day Care, liable to disciplinary action which may include
                        cancellation of
                        registration/employment</p>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <label class="f-12">Applicant’s signature</label>
                    <div *ngIf="!isNewSignature5 && signatureImg5 != ''">
                        <span *ngIf="isEdit" (click)="removeSignature5()"
                            class="badge badge-danger remove-signature pointer b-r-15">x</span>
                        <img src="{{ signatureImg5 }}">
                    </div>
                    <ng-container *ngIf="isNewSignature5 || signatureImg5 == ''">
                        <app-signature-pads (signaturePadEmit)="signatureImgOutput5($event)"></app-signature-pads>
                    </ng-container>
                    <br />
                    <br />
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Applicant’s signature Date</mat-label>
                        <input matInput formControlName="applicant_sign_date" [matDatepicker]="applicant_sign_date"
                            (click)="applicant_sign_date.open()">
                        <mat-datepicker-toggle matSuffix [for]="applicant_sign_date"></mat-datepicker-toggle>
                        <mat-datepicker #applicant_sign_date></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                    <p>Reference:</p>
                    <p>Department of Education and Training: Human resource pre-employment screening. Retrieved from
                        http://www.education.vic.gov.au/hrweb/pages/resources/formsts.</p>
                </div>
            </div>

            <div class="pull-right">
                <button mat-raised-button matStepperPrevious color="warn" class="mr-2">
                    << Back</button>
                        <button mat-flat-button matStepperNext class="text-white" color="primary"
                            [disabled]="submitted || !HFormGroup6.valid" [class.not-allowed]="submitted"
                            [class.spinner]="submitted">Submit</button>
            </div>

        </form>
    </mat-step>
</mat-vertical-stepper>
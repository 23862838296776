import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roleRename'
})
export class RoleRenamePipe implements PipeTransform {

  transform(role: string): any {

    if(role == 'admin'){
      return  'Supper Admin'
    } else if (role == 'director'){
      return  'Service Provider'
    } else if (role == 'parent'){
      return  'Family'
    } else if (role == 'educator'){
      return  'Educator'
    } else if (role == 'staff'){
      return  'Staff'
    } else {
      return  role
    }
  }
}

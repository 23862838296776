import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date): string {
    return `${date.getFullYear()}`;
  }
}

@Component({
  selector: 'app-year-picker',
  templateUrl: './year-picker.component.html',
  styleUrls: ['./year-picker.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: { dateInput: 'YYYY' },
        display: { dateInput: 'YYYY', monthYearLabel: 'YYYY', dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY' },
      },
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearPickerComponent implements OnInit {
  @Output() yearPickerEmit = new EventEmitter<any>();

  constructor() { }
  ngOnInit(): void {
  }
  selectedDate = null;
  minDate = new Date(Date.now());

  public onYearSelected(date: Date, datepicker: MatDatepicker<Date>) {
    const normalizedYear = date.getFullYear();
    this.selectedDate = new Date(normalizedYear, 12, 0);
    datepicker.close();
    this.yearPickerEmit.emit(this.selectedDate);
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from "@angular/material/sidenav";

@Component({
  selector: 'app-auth-layout',
  templateUrl: './web-layout.component.html',
  styleUrls: ['./web-layout.component.css'],
})
export class WebLayoutComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;

  reason = '';

  close(reason: string) {
    this.reason = reason;
    this.sidenav.close();
  }
  constructor() {}

  ngOnInit(): void {}
}

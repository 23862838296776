import { Page404Component } from './authentication/page404/page404.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import {WebLayoutComponent} from "./layout/app-layout/web-layout/web-layout.component";

const routes: Routes = [
  {
    path: 'admin',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      // { path: '', redirectTo: '/authentication', pathMatch: 'full' },
      {
        path: 'child-enrollment',
        loadChildren: () =>
          import('./child-enrollment/child-enrollment.module').then((m) => m.ChildEnrollmentModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./account-dashboard/account-dashboard.module').then((m) => m.AccountDashboardModule),
      },
      {
        path: 'accounts',
        loadChildren: () =>
          import('./accounts/accounts.module').then((m) => m.AccountsModule),
      },
      {
        path: 'accounts',
        loadChildren: () =>
          import('./directors/directors.module').then((m) => m.DirectorsModule),
      },
      {
        path: 'education-form',
        loadChildren: () =>
          import('./education-form/education-form.module').then((m) => m.EducationFormModule),
      },
      {
        path: 'management-form',
        loadChildren: () =>
          import('./management-form/management-form.module').then((m) => m.ManagementFormModule),
      },
      {
        path: 'health-safety-form',
        loadChildren: () =>
          import('./healthandsafety-form/healthandsafety-form.module').then((m) => m.HealthandsafetyFormModule),
      },
      {
        path: 'physical-env-form',
        loadChildren: () =>
          import('./physical-env-form/physical-env-form.module').then((m) => m.PhysicalEnvFormModule),
      },
      {
        path: 'staff-and-educator',
        loadChildren: () =>
            import('./staff-and-educator/staff-and-educator.module').then((m) => m.StaffAndEducatorModule),
      },
      {
        path: 'families-communities',
        loadChildren: () =>
            import('./families-communities-form/families-communities-form.module').then((m) => m.FamiliesCommunitiesFormModule),
      },
      {
        path: 'child-details',
        loadChildren: () =>
          import('./child-details/child-details.module').then((m) => m.ChildDetailsModule),
      },
      {
        path: 'send-email',
        loadChildren: () =>
          import('./acess-email/acess-email.module').then((m) => m.AcessEmailModule),
      },
      {
        path: 'support',
        loadChildren: () =>
          import('./supports/supports.module').then((m) => m.SupportsModule),
      },
      {
        path: 'support-acess',
        loadChildren: () =>
        import("./user-support/user-support.module").then(
          (m) => m.UserSupportModule
        ),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./notifications/notifications.module').then((m) => m.NotificationsModule),
      },

      // {
      //   path: 'dashboard',
      //   loadChildren: () =>
      //     import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      // },
      {
        path: 'advance-table',
        loadChildren: () =>
          import('./advance-table/advance-table.module').then(
            (m) => m.AdvanceTableModule
          ),
      },
      {
        path: 'calendar',
        loadChildren: () =>
          import('./calendar/calendar.module').then((m) => m.CalendarsModule),
      },
      {
        path: 'task',
        loadChildren: () =>
          import('./task/task.module').then((m) => m.TaskModule),
      },
      {
        path: 'contacts',
        loadChildren: () =>
          import('./contacts/contacts.module').then((m) => m.ContactsModule),
      },
      {
        path: 'email',
        loadChildren: () =>
          import('./email/email.module').then((m) => m.EmailModule),
      },
      {
        path: 'apps',
        loadChildren: () =>
          import('./apps/apps.module').then((m) => m.AppsModule),
      },
      {
        path: 'widget',
        loadChildren: () =>
          import('./widget/widget.module').then((m) => m.WidgetModule),
      },
      {
        path: 'ui',
        loadChildren: () => import('./ui/ui.module').then((m) => m.UiModule),
      },
      {
        path: 'forms',
        loadChildren: () =>
          import('./forms/forms.module').then((m) => m.FormModule),
      },
      {
        path: 'tables',
        loadChildren: () =>
          import('./tables/tables.module').then((m) => m.TablesModule),
      },
      {
        path: 'media',
        loadChildren: () =>
          import('./media/media.module').then((m) => m.MediaModule),
      },
      {
        path: 'charts',
        loadChildren: () =>
          import('./charts/charts.module').then((m) => m.ChartsModule),
      },
      {
        path: 'timeline',
        loadChildren: () =>
          import('./timeline/timeline.module').then((m) => m.TimelineModule),
      },
      {
        path: 'icons',
        loadChildren: () =>
          import('./icons/icons.module').then((m) => m.IconsModule),
      },
      {
        path: 'extra-pages',
        loadChildren: () =>
          import('./extra-pages/extra-pages.module').then(
            (m) => m.ExtraPagesModule
          ),
      },
      {
        path: 'maps',
        loadChildren: () =>
          import('./maps/maps.module').then((m) => m.MapsModule),
      },
      {
        path: 'multilevel',
        loadChildren: () =>
          import('./multilevel/multilevel.module').then(
            (m) => m.MultilevelModule
          ),
      },
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'verify/:verification_link',
    loadChildren: () =>
      import('./verifications/verifications.module').then((m) => m.VerificationsModule),
  },
  {
    path: '',
    component: WebLayoutComponent,
    loadChildren: () =>
      import('./web-pages/web-pages.module').then((m) => m.WebPagesModule),
  },

  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}


import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { FormArray, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global/global.service';
import { ManagementFormService } from 'src/app/management-form/management-form.service';
import { SharedComponentService } from '../shared-component.service';

@Component({
  selector: 'app-staff-background-screeing',
  templateUrl: './staff-background-screeing.component.html',
  styleUrls: ['./staff-background-screeing.component.sass']
})

export class StaffBackgroundScreeingComponent implements OnInit {
  @Input() staffRef: any;
  @Input() is_from_link: true;
  @Output() formSubmited = new EventEmitter<any>();
  isLinear = true;
  lastStep = false;
  HFormGroup1: FormGroup;
  HFormGroup2: FormGroup;
  HFormGroup3: FormGroup;
  HFormGroup4: FormGroup;
  HFormGroup5: FormGroup;
  HFormGroup6: FormGroup;
  work_before_details: FormArray;
  assessment_staff_background_ref = '';
  formerNameDocuments = [];
  formerNameDocumentsPreview = [];
  DeleteFormerNameDocuments = [];

  evidenceDocuments = [];
  evidenceDocumentsPreview = [];
  DeleteEvidenceDocuments = [];

  previouseServiceDocuments = [];
  previouseServiceDocumentsPreview = [];
  DeletepreviouseServiceDocuments = [];

  evidenceCurrentWorkDocuments = [];
  evidenceCurrentWorkDocumentsPreview = [];
  DeleteEvidenceCurrentWorkDocuments = [];

  outSideWorkDocuments = [];
  outSideWorkDocumentsPreview = [];
  DeleteOutSideWorkDocuments = [];

  bankruptcyDocuments = [];
  bankruptcyDocumentsPreview = [];
  DeletebankruptcyDocuments = [];

  medicalDocuments = [];
  medicalDocumentsPreview = [];
  DeletemedicalDocuments = [];

  hidePreviouseWork = false;
  hideCrimanalRecord = false;
  hideliveOutsideAustralia = false;
  hideSubjectFormal = false;
  hideBankruptcy = false;
  work_before_files: any = [];
  isEdit = false;
  submitted = false;
  error = '';

  checkBokIsCriminal = false;
  checkBokIsWorkBefore = false;
  checkBoxIsOutsideAustralia = false;
  checkBoxIsDisciplinaryAction = false;
  checkBoxIsBankruptcy = false;

  signatureImg = '';
  signatureImg2 = '';
  signatureImg3 = '';
  signatureImg4 = '';
  signatureImg5 = '';
  isNewSignature = true;
  isNewSignature2 = true;
  isNewSignature3 = true;
  isNewSignature4 = true;
  isNewSignature5 = true;

  checkBox1 = false;
  checkBox2 = false;
  checkBox3 = false;
  checkBox4 = false;
  checkBox5 = false;
  checkBox6 = false;
  checkBox7 = false;
  checkBox8 = false;
  checkBox9 = false;
  checkBox10 = false;
  checkBox11 = false;
  checkBox12 = false;
  checkBox13 = false;
  checkBox14 = false;
  checkBox15 = false;
  checkBox16 = false;
  checkBox17 = false;
  checkBox18 = false;
  checkBox19 = false;
  checkBox20 = false;
  checkBox21 = false;
  checkBox22 = false;
  checkBox23 = false;
  checkBox24 = false;
  checkBox25 = false;
  checkBox26 = false;
  checkBox27 = false;
  checkBox28 = false;
  currentWork = false;

  showDropzone = false;
  multiple_upload = false;
  selectEducatorId = null;

  loggedUser: any = [];
  constructor(
    private sharedService: SharedComponentService,
    private fb: FormBuilder,
    private globalService: GlobalService,
    private router: Router,
    private managementFormService: ManagementFormService,
  ) {
    this.initForm();
    this.loggedUser = JSON.parse(localStorage.getItem('user'));
  }
  initForm() {

    this.HFormGroup1 = this.fb.group({
      staff_id: ['', [Validators.required]],
      applicant_name: ['', [Validators.required]],
      former_name: [''],
      wwcc_number: [''],
      is_work_before: [false],
      work_before_details: this.fb.array([this.createMandatoryTraining()]),
      is_criminal: [false],
      criminal_record_details: [''],
      is_outside_australia: [false],
      outside_australia_details: [''],
      is_disciplinary_action: [false],
      disciplinary_action_details: [''],
      is_bankruptcy: [false],
      bankruptcy_details: [''],
      assessment_staff_background_ref: ['']
    });
    this.HFormGroup2 = this.fb.group({
      sign_at: [''],
      sign_date: [''],
      assessment_staff_background_ref: ['']
    });
    this.HFormGroup3 = this.fb.group({
      position: [''],
      sign_date1: [''],
      nominated_supervisor: [''],
      sign_date2: [''],
      assessment_staff_background_ref: ['']
    });
    this.HFormGroup4 = this.fb.group({
      is_current_employee: [false],
      current_company: [''],
      current_company_contact: [''],
      current_company_position: [''],
      current_company_address: [''],
      current_company_contact_details: [''],
      previous_company: [''],
      previous_company_contact: [''],
      previous_company_position: [''],
      previous_company_address: [''],
      previous_company_contact_details: [''],
      previous_company_2: [''],
      previous_company_contact_2: [''],
      previous_company_position_2: [''],
      previous_company_address_2: [''],
      previous_company_contact_details_2: [''],
      cr_full_name: [''],
      cr_position: [''],
      cr_address: [''],
      cr_contact_details: [''],
      cr2_full_name: [''],
      cr2_position: [''],
      cr2_address: [''],
      cr2_contact_details: [''],
      //  applicant_name: [''],
      sign_date3: [''],
      $assessment_staff_background_ref: ['']
    });
    this.HFormGroup5 = this.fb.group({
      applicant_dob: [''],
      applicant_address: [''],
      applicant_post_code: [''],
      applicant_phone: [''],
      applicant_mobile: [''],
      medical_1: [false],
      medical_d_1: [''],
      medical_2: [false],
      medical_d_2: [''],
      medical_3: [false],
      medical_d_3: [''],
      medical_4: [false],
      medical_d_4: [''],
      medical_5: [false],
      medical_d_5: [''],
      medical_6: [false],
      medical_d_6: [''],
      medical_7: [false],
      medical_d_7: [''],
      medical_8: [false],
      medical_d_8: [''],
      medical_9: [false],
      medical_d_9: [''],
      medical_10: [false],
      medical_d_10: [''],
      medical_11: [false],
      medical_d_11: [''],
      medical_12: [false],
      medical_d_12: [''],
      medical_13: [false],
      medical_d_13: [''],
      medical_14: [false],
      medical_d_14: [''],
      medical_15: [false],
      medical_d_15: [''],
      medical_16: [false],
      medical_d_16: [''],
      medical_17: [false],
      medical_d_17: [''],
      medical_18: [false],
      medical_d_18: [''],
      medical_19: [false],
      medical_d_19: [''],
      health_a: [false],
      health_a_details: [''],
      health_b: [false],
      health_b_details: [''],
      health_c: [false],
      health_c_details: [''],
      health_d: [false],
      health_e: [false],
      disease_1: [false],
      disease_d_1: [''],
      disease_2: [false],
      disease_d_2: [''],
      disease_3: [false],
      disease_d_3: [''],
      disease_4: [false],
      disease_d_4: [''],
      disease_5: [false],
      disease_d_5: [''],
      doctor_name: [''],
      sign_date4: [''],
      medical_address: [''],
      medical_contact_no: [''],
      medical_doc_description: [''],
      assessment_staff_background_ref: ['']
    });
    this.HFormGroup6 = this.fb.group({
      applicant_address1: [''],
      applicant_sign_date: [''],
    })
  }
  ngOnInit() {
    this.getFromData();
  }

  getFromData() {
    this.sharedService.getStaffBackGroundScreeningFormData(this.staffRef).subscribe((res) => {

      if (res['is_edit'] == 0) {
        this.isEdit = false;
        this.HFormGroup1.patchValue({
          staff_id: res['staff'].staff_id,
        })

      } else {
        this.isEdit = true;
        this.setEditData(res['data']);

      }
    }, (error) => {
      this.globalService.showErrorNotification(error);
    });
  }

  onStepChange($event: StepperSelectionEvent) {

    if ($event.selectedIndex == 5) {
      this.lastStep = true;
    }
  }

  async mandatoryTrainingFileChange(item, event) {
    this.work_before_files.push({
      'file': event.target.files[0],
    });
    await this.mandatoryTrainingFileIdPush(item, this.work_before_files.length - 1);
  }

  mandatoryTrainingFileIdPush(item, key) {
    item.value['work_before_file_data'] = key;
  }
  removeMandatoryTraining(i) {
    this.work_before_details.removeAt(i);
  }

  addMandatoryTraining(data) {
    this.work_before_details = this.HFormGroup1.get('work_before_details') as FormArray;
    if (data == null) {
      this.work_before_details.push(this.createMandatoryTraining());
    } else {
      this.work_before_details.push(this.createMandatoryTraining2(data));
    }
  }

  createMandatoryTraining() {
    return this.fb.group({
      name_of_service: '',
      service_address: '',
      position: '',
      work_before_file: '',
      work_before_file_url: '',
      work_before_file_data: '',
      media_id: '',
    });
  }

  createMandatoryTraining2(data: any) {
    return this.fb.group({
      name_of_service: data.name_of_service,
      service_address: data.service_address,
      position: data.position,
      work_before_file: data.work_before_file,
      work_before_file_url: data.work_before_file_url,
      work_before_file_data: data.work_before_file_data,
      media_id: data.media_id,
    });
  }

  async formSubmit(type) {

    if (type == 'staff_details') {
      if (!this.HFormGroup1.valid) {
        this.globalService.scrollToError();
        return;
      }
      const xForm = new FormData();
      this.HFormGroup1.value['assessment_staff_background_ref'] = this.assessment_staff_background_ref;
      this.HFormGroup1.value['form_type'] = type;
      for (var i = 0; i < this.formerNameDocumentsPreview.length; i++) {
        xForm.append('former_name_attachments[]', this.formerNameDocumentsPreview[i]);
      }

      for (var i = 0; i < this.evidenceDocumentsPreview.length; i++) {
        xForm.append('evidence_attachments[]', this.evidenceDocumentsPreview[i]);
      }

      for (var i = 0; i < this.previouseServiceDocumentsPreview.length; i++) {
        xForm.append('previous_service_attachments[]', this.previouseServiceDocumentsPreview[i]);
      }

      for (var i = 0; i < this.evidenceCurrentWorkDocumentsPreview.length; i++) {
        xForm.append('evidence_current_work_attachments[]', this.evidenceCurrentWorkDocumentsPreview[i]);

      }

      for (var i = 0; i < this.outSideWorkDocumentsPreview.length; i++) {
        xForm.append('outside_work_attachments[]', this.outSideWorkDocumentsPreview[i]);
      }

      for (var i = 0; i < this.bankruptcyDocumentsPreview.length; i++) {
        xForm.append('bankruptcy_attachments[]', this.bankruptcyDocumentsPreview[i]);
      }

      for (var i = 0; i < this.work_before_files.length; i++) {
        xForm.append('work_before_files[]', this.work_before_files[i].file);
      }
      xForm.append('delete_former_name_attachments', JSON.stringify(this.DeleteFormerNameDocuments));
      xForm.append('delete_evidence_attachments_attachments', JSON.stringify(this.DeleteEvidenceDocuments));
      xForm.append('delete_previous_service_attachments', JSON.stringify(this.DeletepreviouseServiceDocuments));
      xForm.append('delete_evidence_current_work_attachments', JSON.stringify(this.DeleteEvidenceCurrentWorkDocuments));
      xForm.append('delete_outside_work_attachments', JSON.stringify(this.DeleteOutSideWorkDocuments));
      xForm.append('delete_bankruptcy_attachments', JSON.stringify(this.DeletebankruptcyDocuments));

      xForm.append('form', JSON.stringify(this.HFormGroup1.value));
      await this.managementFormService.createAssessmentStaffForm(xForm).subscribe((res) => {
        this.assessment_staff_background_ref = res['assessment_staff_background_ref'];
        // this.$assessment_staff_background_ref = res['educator_background_data'];
        this.setEditData(res['staff_background_data']);
        this.submitted = false;

        if (this.isEdit) {

          this.globalService.showSuccessNotification('Successfully updated');
          this.isEdit = false;
        } else {

          this.globalService.showSuccessNotification('Successfully saved');
        }
      }, (error) => {
        this.error = error.error.error;
        this.globalService.showErrorNotification(this.error);
        this.submitted = false;
      }
      );
    }
    if (type == 'declaration') {
      if (!this.HFormGroup2.valid) {
        this.globalService.scrollToError();
        return;
      }

      this.HFormGroup2.patchValue({
        'sign_date': this.globalService.formatDatetoServerSide(this.HFormGroup2.value.sign_date),
      });
      this.HFormGroup2.value['assessment_staff_background_ref'] = this.assessment_staff_background_ref;
      this.HFormGroup2.value['form_type'] = type;
      const xForm = new FormData();
      xForm.append('form', JSON.stringify(this.HFormGroup2.value));
      xForm.append('signature1', this.signatureImg);
      await this.managementFormService.createAssessmentStaffForm(xForm).subscribe((res) => {
        this.assessment_staff_background_ref = res['assessment_staff_background_ref'];
        this.setEditData(res['staff_background_data']);
        this.submitted = false;
        if (this.isEdit) {

          this.globalService.showSuccessNotification('Successfully updated');
          this.isEdit = false;
        } else {

          this.globalService.showSuccessNotification('Successfully saved');
        }
      }, (error) => {
        this.error = error.error.error;
        this.globalService.showErrorNotification(this.error);
        this.submitted = false;
      }
      );
    }
    if (type == 'confidentiality_agreement') {
      if (!this.HFormGroup3.valid) {
        this.globalService.scrollToError();
        return;
      }
      this.HFormGroup3.patchValue({
        'sign_date1': this.globalService.formatDatetoServerSide(this.HFormGroup3.value.sign_date1),
        'sign_date2': this.globalService.formatDatetoServerSide(this.HFormGroup3.value.sign_date2),
      });
      this.HFormGroup3.value['assessment_staff_background_ref'] = this.assessment_staff_background_ref;
      this.HFormGroup3.value['form_type'] = type;

      const xForm = new FormData();
      xForm.append('form', JSON.stringify(this.HFormGroup3.value));
      xForm.append('signature2', this.signatureImg2);
      await this.managementFormService.createAssessmentStaffForm(xForm).subscribe((res) => {
        this.assessment_staff_background_ref = res['assessment_staff_background_ref'];
        this.submitted = false;
        this.setEditData(res['staff_background_data']);
        if (this.isEdit) {

          this.globalService.showSuccessNotification('Successfully updated');
          this.isEdit = false;
        } else {

          this.globalService.showSuccessNotification('Successfully saved');
        }
      }, (error) => {
        this.error = error.error.error;
        this.globalService.showErrorNotification(this.error);
        this.submitted = false;
      }
      );
    }
    if (type == 'reference_background') {
      if (!this.HFormGroup4.valid) {
        this.globalService.scrollToError();
        return;
      }
      this.HFormGroup4.patchValue({
        'sign_date3': this.globalService.formatDatetoServerSide(this.HFormGroup4.value.sign_date3),
      });
      this.HFormGroup4.value['assessment_staff_background_ref'] = this.assessment_staff_background_ref;
      this.HFormGroup4.value['form_type'] = type;

      const xForm = new FormData();
      xForm.append('form', JSON.stringify(this.HFormGroup4.value));
      xForm.append('signature3', this.signatureImg3);
      await this.managementFormService.createAssessmentStaffForm(xForm).subscribe((res) => {
        this.assessment_staff_background_ref = res['assessment_staff_background_ref'];
        this.submitted = false;
        this.setEditData(res['staff_background_data']);
        if (this.isEdit) {

          this.globalService.showSuccessNotification('Successfully updated');
          this.isEdit = false;
        } else {

          this.globalService.showSuccessNotification('Successfully saved');
        }
      }, (error) => {
        this.error = error.error.error;
        this.globalService.showErrorNotification(this.error);
        this.submitted = false;
      }
      );
    }
    if (type == 'medical_health') {
      if (!this.HFormGroup5.valid) {
        this.globalService.scrollToError();
        return;
      }
      this.HFormGroup5.patchValue({
        'sign_date4': this.globalService.formatDatetoServerSide(this.HFormGroup5.value.sign_date4),
        'applicant_dob': this.globalService.formatDatetoServerSide(this.HFormGroup5.value.applicant_dob),
        'disease_d_1': this.globalService.formatDatetoServerSide(this.HFormGroup5.value.disease_d_1),
        'disease_d_2': this.globalService.formatDatetoServerSide(this.HFormGroup5.value.disease_d_2),
        'disease_d_3': this.globalService.formatDatetoServerSide(this.HFormGroup5.value.disease_d_3),
        'disease_d_4': this.globalService.formatDatetoServerSide(this.HFormGroup5.value.disease_d_4),
        'disease_d_5': this.globalService.formatDatetoServerSide(this.HFormGroup5.value.disease_d_5),
      });
      this.HFormGroup5.value['assessment_staff_background_ref'] = this.assessment_staff_background_ref;
      this.HFormGroup5.value['form_type'] = type;

      const xForm = new FormData();
      for (var i = 0; i < this.medicalDocumentsPreview.length; i++) {
        xForm.append('medical_attachments[]', this.medicalDocumentsPreview[i]);
      }
      xForm.append('delete_medical_attachments', JSON.stringify(this.DeletemedicalDocuments));
      xForm.append('form', JSON.stringify(this.HFormGroup5.value));
      xForm.append('signature4', this.signatureImg4);
      await this.managementFormService.createAssessmentStaffForm(xForm).subscribe((res) => {
        this.assessment_staff_background_ref = res['assessment_staff_background_ref'];
        this.submitted = false;
        this.setEditData(res['staff_background_data']);
        if (this.isEdit) {
          this.globalService.showSuccessNotification('Successfully updated');
          this.isEdit = false;
        } else {
          this.globalService.showSuccessNotification('Successfully saved');
        }
      }, (error) => {
        this.error = error.error.error;
        this.globalService.showErrorNotification(this.error);
        this.submitted = false;
      }
      );
    }
    if (type == 'educator_declaration') {
      if (!this.HFormGroup6.valid || this.submitted) {
        this.globalService.scrollToError();
        return;
      }
      this.HFormGroup6.patchValue({
        'applicant_sign_date': this.globalService.formatDatetoServerSide(this.HFormGroup6.value.applicant_sign_date),
      });
      this.HFormGroup6.value['assessment_staff_background_ref'] = this.assessment_staff_background_ref;
      this.HFormGroup6.value['form_type'] = type;

      const xForm = new FormData();
      xForm.append('form', JSON.stringify(this.HFormGroup6.value));
      xForm.append('signature5', this.signatureImg5);
      xForm.append('is_from_link', JSON.stringify(this.is_from_link == undefined ? true : this.is_from_link));
      await this.managementFormService.createAssessmentStaffForm(xForm).subscribe((res) => {
        this.assessment_staff_background_ref = res['assessment_staff_background_ref'];
        this.submitted = false;
        if (this.isEdit) {

          this.globalService.showSuccessNotification('Successfully updated');
          this.isEdit = false;
        } else {
          this.globalService.showSuccessNotification('Successfully saved');
        }

        this.formSubmited.emit(true);
      }, (error) => {
        this.error = error.error.error;
        this.globalService.showErrorNotification(this.error);
        this.submitted = false;
      }
      );
    }

  }

  async setEditData(row) {
    this.lastStep = false;
    await this.resetVeriable();
    await this.checkBoxReset();
    //  this.sectionForm.enable();
    this.isEdit = true;
    // this.sectionForm.reset();
    this.assessment_staff_background_ref = row['assessment_staff_background_ref'];
    this.HFormGroup1.patchValue({
      staff_id: row['staff_id'] != null ? row['staff_id'].toString() : null,
      applicant_name: row['applicant_name'],
      former_name: row['former_name'],
      wwcc_number: row['wwcc_number'],
      is_work_before: row['is_work_before'],
      is_criminal: row['is_criminal'],
      criminal_record_details: row['criminal_record_details'],
      is_outside_australia: row['is_outside_australia'],
      outside_australia_details: row['outside_australia_details'],
      is_disciplinary_action: row['is_disciplinary_action'],
      disciplinary_action_details: row['disciplinary_action_details'],
      is_bankruptcy: row['is_bankruptcy'],
      bankruptcy_details: row['bankruptcy_details'],
    });
    this.HFormGroup2.patchValue({
      sign_at: row['sign_at'],
      sign_date: row['sign_date'],
    });
    this.HFormGroup3.patchValue({
      position: row['position'],
      sign_date1: row['sign_date1'],
      nominated_supervisor: row['nominated_supervisor'],
      sign_date2: row['sign_date2'],
    });
    this.HFormGroup4.patchValue({
      is_current_employee: row['is_current_employee'],
      current_company: row['current_company'],
      current_company_contact: row['current_company_contact'],
      current_company_position: row['current_company_position'],
      current_company_address: row['current_company_address'],
      current_company_contact_details: row['current_company_contact_details'],
      previous_company: row['previous_company'],
      previous_company_contact: row['previous_company_contact'],
      previous_company_position: row['previous_company_position'],
      previous_company_address: row['previous_company_address'],
      previous_company_contact_details: row['previous_company_contact_details'],
      previous_company_2: row['previous_company_2'],
      previous_company_contact_2: row['previous_company_contact_2'],
      previous_company_position_2: row['previous_company_position_2'],
      previous_company_address_2: row['previous_company_address_2'],
      previous_company_contact_details_2: row['previous_company_contact_details_2'],
      cr_full_name: row['cr_full_name'],
      cr_position: row['cr_position'],
      cr_address: row['cr_address'],
      cr_contact_details: row['cr_contact_details'],
      cr2_full_name: row['cr2_full_name'],
      cr2_position: row['cr2_position'],
      cr2_address: row['cr2_address'],
      cr2_contact_details: row['cr2_contact_details'],
      //  applicant_name: row['applicant_name'],
      sign_date3: row['sign_date3'],
    });
    this.HFormGroup5.patchValue({
      // aplicant_name: row['aplicant_name'],
      applicant_dob: row['applicant_dob'],
      applicant_address: row['applicant_address'],
      applicant_post_code: row['applicant_post_code'],
      applicant_phone: row['applicant_phone'],
      applicant_mobile: row['applicant_mobile'],
      medical_1: row['medical_1'],
      medical_d_1: row['medical_d_1'],
      medical_2: row['medical_2'],
      medical_d_2: row['medical_d_2'],
      medical_3: row['medical_3'],
      medical_d_3: row['medical_d_3'],
      medical_4: row['medical_4'],
      medical_d_4: row[''],
      medical_5: row['medical_5'],
      medical_d_5: row['medical_d_5'],
      medical_6: row['medical_6'],
      medical_d_6: row['medical_d_6'],
      medical_7: row['medical_7'],
      medical_d_7: row['medical_d_7'],
      medical_8: row['medical_8'],
      medical_d_8: row['medical_d_8'],
      medical_9: row['medical_9'],
      medical_d_9: row['medical_d_9'],
      medical_10: row['medical_10'],
      medical_d_10: row['medical_d_10'],
      medical_11: row['medical_11'],
      medical_d_11: row['medical_d_11'],
      medical_12: row['medical_12'],
      medical_d_12: row['medical_d_12'],
      medical_13: row['medical_13'],
      medical_d_13: row['medical_d_13'],
      medical_14: row['medical_d_14'],
      medical_d_14: row['medical_d_14'],
      medical_15: row['medical_15'],
      medical_d_15: row['medical_d_15'],
      medical_16: row['medical_d_16'],
      medical_d_16: row[''],
      medical_17: row['medical_17'],
      medical_d_17: row['medical_d_17'],
      medical_18: row['medical_18'],
      medical_d_18: row['medical_d_18'],
      medical_19: row['medical_19'],
      medical_d_19: row['medical_d_19'],
      health_a: row['health_a'],
      health_a_details: row['health_a_details'],
      health_b: row['health_b'],
      health_b_details: row['health_b_details'],
      health_c: row['health_c'],
      health_c_details: row['health_c_details'],
      health_d: row['health_d'],
      health_e: row['health_e'],
      disease_1: row['disease_1'],
      disease_d_1: row['disease_d_1'],
      disease_2: row['disease_2'],
      disease_d_2: row['disease_d_2'],
      disease_3: row['disease_3'],
      disease_d_3: row['disease_d_3'],
      disease_4: row['disease_4'],
      disease_d_4: row['disease_d_4'],
      disease_5: row['disease_5'],
      disease_d_5: row['disease_d_5'],
      doctor_name: row['doctor_name'],
      sign_date4: row['sign_date4'],
      medical_address: row['medical_address'],
      medical_doc_description: row['medical_doc_description'],
      medical_contact_no: row['medical_contact_no'],
    });
    this.HFormGroup6.patchValue({
      applicant_address1: row['applicant_address1'],
      applicant_sign_date: row['applicant_sign_date'],
    });
    this.showDropzone = false;
    if (row.former_name != '') {
      this.showDropzone = true;
    }
    this.selectEducatorId = row['staff_name'];
    this.signatureImg = row['signature1'];
    this.isNewSignature = false;
    if (this.signatureImg == null) {
      this.isNewSignature = true;
      this.signatureImg = '';
    }
    this.isNewSignature2 = false;
    this.signatureImg2 = row['signature2'];
    if (this.signatureImg2 == null) {
      this.isNewSignature2 = true;
      this.signatureImg2 = '';
    }
    this.isNewSignature3 = false;
    this.signatureImg3 = row['signature3'];
    if (this.signatureImg3 == null) {
      this.isNewSignature3 = true;
      this.signatureImg3 = '';
    }
    this.isNewSignature4 = false;
    this.signatureImg4 = row['signature4'];
    if (this.signatureImg4 == null) {
      this.isNewSignature4 = true;
      this.signatureImg4 = '';
    }
    this.isNewSignature5 = false;
    this.signatureImg5 = row['signature5'];
    if (this.signatureImg5 == null) {
      this.isNewSignature5 = true;
      this.signatureImg5 = '';
    }
    // dropzone attachment
    this.formerNameDocuments = Object.assign([], row.former_name_attachment);
    this.evidenceDocuments = Object.assign([], row.evidence);
    this.previouseServiceDocuments = Object.assign([], row.previous_service);
    this.evidenceCurrentWorkDocuments = Object.assign([], row.evidence_current_work);
    this.outSideWorkDocuments = Object.assign([], row.outside_work);
    this.bankruptcyDocuments = Object.assign([], row.bankruptcy);
    this.medicalDocuments = Object.assign([], row.medical_document);

    if (row['is_work_before'] == 1) {
      this.checkBokIsWorkBefore = true;
    }
    if (row['is_criminal'] == 1) {
      this.checkBokIsCriminal = true;
    }
    if (row['is_outside_australia'] == 1) {
      this.checkBoxIsOutsideAustralia = true;
    }
    if (row['is_outside_australia'] == 1) {
      this.checkBoxIsOutsideAustralia = true;
    }
    if (row['is_disciplinary_action'] == 1) {
      this.checkBoxIsDisciplinaryAction = true;
    }
    if (row['is_bankruptcy'] == 1) {
      this.checkBoxIsBankruptcy = true;
    }
    if (row['is_current_employee'] == 1) {
      this.currentWork = true;
    }
    if (row['disease_1'] == 1) {
      this.checkBox23 = true;
    }
    if (row['disease_2'] == 1) {
      this.checkBox24 = true;
    }
    if (row['disease_3'] == 1) {
      this.checkBox25 = true;
    }
    if (row['disease_4'] == 1) {
      this.checkBox26 = true;
    }
    if (row['disease_5'] == 1) {
      this.checkBox27 = true;
    }
    if (row['checkBox1'] == 1) {
      this.checkBox1 = true;
    }
    if (row['medical_1'] == 1) {
      this.checkBox1 = true;
    }
    if (row['medical_2'] == 1) {
      this.checkBox2 = true;
    }
    if (row['medical_3'] == 1) {
      this.checkBox3 = true;
    }
    if (row['medical_4'] == 1) {
      this.checkBox4 = true;
    }
    if (row['medical_5'] == 1) {
      this.checkBox5 = true;
    }
    if (row['medical_6'] == 1) {
      this.checkBox6 = true;
    }
    if (row['medical_7'] == 1) {
      this.checkBox7 = true;
    }
    if (row['medical_8'] == 1) {
      this.checkBox8 = true;
    }
    if (row['medical_9'] == 1) {
      this.checkBox9 = true;
    }
    if (row['medical_10'] == 1) {
      this.checkBox10 = true;
    }
    if (row['medical_11'] == 1) {
      this.checkBox11 = true;
    }
    if (row['medical_12'] == 1) {
      this.checkBox12 = true;
    }
    if (row['medical_13'] == 1) {
      this.checkBox13 = true;
    }
    if (row['medical_14'] == 1) {
      this.checkBox14 = true;
    }
    if (row['medical_15'] == 1) {
      this.checkBox15 = true;
    }
    if (row['medical_16'] == 1) {
      this.checkBox16 = true;
    }
    if (row['medical_17'] == 1) {
      this.checkBox17 = true;
    }
    if (row['medical_18'] == 1) {
      this.checkBox18 = true;
    }
    if (row['medical_19'] == 1) {
      this.checkBox19 = true;
    }
    if (row['health_a'] == 1) {
      this.checkBox20 = true;
    }
    if (row['health_b'] == 1) {
      this.checkBox21 = true;
    }
    if (row['health_c'] == 1) {
      this.checkBox22 = true;
    }
    (this.HFormGroup1.get('work_before_details') as FormArray).clear();
    this.work_before_details = this.HFormGroup1.get('work_before_details') as FormArray;
    if (row['work_before_data'] != null) {
      JSON.parse(row['work_before_data']).forEach((item, index) => {
        let data = {
          name_of_service: item.name_of_service,
          service_address: item.service_address,
          position: item.position,
          work_before_file: item.work_before_file,
          work_before_file_url: item.work_before_file_url,
          work_before_file_data: item.work_before_file_data,
          media_id: item.media_id,
        };
        this.work_before_details.push(this.createMandatoryTraining2(data));
      });
    } else {
      this.work_before_details.push(this.createMandatoryTraining());
    }
  }

  private resetVeriable() {
    this.formerNameDocuments = [];
    this.formerNameDocumentsPreview = [];
    this.DeleteFormerNameDocuments = [];

    this.evidenceDocuments = [];
    this.evidenceDocumentsPreview = [];
    this.DeleteEvidenceDocuments = [];

    this.previouseServiceDocuments = [];
    this.previouseServiceDocumentsPreview = [];
    this.DeletepreviouseServiceDocuments = [];

    this.evidenceCurrentWorkDocuments = [];
    this.evidenceCurrentWorkDocumentsPreview = [];
    this.DeleteEvidenceCurrentWorkDocuments = [];

    this.outSideWorkDocuments = [];
    this.outSideWorkDocumentsPreview = [];
    this.DeleteOutSideWorkDocuments = [];

    this.bankruptcyDocuments = [];
    this.bankruptcyDocumentsPreview = [];
    this.DeletebankruptcyDocuments = [];

    this.hidePreviouseWork = false;
    this.hideCrimanalRecord = false;
    this.hideliveOutsideAustralia = false;
    this.hideSubjectFormal = false;
    this.hideBankruptcy = false;
  }

  checkBoxReset() {
    // checkbox reset
    this.checkBox1 = false;
    this.checkBox2 = false;
    this.checkBox3 = false;
    this.checkBox4 = false;
    this.checkBox5 = false;
    this.checkBox6 = false;
    this.checkBox7 = false;
    this.checkBox8 = false;
    this.checkBox9 = false;
    this.checkBox10 = false;
    this.checkBox11 = false;
    this.checkBox12 = false;
    this.checkBox13 = false;
    this.checkBox14 = false;
    this.checkBox15 = false;
    this.checkBox16 = false;
    this.checkBox17 = false;
    this.checkBox18 = false;
    this.checkBox19 = false;
    this.checkBox20 = false;
    this.checkBox21 = false;
    this.checkBox22 = false;
    this.checkBox23 = false;
    this.checkBox24 = false;
    this.checkBox25 = false;
    this.checkBox26 = false;
    this.checkBox27 = false;
    this.checkBokIsCriminal = false;
    this.checkBokIsWorkBefore = false;
    this.checkBoxIsOutsideAustralia = false;
    this.checkBoxIsDisciplinaryAction = false;
    this.checkBoxIsBankruptcy = false;
  }

  showFormerNameDropzone(event) {
    if (event.code == 'Backspace') {
      this.showDropzone = false;
    } else {
      this.showDropzone = true;
    }
  }

  uploadDocuments(data: any, type) {

    let fileList: FileList = data;
    if (type == 'formerName') {
      for (var i = 0; i < fileList.length; i++) {
        this.formerNameDocumentsPreview.push(fileList[i]);
      }
    }
    if (type == 'evidenceDocument') {
      for (var i = 0; i < fileList.length; i++) {
        this.evidenceDocumentsPreview.push(fileList[i]);
      }
    }
    if (type == 'previouseServices') {
      for (var i = 0; i < fileList.length; i++) {
        this.previouseServiceDocumentsPreview.push(fileList[i]);
      }
    }
    if (type == 'evidenceCurrentWork') {
      for (var i = 0; i < fileList.length; i++) {
        this.evidenceCurrentWorkDocumentsPreview.push(fileList[i]);
      }
    }
    if (type == 'outSideAustraliaDocument') {
      for (var i = 0; i < fileList.length; i++) {
        this.outSideWorkDocumentsPreview.push(fileList[i]);
      }
    }
    if (type == 'bankruptcy') {
      for (var i = 0; i < fileList.length; i++) {
        this.bankruptcyDocumentsPreview.push(fileList[i]);
      }
    }
    if (type == 'medicalDocuments') {
      for (var i = 0; i < fileList.length; i++) {
        this.medicalDocumentsPreview.push(fileList[i]);
      }
    }

  }

  resetUpload(key, type) {

    if (type == 'formerName') {
      this.DeleteFormerNameDocuments.push(this.formerNameDocuments[key]);
      this.formerNameDocuments.splice(key, 1);
    }
    if (type == 'evidenceDocument') {
      this.DeleteEvidenceDocuments.push(this.evidenceDocuments[key]);
      this.evidenceDocuments.splice(key, 1);
    }

    if (type == 'previouseServices') {
      this.DeletepreviouseServiceDocuments.push(this.previouseServiceDocuments[key]);
      this.previouseServiceDocuments.splice(key, 1);
    }
    if (type == 'evidenceCurrentWork') {
      this.DeleteEvidenceCurrentWorkDocuments.push(this.evidenceCurrentWorkDocuments[key]);
      this.evidenceCurrentWorkDocuments.splice(key, 1);
    }
    if (type == 'outSideAustraliaDocument') {
      this.DeleteOutSideWorkDocuments.push(this.outSideWorkDocuments[key]);
      this.outSideWorkDocuments.splice(key, 1);
    }
    if (type == 'bankruptcy') {
      this.DeletebankruptcyDocuments.push(this.bankruptcyDocuments[key]);
      this.bankruptcyDocuments.splice(key, 1);
    }
    if (type == 'medicalDocuments') {
      this.DeletemedicalDocuments.push(this.medicalDocuments[key]);
      this.medicalDocuments.splice(key, 1);
    }

  }

  resetUploadPreview(key, type) {
    if (type == 'formerName') {
      if (key > -1) {
        this.formerNameDocumentsPreview.splice(key, 1);
      }
    }
    if (type == 'evidenceDocument') {
      if (key > -1) {
        this.evidenceDocumentsPreview.splice(key, 1);
      }
    }

    if (type == 'previouseServices') {
      if (key > -1) {
        this.previouseServiceDocumentsPreview.splice(key, 1);
      }
    }

    if (type == 'evidenceCurrentWork') {
      if (key > -1) {
        this.evidenceCurrentWorkDocumentsPreview.splice(key, 1);
      }
    }

    if (type == 'outSideAustraliaDocument') {
      if (key > -1) {
        this.outSideWorkDocumentsPreview.splice(key, 1);
      }
    }
    if (type == 'bankruptcy') {
      if (key > -1) {
        this.bankruptcyDocumentsPreview.splice(key, 1);
      }
    }

    if (type == 'medicalDocuments') {
      if (key > -1) {
        this.medicalDocumentsPreview.splice(key, 1);
      }
    }
  }

  // signature one
  signatureImgOutput($event) {
    this.signatureImg = $event;
  }

  removeSignature() {
    this.isNewSignature = true;
    this.signatureImg = '';
  }
  // signature 2
  signatureImgOutput2($event) {
    this.signatureImg2 = $event;
  }

  removeSignature2() {
    this.isNewSignature2 = true;
    this.signatureImg2 = '';
  }

  // signature 3
  signatureImgOutput3($event) {
    this.signatureImg3 = $event;
  }

  removeSignature3() {
    this.isNewSignature3 = true;
    this.signatureImg3 = '';
  }

  // signature 4
  signatureImgOutput4($event) {
    this.signatureImg4 = $event;
  }

  removeSignature4() {
    this.isNewSignature4 = true;
    this.signatureImg4 = '';
  }

  // signature 5
  signatureImgOutput5($event) {
    this.signatureImg5 = $event;
  }

  removeSignature5() {
    this.isNewSignature5 = true;
    this.signatureImg5 = '';
  }

  toggleIsCriminal($event: MatCheckboxChange) {
    this.checkBokIsCriminal = $event.checked;
  }

  toggleIsWorkBefore($event: MatCheckboxChange) {
    this.checkBokIsWorkBefore = $event.checked;
  }

  toggleIsOutsideAustralia($event: MatCheckboxChange) {
    this.checkBoxIsOutsideAustralia = $event.checked;
  }

  toggleIsDisciplinaryAction($event: MatCheckboxChange) {
    this.checkBoxIsDisciplinaryAction = $event.checked;
  }

  toggleIsBankruptcy($event: MatCheckboxChange) {
    this.checkBoxIsBankruptcy = $event.checked;
  }

  toggle1($event: MatCheckboxChange) { this.checkBox1 = $event.checked; }
  toggle2($event: MatCheckboxChange) { this.checkBox2 = $event.checked; }
  toggle3($event: MatCheckboxChange) { this.checkBox3 = $event.checked; }
  toggle4($event: MatCheckboxChange) { this.checkBox4 = $event.checked; }
  toggle5($event: MatCheckboxChange) { this.checkBox5 = $event.checked; }
  toggle6($event: MatCheckboxChange) { this.checkBox6 = $event.checked; }
  toggle7($event: MatCheckboxChange) { this.checkBox7 = $event.checked; }
  toggle8($event: MatCheckboxChange) { this.checkBox8 = $event.checked; }
  toggle9($event: MatCheckboxChange) { this.checkBox9 = $event.checked; }
  toggle10($event: MatCheckboxChange) { this.checkBox10 = $event.checked; }
  toggle11($event: MatCheckboxChange) { this.checkBox11 = $event.checked; }
  toggle12($event: MatCheckboxChange) { this.checkBox12 = $event.checked; }
  toggle13($event: MatCheckboxChange) { this.checkBox13 = $event.checked; }
  toggle14($event: MatCheckboxChange) { this.checkBox14 = $event.checked; }
  toggle15($event: MatCheckboxChange) { this.checkBox15 = $event.checked; }
  toggle16($event: MatCheckboxChange) { this.checkBox16 = $event.checked; }
  toggle17($event: MatCheckboxChange) { this.checkBox17 = $event.checked; }
  toggle18($event: MatCheckboxChange) { this.checkBox18 = $event.checked; }
  toggle19($event: MatCheckboxChange) { this.checkBox19 = $event.checked; }
  toggle20($event: MatCheckboxChange) { this.checkBox20 = $event.checked; }
  toggle21($event: MatCheckboxChange) { this.checkBox21 = $event.checked; }
  toggle22($event: MatCheckboxChange) { this.checkBox22 = $event.checked; }
  toggle23($event: MatCheckboxChange) { this.checkBox23 = $event.checked; }
  toggle24($event: MatCheckboxChange) { this.checkBox24 = $event.checked; }
  toggle25($event: MatCheckboxChange) { this.checkBox25 = $event.checked; }
  toggle26($event: MatCheckboxChange) { this.checkBox26 = $event.checked; }
  toggle27($event: MatCheckboxChange) { this.checkBox27 = $event.checked; }
  toggleCurrentEmployee($event: MatCheckboxChange) {
    this.currentWork = $event.checked;
  }
}

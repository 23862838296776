import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'age'
})
export class GetAgePipe implements PipeTransform {
  transform(value: string): string {
    var timeDiff = Math.abs(Date.now() - new Date(value).getTime());
    const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    return age.toString();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { SharedComponentService } from "../../shared-component.service";
import { GlobalService } from "../../../global/global.service";
import { StaffAndEducatorService } from '../../../staff-and-educator/staff-and-educator.service';

@Component({
  selector: 'app-monitoring-support-visit',
  templateUrl: './monitoring-support-visit.component.html',
  styleUrls: ['./monitoring-support-visit.component.sass']
})
export class MonitoringSupportVisitComponent implements OnInit {


  @Input() rowRef: string;
  @Input() headerHide: number = 0;
  @Input() notificationRef: string = '';
  @Output() closeModal = new EventEmitter<boolean>();
  data: any = [];
  loggedUser: any = [];
  signatureImg = null;
  signatureImg2 = null;
  currentDate = null;
  isNewSignature = true;
  isNewSignature2 = true;
  isEdit = false;
  sectionForm: FormGroup;
  submitted = false;
  error = '';
  sigError = false;
  allMembers: any = [];
  selectedChildren: any = [];
  children: any = [];
  signatureLength = 0;
  signature2Length = 0;
  currentIndex: any = -1;
  htmlBody = null;

  constructor(
    private shardService: SharedComponentService,
    private fb: FormBuilder,
    private globeService: GlobalService,
    private staffEducatorService: StaffAndEducatorService,
  ) {
    this.loggedUser = JSON.parse(localStorage.getItem('user'));
    this.sectionForm = this.fb.group({
      ref: [''],
      table: [''],
      feedback: [''],
      parent_id: [''],
      child_id: [''],
      notification_ref: [''],
      date: [''],
      signature: [''],
      request_type: [''],
      witness_name: [''],
      witness_sign_date: [''],
      witness_signature: [''],
      educator_feedback: ['']
    });
  }

  ngOnInit(): void {
    this.sectionForm.patchValue({
      ref: this.rowRef,
      table: 'monitoring_support',
      notification_ref: this.notificationRef,
      request_type: 'own-table'
    });
    this.getNotification();
    if (this.loggedUser['role_id'] == 6) {
      let child = JSON.parse(localStorage.getItem('child'));
      this.sectionForm.patchValue({
        child_id: child['child_id']
      });
    }
  }

  getNotification() {
    this.htmlBody = null;
    var data = { monitoring_support_ref: this.rowRef, type: 2, };
    this.staffEducatorService.downloadMonitoringSupportVisitReport(data).subscribe(res => {
      this.htmlBody = res['view_html'];
      this.signatureLength = res['signature_length'];
      this.signature2Length = res['witness_sign_length'];
    }, error => {
      this.globeService.showErrorNotification(error.statusText);
    });
  }

  getPriorDate() {
    const today = new Date()  ;
    const priorDate = new Date().setDate(today.getDate() - 30);
    return new Date(priorDate);
  }

  getMaxDate() {
    return new Date();
  }

  formSubmit(form: FormGroup) {
    if (
      !form.valid ||
      this.signatureImg == null
      // (this.signatureImg1 == null && (this.loggedUser['role'] === 'staff' || this.loggedUser['role'] === 'director'))
    ) {
      this.globeService.scrollToError();
      return;
    }
    this.sectionForm.patchValue({
      date: this.globeService.formatDatetoServerSide(this.sectionForm.value.date),
      signature: this.signatureImg,
      witness_sign_date: this.globeService.formatDatetoServerSide(this.sectionForm.value.witness_sign_date),
      witness_signature: this.signatureImg2,
    });

    this.shardService.updateParentSignature(this.sectionForm.value).subscribe((res) => {
      this.globeService.showSuccessNotification('Successfully updated');
      this.modalClose();
    }, (error) => {
      this.error = error.error.error;
      this.globeService.showErrorNotification('Something went wrong!');
    }
    );
  }

  modalClose() {
    this.closeModal.emit(true);
  }

  removeSignature() { this.isNewSignature = true; }

  signatureImgOutput($event) { this.signatureImg = $event; }

  removeSignature2() { this.isNewSignature2 = true; }

  signatureImgOutput2($event) { this.signatureImg2 = $event; }


}

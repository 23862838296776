import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SharedComponentService } from "../../shared-component.service";
import { GlobalService } from "../../../global/global.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-f1-incident-notification-view',
  templateUrl: './f1-incident-notification-view.component.html',
  styleUrls: ['./f1-incident-notification-view.component.sass']
})
export class F1IncidentNotificationViewComponent implements OnInit {
  @Input() rowRef: string;
  @Input() headerHide: number = 0;
  @Input() notificationRef: string = '';
  @Output() closeModal = new EventEmitter<boolean>();
  data: any = [];
  loggedUser: any = [];
  signatureImg = null;
  currentDate = null;
  isNewSignature = true;
  isEdit = false;
  sectionForm: FormGroup;
  submitted = false;
  error = '';
  sigError = false;

  constructor(
    private shardService: SharedComponentService,
    private fb: FormBuilder,
    private globeService: GlobalService,
  ) {
    let c_date = new Date();
    this.currentDate = new Date(c_date.getFullYear() + '/' + (c_date.getMonth() + 1) + '/' + c_date.getDate());
    this.loggedUser = JSON.parse(localStorage.getItem('user'));
    this.sectionForm = this.fb.group({
      parent_id: [this.loggedUser['user']['id']],
      signature_date: [this.currentDate],
      f1_incident_form_ref: [''],
      notification_ref: ['']
    });
  }

  ngOnInit(): void {
    this.getNotification();
  }

  formSubmit(form: FormGroup, type) {
    if (!form.valid || this.submitted) {
      this.globeService.scrollToError();
      return false;
    }
    this.sectionForm.patchValue({
      'signature_date': this.globeService.formatDatetoServerSide(this.sectionForm.value.signature_date)
    });
    this.sigError = false;
    if (this.signatureImg == null) {
      this.sigError = true;
      return false;
    }

    if (type == 1) {
      Swal.fire(<any>{
        title: 'Are you sure?',
        text: 'Do you want to approve this notification?',
        allowOutsideClick: false,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, approved',
        cancelButtonText: 'No, will approve later',
      }).then((result) => {
        if (result.value) {
          this.formSave(type);
        } else {
          this.closeModal.emit(true);
        }
      });
    } else {
      Swal.fire(<any>{
        title: 'Are you sure?',
        text: 'Do you want to decline this notification',
        allowOutsideClick: false,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, decline',
        cancelButtonText: 'No, will decline later',
      }).then((result) => {
        if (result.value) {
          this.formSave(type);
        } else {
          this.closeModal.emit(true);
        }
      });
    }

  }

  formSave(type) {
    const xForm = new FormData();
    xForm.append('form', JSON.stringify(this.sectionForm.value));
    xForm.append('signature', this.signatureImg);
    xForm.append('is_approval', type);
    this.shardService.updateIncidentNotification(xForm).subscribe((res) => {
      this.globeService.showSuccessNotification('Successfully updated');
      this.closeModal.emit(true);
    }, (error) => {
      this.error = error.error.error;
      this.globeService.showErrorNotification(this.error);
      this.submitted = false;
    }
    );
  }

  removeSignature() {
    this.isNewSignature = true;
    this.signatureImg = '';
  }
  signatureImgOutput($event) { this.signatureImg = $event; }

  getNotification() {
    this.shardService.getIncidentForm(this.rowRef).subscribe((res) => {
      this.data = res['data'];
      if(!this.data.sign_parent_name)
        this.data.sign_parent_name = this.loggedUser.user.name;
      if (this.data['is_approval'] == 0) {
        this.sectionForm.patchValue({
          signature_date: this.currentDate,
          f1_incident_form_ref: this.data['f1_incident_form_ref'],
          notification_ref: this.notificationRef
        });
      }
    }, (error) => {
    }
    );
  }

  modelClose() {

  }
  calculateAge(birthdate: string): number {
    if(!birthdate)
      return 0;
    const birthdateParts = birthdate.split('-');
    const birthYear = parseInt(birthdateParts[0], 10);
    const birthMonth = parseInt(birthdateParts[1], 10) - 1; // Months are 0-based
    const birthDay = parseInt(birthdateParts[2], 10);
    const today = new Date();
    const age = today.getFullYear() - birthYear;
    return age;
  }
}

<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <form class="login100-form validate-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <span class="login100-form-title p-b-30">
          Register
        </span>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Username</mat-label>
          <input matInput formControlName="username" required>
          <mat-icon matSuffix>face</mat-icon>
          <mat-error *ngIf="loginForm.get('username').hasError('required')">
            Username is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" required>
          <mat-icon matSuffix>mail</mat-icon>
          <mat-error *ngIf="loginForm.get('email').hasError('required') || loginForm.get('email').touched">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required>
          <mat-icon matSuffix (click)="hide = !hide">
            {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="loginForm.get('password').hasError('required')">
            Password is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Confirm Password</mat-label>
          <input matInput formControlName="cpassword" [type]="chide ? 'password' : 'text'" required>
          <mat-icon matSuffix (click)="chide = !chide">
            {{chide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="loginForm.get('cpassword').hasError('required')">
            Confirm Password is required
          </mat-error>
        </mat-form-field>
        <div class="flex-sb-m w-full p-t-15 p-b-20">
          <div>
            <span>Already Registered? <a routerLink="/authentication/signin">
                Login
              </a></span>
          </div>
        </div>
        <div class="container-login100-form-btn">
          <button mat-flat-button color="primary" class="login100-form-btn" type="submit">
            Register
          </button>
        </div>
        <div class="text-center p-t-30 p-b-20">
          <span class="txt2">
            or sign up using
          </span>
        </div>
        <div class="login100-form-social flex-c-m">
          <a href="#" class="login100-form-social-item flex-c-m bg1 m-r-5">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a href="#" class="login100-form-social-item flex-c-m bg2 m-r-5">
            <i class="fab fa-twitter"></i>
          </a>
        </div>
      </form>
      <div class="login100-more" style="background-image: url('assets/images/pages/bg-02.png');">
      </div>
    </div>
  </div>
</div>
